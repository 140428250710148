<div class="saDashboardContainer">
    <div class="sideBar">
        <app-ins-side-bar (messageEmitter)="changeTab($event)"></app-ins-side-bar>
    </div>
    <div class="body">
        <app-ins-epg-list *ngIf="tabSelected=='epg'"></app-ins-epg-list>
        <app-ins-esg-list *ngIf="tabSelected=='esg'"></app-ins-esg-list>
        <app-ins-esg-list *ngIf="tabSelected!='esg' && tabSelected!='epg'&& tabSelected !='gr'"></app-ins-esg-list>
        <app-ins-game-report *ngIf="tabSelected=='gr'"></app-ins-game-report>
    </div>
</div>