import { Component, Inject, OnInit } from '@angular/core';
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/https/http.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-epg-report',
  templateUrl: './epg-report.component.html',
  styleUrls: ['./epg-report.component.css'],
  providers: [DatePipe]
})
export class EpgReportComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<EpgReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }

  ngOnInit(): void {
    this.reportApiData = this.data.data
    this.tempArr = this.data.data.epg_strategy_values
    if (this.data.flag) {
      this.showSpinnerFlag = true
      setTimeout(() => {
        this.sendPDF()
      }, 2000)
    }else if(this.data.downloadFlag){
      this.showSpinnerFlag = true
      setTimeout(() => {
        this.convetToPDF('a')
      }, 2000)
    }else if(this.data.downloadAll){
      this.showSpinnerFlag = true
      setTimeout(() => {
        this.convetToPDFToDownload('a')
      }, 2000)
    }
  }
  showSpinnerFlag:boolean = false
  reportApiData: any
  openFlag: boolean = false
  tempArr: any[] = []
  public convetToPDF(value: any) {
    var data: any = document.getElementById('contentToConvert');
    var data1: any = document.getElementById('contentToConvert2');
    var data2: any = document.getElementById('contentToConvert3');

    let pdf = new jspdf('p', 'px', 'a4',true);
    html2canvas(data, { windowHeight: data.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas => {
      // Few necessary setting options
      var imgWidth = 446;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
      pdf.addPage();

      html2canvas(data1, { windowHeight: data1.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas1 => {
        const contentDataURL2 = canvas1.toDataURL('image/png')
        pdf.addImage(contentDataURL2, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
        pdf.addPage();
        html2canvas(data2, { windowHeight: data2.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas1 => {
          const contentDataURL3 = canvas1.toDataURL('image/png')
          pdf.addImage(contentDataURL3, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
          pdf.save('file')
          this.showSpinnerFlag = false
        })
      })
    })

  }
  public convetToPDFToDownload(value: any) {
    var data: any = document.getElementById('contentToConvert');
    var data1: any = document.getElementById('contentToConvert2');
    var data2: any = document.getElementById('contentToConvert3');

    let pdf = new jspdf('p', 'px', 'a4',true);
    html2canvas(data, { windowHeight: data.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas => {
      // Few necessary setting options
      var imgWidth = 446;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
      pdf.addPage();

      html2canvas(data1, { windowHeight: data1.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas1 => {
        const contentDataURL2 = canvas1.toDataURL('image/png')
        pdf.addImage(contentDataURL2, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
        pdf.addPage();
        html2canvas(data2, { windowHeight: data2.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas1 => {
          const contentDataURL3 = canvas1.toDataURL('image/png')
          pdf.addImage(contentDataURL3, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
          // pdf.save('file')
          this.showSpinnerFlag = false

          let pdfBlob = pdf.output("blob");
          console.log('Generated PDF Blob:', pdfBlob);
          if(this.dialogRef)
            this.dialogRef.close(pdfBlob)
          return pdf
        })
      })
    })

  }
  public sendPDF() {
    var data: any = document.getElementById('contentToConvert');
    var data1: any = document.getElementById('contentToConvert2');
    var data2: any = document.getElementById('contentToConvert3');

    let pdf = new jspdf('p', 'px', 'a4',true);
    html2canvas(data, { windowHeight: data.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas => {
      // Few necessary setting options
      var imgWidth = 446;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      // A4 size page of PDF
      var position = 0;
      pdf.addImage(contentDataURL, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
      pdf.addPage();

      html2canvas(data1, { windowHeight: data1.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas1 => {
        const contentDataURL2 = canvas1.toDataURL('image/png')
        pdf.addImage(contentDataURL2, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
        pdf.addPage();
        html2canvas(data2, { windowHeight: data2.scrollHeight, backgroundColor: 'rgba(255, 255, 255, 0)' }).then(canvas1 => {
          const contentDataURL3 = canvas1.toDataURL('image/png')
          pdf.addImage(contentDataURL3, 'PNG', 0, 0, pdf.internal.pageSize.getWidth(), pdf.internal.pageSize.getHeight(),'','FAST')
          setTimeout(() => {
            var blob = pdf.output("blob");
            const data = new FormData()
            data.append('file', blob)
            this.dialogRef.close(data)
          }, 0);

        })

      })
    })

  }
  get DateInLocal() {
    const temp = new Date(this.reportApiData.date_of_the_session * 1000)
    return `${new Date(temp).getFullYear()}-${new Date(temp).getMonth() + 1}-${new Date(temp).getDate()}`
  }
  close() {
    this.dialogRef.close(false)
  }
  getUpstartProfit(profit:any){
    if (profit == 0) return '&nbsp;&nbsp;$0'
    else if(!profit) return ''
    const formattedProfit = Number(profit).toLocaleString('en-US');
    return profit.toString().slice(0,1)=='-'?"- $"+formattedProfit.slice(1):"&nbsp;&nbsp;$"+formattedProfit
  }

}
