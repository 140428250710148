import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CreateNewGameComponent } from '../../commonComponants/create-new-game/create-new-game.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-super-admin-dashboard',
  templateUrl: './super-admin-dashboard.component.html',
  styleUrls: ['./super-admin-dashboard.component.css']
})
export class SuperAdminDashboardComponent implements OnInit {

  addGame: Subject<void> = new Subject<void>();
  isNewGame : boolean = false
  constructor(
    private dialogue:MatDialog
  ) { }

  ngOnInit(): void {
  }

  tabSelected='university_list';
  // tabSelected='instructor';
  changeTab(route:any)
  {
    this.tabSelected=route.route
    this.isNewGame = false
    setTimeout(() => {
      if(route.isNewGame)
        this.isNewGame = true
      else{
        this.isNewGame = false
      }
    }, 0);
    }
  createNewGame(){
    this.dialogue.open(CreateNewGameComponent,{}).afterClosed().subscribe((res:any)=>{
      if(res){
        this.addGame.next()
      }
    })
  }
}
