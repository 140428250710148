<div class="dialogueAddRound">
    <div class="header">
        Add Rounds
        <img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>
    <div class="body">
        <div class="content">
            <label>Theme Name <b>*</b></label>
            <input type="text" [(ngModel)]="themeName">
        </div>
        <div class="content">
            <label>Theme Image<b>*</b></label>
            <div class="uploadImg" (click)="fileInput_image.click()">
                <img src="../../../../assets/img/upload.svg"/>
                Upload
                <input #fileInput_image hidden id="file_inp" style="position: absolute;left: 0;" type="file" accept="image/png,image/jpg,image/svg" (change)="onImageChange($event)">
            </div>        
        </div>
        <div class="imgConatiner">
            <img id="UploadImage" *ngIf="src.length>0" class="shownImg" [src]="src" alt="">
            <mat-slider *ngIf="src.length>0" min="10" max="200" step="10" value="100"  (change)="changeContrast($event)" >
                <input matSliderThumb>
              </mat-slider>
              
        </div>
    </div>
    <div class="contentFooter">
        <div class="gameCancel" style="color:#D43738;margin-right: 3rem;" (click)="close()">CANCEL</div>
        <div class="gameSave" style="margin-right: 2rem;" (click)="save()">SAVE</div>
    </div>
</div>