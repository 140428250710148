<div class="instructorConatainer" *ngIf="!isGameSummary">
    <div class="header">
        <div class="title">
            <p>GAME REPORT</p>
        </div>
    </div>

    <div class="body"> 
        <div class="instructor">
            <div>
                <label for="instructor-email">Instructor Name: </label>
                <span>{{ name }}</span>
            </div>
            <div>
                <label for="instructor-email">Instructor Email Address: </label>
                <span>{{ email }}</span>
            </div>
        </div>

        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="text" class="search_icon_inp" placeholder="Search" (keyup)="applyFilter($event)"
                        autocomplete="off">
                </div>
            </div>

            <div class="dropdown">
                <button class="btn filter_btn" id="dropdownMenuLink" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img src="../../../../assets/img/filter_icon.svg" alt="">Filters
                </button>
                <ul class="dropdown-menu" name="dropdown" aria-labelledby="dropdownMenuLink">
                    <li class="dropdown-item" (click)="filterByStatus('all')">
                        All</li>
                    <li class="dropdown-item" (click)="filterByStatus('esg')">
                        ESG</li>
                    <li class="dropdown-item" (click)="filterByStatus('epg')">
                        EPG</li>
                </ul>
            </div>
            <div *ngIf="selectedFilter!='all' && selectedFilter.length>0" class="selectedFilter">
                {{selectedFilter=='esg'?"ESG":"EPG"}} <img (click)="filterByStatus('all')"
                    src="../../../../assets//img/carbon_close-outline.svg" />
            </div>
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">

                <!-- Sl NO -->
                <ng-container matColumnDef="report_name">
                    <th class="" mat-header-cell *matHeaderCellDef>Game Report Name </th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{element.report_name}} </td>
                </ng-container>

                <!-- Game Name  -->
                <ng-container matColumnDef="game_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Game Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.game_type == 1 ?
                        'ESG':element.game_type == 2? 'EPG':''}} </td>
                </ng-container>

                <!-- University Name -->
                <ng-container matColumnDef="univ_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef> Session Code</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.session_code}}</td>
                </ng-container>


                <!-- University ID -->
                <ng-container matColumnDef="univ_id">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Instructor Code</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.university_code}}</td>
                </ng-container>

                <!-- Instructor Email Address -->
                <ng-container matColumnDef="email">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Instructor Email Address</th>
                    <td style="z-index: -1;" class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.email}}
                    </td>
                </ng-container>

                <!-- Rename Game Report -->
                <ng-container matColumnDef="rename">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Rename Game Report</th>
                    <td style="z-index: -1;" class="common_table_data" mat-cell *matCellDef="let element"> <img
                            src="../../../../assets/img/rename.svg" alt="" srcset="" style="cursor: pointer;"
                            (click)="renameReport(element)"> </td>
                </ng-container>



                <!--View Summary -->
                <ng-container matColumnDef="view">
                    <th class="common_table_data " style="margin-right: 10px;" mat-header-cell *matHeaderCellDef>View
                        Summary </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <img (click)="togleGameSummary(element.game_type, element.session_code,element.session_id)"
                            src="../../../../assets//img/carbon_view.svg" />
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
<div *ngIf="isGameSummary" style="height: 100%;">
    <!-- <app-game-summary-game *ngIf="isGameSummary" (messageEmitterSummary)="togleGameSummary()"></app-game-summary-game> -->
    <app-epg-summary-game *ngIf="epgGameSummary"
        (messageEmitterSummary)="togleGameSummary('close')" [session_code]="sessionCode" [instructor_id]="instructor_id"></app-epg-summary-game>
    <app-game-summary-game *ngIf="esgGameSummary"
    (messageEmitterSummary)="togleGameSummary('close')"></app-game-summary-game>
</div>