<div class="addInstructorContainer">
    <div class="containerHeader">
        <div>
            <span *ngIf="data.type == 'add'">Add Instructor</span>
            <span *ngIf="data.type == 'edit'">Update Instructor</span>
        </div>
        <div>
            <img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
        </div>
    </div>
    <div class="containerBody">
        <!-- <div class="gameSelection">
            <mat-radio-group aria-label="Select an option">
                <mat-radio-button [checked]="true" value="esg">ESG Game</mat-radio-button>
                <mat-radio-button value="epg">Entry Pricing Game</mat-radio-button>
              </mat-radio-group>
        </div> -->
        <div class="fieldValues">
            <div class="ces_pd_inp_sub_div">
                <label for="uniName">Instructor Name<b>*</b></label>
                <input type="text" name="uniName" [(ngModel)]="uniName" required="true">
            </div>
            <div class="ces_pd_inp_sub_div">
                <label for="uniCode">Instructor Code<b>*</b></label>
                <input type="text" name="uniCode" [(ngModel)]="uniCode" (input)="onInputChange()" maxlength="8">
            </div>
            <div class="ces_pd_inp_sub_div">
                <label>Select University<b>*</b></label>
                <mat-form-field class="selectOption">
                    <mat-select  name="universityName"  style="padding: 5px 25px;background-color: #F3F3F3;border-radius: 6px; display: inline-block;" [(ngModel)]="slectedUniversityId">
                        <mat-option [value]="university.id" *ngFor="let university of universityList" (click)="selectUniversity(university)">{{university.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="ces_pd_inp_sub_div">
                <label for="number">Instructor Email Address<b>*</b></label>
                <input type="email" name="univName" [(ngModel)]="insEmail">
            </div>
            <div class="ces_pd_inp_sub_div" *ngIf="data.type=='add'">
                <label for="number">Create Password<b>*</b></label>
                <input type="text" name="univName" [(ngModel)]="password">
            </div>
            <div class="ces_pd_inp_sub_div" *ngIf="data.type=='add'">
                <label for="text">Confirm Password<b>*</b></label>
                <input type="text" name="univName" [(ngModel)]="cfPassword">
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="cancel" (click)="close()">
            Cancel
        </div>
        <div class="save" (click)="save()">
            SAVE
        </div>
    </div>
</div>