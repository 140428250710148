import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-round-game-info',
  templateUrl: './round-game-info.component.html',
  styleUrls: ['./round-game-info.component.css']
})
export class RoundGameInfoComponent implements OnInit {
  // page: any = 1;
  constructor(private router: Router) { }
  @Input() condition: any
  @Input() pageNo: any = 1
  playBtnFlag: boolean = true;
  ngOnInit(): void {
    if (this.router.url == '/overview')
      this.playBtnFlag = false
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const image = document.getElementById('img_header');
    if (image) {
      image.addEventListener('load', () => {
        const width = image.offsetWidth;
        const element = document.getElementById('content_text_main_div')
        if (element) {
          element.style.width = (width - 30).toString() + 'px'
        }
      });
    }
    if (document.getElementById('scrollContainer1')) {
      const container = document.getElementById('scrollContainer1')
      if (container) {
        if (container.scrollHeight > container.clientHeight) {
          this.scrollContainer1Flag = true
        } else {
          this.scrollContainer1Flag = false
        }
      }
    }
  }
  scrollContainer1Flag: boolean = false
  scrollContainer2Flag: boolean = false
  scrollContainer3Flag: boolean = false
  leftArrow() {
    this.showScrollToTopButton = false
    if (this.pageNo > 1)
      this.pageNo -= 1;
       setTimeout(() => {
        if (this.pageNo == 1) {
          if (document.getElementById('scrollContainer1')) {
            const container = document.getElementById('scrollContainer1')
            if (container) {
              if (container.scrollHeight > container.clientHeight) {
                this.scrollContainer1Flag = true
              } else {
                this.scrollContainer1Flag = false
              }
            }
          }
        }
        else if (this.pageNo == 2) {
          if (document.getElementById('scrollContainer2')) {
            const container = document.getElementById('scrollContainer2')
            if (container) {
              if (container.scrollHeight > container.clientHeight) {
                this.scrollContainer2Flag = true
              } else {
                this.scrollContainer2Flag = false
              }
            }
          }
        }
        else if (this.pageNo == 3) {
          if (document.getElementById('scrollContainer3')) {
            const container = document.getElementById('scrollContainer3')
            if (container) {
              if (container.scrollHeight > container.clientHeight) {
                this.scrollContainer3Flag = true
              } else {
                this.scrollContainer3Flag = false
              }
            }
          }
        }
      }, 0)

  }
  rightArrow() {
    this.showScrollToTopButton = false
    if (this.pageNo <= 2)
      this.pageNo += 1;
       setTimeout(() => {
        if (this.pageNo == 1) {
          if (document.getElementById('scrollContainer1')) {
            const container = document.getElementById('scrollContainer1')
            if (container) {
              if (container.scrollHeight > container.clientHeight) {
                this.scrollContainer1Flag = true
              } else {
                this.scrollContainer1Flag = false
              }
            }
          }
        }
        else if (this.pageNo == 2) {
          if (document.getElementById('scrollContainer2')) {
            const container = document.getElementById('scrollContainer2')
            if (container) {
              if (container.scrollHeight > container.clientHeight) {
                this.scrollContainer2Flag = true
              } else {
                this.scrollContainer2Flag = false
              }
            }
          }
        }
        else if (this.pageNo == 3) {
          if (document.getElementById('scrollContainer3')) {
            const container = document.getElementById('scrollContainer3')
            if (container) {
              if (container.scrollHeight > container.clientHeight) {
                this.scrollContainer3Flag = true
              } else {
                this.scrollContainer3Flag = false
              }
            }
          }
        }
      }, 0)
  }
  scrollDown(num:string) {
    const count = document.getElementById('scrollContainer' + num)?.scrollTop
    if (count || count == 0) {
      document.getElementById('scrollContainer' + num)?.scroll({
        top: count + 150,
        left: 0,
        behavior: "smooth",
      })
    }
  }
  scrollTop(num:string) {
    const count = document.getElementById('scrollContainer' + num)?.scrollTop
    if (count || count == 0) {
      document.getElementById('scrollContainer' + num)?.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }
  }
  onScroll(num:string) {
    if (document.getElementById('scrollContainer' + num)) {
      const h1 = document.getElementById('scrollContainer' + num)?.scrollHeight
      const h2 = document.getElementById('scrollContainer' + num)?.scrollTop
      if (h1 && h2) {
        const diff = h1 - h2
        if(diff === document.getElementById('scrollContainer' + num)?.clientHeight){
          this.showScrollToTopButton = true
        }
        else{
          this.showScrollToTopButton = false
        }
      }
    }
  }
  showScrollToTopButton = false;
  startRound() {
    this.router.navigate(['/rounds'])
  }
  opendrawer(data: any) {
    if (data)
      this.pageNo = data
  }

}
