<div class="instructorConatainer">
    <div class="header">
        <div class="title">
            <p>UNIVERSITY LIST</p>
        </div>
        <div class="addInstructor">
            <button (click)="addUniversity()" class="add_university_btn">
                <img src="../../../../assets/img/add-fill-icon.svg" alt="" style="width: 24px;">
                <span style="margin-top: 4px;">ADD UNIVERSITY</span>
            </button>
        </div>
    </div>


    <div class="body">
        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="search" class="search_icon_inp" placeholder="Search" (keyup)="applyFilter($event)"
                        autocomplete="off">
                </div>
            </div>
        </div>


        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">

                <!-- Sl NO -->
                <ng-container matColumnDef="sl_no">
                    <th class="" mat-header-cell *matHeaderCellDef [style.width.%]="8"> SN. </th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>


                <!-- University name -->
                <ng-container matColumnDef="name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="25"> University
                        Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.name}}</td>
                </ng-container>

                <!-- No of Instructor -->
                <ng-container matColumnDef="no_of_instructor">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="12"
                        style="text-align: center;"> No of
                        Instructors </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;">
                            {{element.instructors.length}}
                        </div>
                    </td>
                </ng-container>

                <!-- View -->
                <ng-container matColumnDef="view">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="12"
                        style="text-align: center;"> View </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;">
                            <img (click)="viewAllInstructor(element)" src="../../../../assets/img/carbon_view.svg"
                                style="cursor: pointer;" />
                        </div>
                    </td>
                </ng-container>


                <!-- Modify -->
                <ng-container matColumnDef="action">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10"
                        style="text-align: center;">Action</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;">
                            <img src="../../../../assets/img/edit_icon.svg" alt=""
                                (click)="modifyUniversity(element)" style="cursor: pointer;">
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;
                            </div>
                            <img src="../../../../assets/img/mdi-light_delete.svg"
                                (click)="deleteUniversity(element.id)" style="cursor: pointer;" />
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                <ng-container *ngIf="dataSource.data.length > 0; else noDataMessage">
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </ng-container>

                <ng-template #noDataMessage>
                    <tr mat-row>
                        <td colspan="6" style="background-color: blueviolet; margin-top:40px;">No data available</td>
                    </tr>
                </ng-template>

            </table>
        </div>

    </div>
</div>