<div style="background: white;position: relative;z-index: 1111;">
    <div class="header-main-div">
        <div class="header-sub-div-right" style="display: flex;">
            <img class="univLogo" [src]="universityLogo" *ngIf="universityLogo != null;" alt="" style="width: 6vw; margin: 0px 10px;">
            <img src="../../../assets/img/esg-logo.svg" alt="" style="width: 170px; margin: 0px 0px 0px 10px">
            <div style="display: flex; align-items: center;" *ngIf="headerTxtFlag">
                <div class="header-txt" [ngClass]="activeOverview && activeOverview != null?'activeCss':''"
                    (click)="gameDtlClick('1')">Game overview</div>
                <div class="header-txt" [ngClass]="!activeOverview && activeOverview != null?'activeCss':''"
                    (click)="gameDtlClick('2')">Game summary</div>
            </div>
        </div>
    
        <div class="header-sub-div-left">
            <div *ngIf="timerShowFlag" style="margin: auto;margin-right: 13px;font-size: 23px;font-weight: 500;">
                <span>{{remainingMin}}</span>
                <span>:</span>
                <span>{{remainingSec}}</span>
            </div>
            <div *ngIf="headerTxtFlag">
                <div class="" *ngIf="programStaus == 'ended'">
                    <input type="image" src="../../../assets/img/start_game.svg" alt="" (click)="startGameProgram()"
                        style="width: 76%;">
                </div>
                <div class="" *ngIf="programStaus == 'started'">
                    <input type="image" src="../../../assets/img/end_game.svg" alt="" (click)="endGameProgram()"
                        style="width: 70%;">
                </div>
            </div>
            <!-- <img src="../../../assets/img/account-icon.svg" alt="" class="icon mr-40"> -->
            <!-- <img src="../../../assets/img/point-iocn.svg" alt="" class="icon mr-40"> -->
            <img (click)="openSideBar()" src="../../../assets/img/more-option.svg" alt="" class="icon">
        </div>
        <div class="sidenav" *ngIf="sideBarFlag && !logoutFlag">
            <img src="../../../assets/img/Vector.svg" (click)="sideBarFlag = false">
            <div class="sidebar-txt" (click)="infoDisplayHandler(1)">About the Game</div>
            <div class="sidebar-txt" (click)="infoDisplayHandler(2)">About the Company</div>
            <div class="sidebar-txt" (click)="infoDisplayHandler(3)">My Role</div>
            <div class="resume-main-div" *ngIf="resumeGameFlag"><input type="image" src="../../../assets/img/resume-btn.svg"
                    alt="" (click)="infoDisplayHandler('resume')"></div>
            <div class="powered-txt">ESG Marketing Game</div>
        </div>
        <div class="sidenav" *ngIf="sideBarFlag && logoutFlag">
            <img src="../../../assets/img/Vector.svg" (click)="sideBarFlag = false">
            <div class="sidebar-txt" (click)="logout()"> <img src="../../../assets/img/logout-3-svgrepo-com.svg" alt="" style="    width: 17%;
                margin-bottom: 0;"> Logut</div>
            <div class="powered-txt">ESG Marketing Game</div>
        </div>
    </div>
    <div style="margin-left: 2rem;font-weight: 500;margin-bottom: 10px;" class="headerTxt">
        <!-- Sustainable Marketing Leadership, Marketing Game - Wednesday 20th September - 13:45 – 15:15 -->
        {{gameDetailText}}
    </div>
</div>