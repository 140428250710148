<div class="instructorConatainer" *ngIf="!isGameSummary && !isEpgSummary">
    <div class="header">
        <div class="title">
            <p>GAME REPORT</p>
        </div>
    </div>
    <div class="body">
        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="text" class="search_icon_inp" placeholder="Search" (keyup)="applyFilter($event)"
                        autocomplete="off">
                </div>
            </div>

            <div class="dropdown">
                <button class="btn filter_btn" id="dropdownMenuLink" type="button" data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <img src="../../../../assets/img/filter_icon.svg" alt="">Filters
                </button>
                <ul class="dropdown-menu" name="dropdown" aria-labelledby="dropdownMenuLink">
                    <li class="dropdown-item" (click)="filterByStatus('all')">
                        All</li>
                    <li class="dropdown-item" (click)="filterByStatus('1')">
                        ESG</li>
                    <li class="dropdown-item" (click)="filterByStatus('2')">
                        EPG</li>
                </ul>
            </div>
            <div *ngIf="selectedFilter!='all' && selectedFilter.length>0" class="selectedFilter">
                {{selectedFilter=='all'?'':selectedFilter}} <img (click)="filterByStatus('all')"
                    src="../../../../assets//img/carbon_close-outline.svg" />
            </div>
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">

                <!-- Sl NO -->
                <ng-container matColumnDef="report_name">
                    <th class="" mat-header-cell *matHeaderCellDef [style.width.%]="20">Game Session</th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{element.report_name}} </td>
                </ng-container>

                <!-- Retail User Name  -->
                <ng-container matColumnDef="game_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="20">Game Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.game_name}} </td>
                </ng-container>

                <!-- Company name -->
                <ng-container matColumnDef="univ_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10"> University
                        Code</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.university_code == null
                        ? 'N/A' : element.university_code}}</td>
                </ng-container>


                <!-- Reset Password -->
                <ng-container matColumnDef="univ_id">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10">Session Code
                    </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.session_code}} </td>
                </ng-container>

                <!-- Reset Password -->
                <ng-container matColumnDef="email">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="20">Instructor
                        Email Address</th>
                    <td style="z-index: -1;" class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.email}}</td>
                </ng-container>
                <!-- Reset Password -->
                <ng-container matColumnDef="rename">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10">Rename Session
                    </th>
                    <td style="z-index: -1;" class="common_table_data" mat-cell *matCellDef="let element"> <img
                            src="../../../../assets/img/edit_icon.svg" (click)="renameReport(element)" alt="" srcset=""
                            style="cursor: pointer;"> </td>
                </ng-container> <!-- Reset Password -->
                <!-- <ng-container matColumnDef="edit">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Edit  Report  Template </th>
                    <td style="z-index: -1;" class="common_table_data" mat-cell *matCellDef="let element"> <img 
                       (click)="editTemplate(element)" src="../../../../assets/img/edit_icon.svg" alt="" srcset=""
                                style="cursor: pointer;"> </td>
                </ng-container> -->
                <!-- Reset Password -->
                <ng-container matColumnDef="view">
                    <ng-container *ngIf="!adminFlag">
                        <th class="common_table_data " style="margin-right: 10px;" mat-header-cell *matHeaderCellDef
                            [style.width.%]="8">View Summary </th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            <img (click)="togleGameSummary(element.session_id,element.game_type,element.session_code)"
                                src="../../../../assets//img/carbon_view.svg" />
                        </td>
                    </ng-container>
                    <ng-container *ngIf="adminFlag">
                        <th class="common_table_data " style="margin-right: 10px;" mat-header-cell *matHeaderCellDef
                            [style.width.%]="8">Action</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            <div style="display: flex;">
                                <img (click)="togleGameSummary(element.session_id,element.game_type,element.session_code)"
                                    src="../../../../assets//img/carbon_view.svg" />
                                <div style="border-right: 1px solid #C2C2C2;margin: 0 5px" *ngIf="adminFlag">&nbsp;
                                </div>
                                <img src="../../../../assets/img/mdi-light_delete.svg"
                                    (click)="deleteGameReport(element.session_id)" *ngIf="adminFlag" />
                            </div>
                        </td>
                    </ng-container>


                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>
<app-game-summary-game *ngIf="isGameSummary"
    (messageEmitterSummary)="togleGameSummary(null,1,null)"></app-game-summary-game>
<app-epg-summary-game *ngIf="isEpgSummary" (messageEmitterSummary)="togleGameSummary(null,2,null)"
    [session_code]="sessionCode"></app-epg-summary-game>