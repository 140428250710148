<div class="instructorConatainer">
    <div class="header">
        <div class="title">
            <p>EPG</p>
        </div>
        <div class="addInstructor" *ngIf="addMoreStrategyFlag">
            <div class="create_btn_div" (click)="createStrategy()">Create New Strategy</div>
        </div>
    </div>
    <div class="body">
        <div>
            <div class="table_main_div">
                <table mat-table [dataSource]="dataSource" class="epg_table" style="width: 100%; position: relative;"
                    mat-table-sticky>

                    <!-- Sl NO -->
                    <ng-container matColumnDef="sl_no">
                        <th class="" mat-header-cell *matHeaderCellDef> SN. </th>
                        <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                    </ng-container>

                    <!-- Strategy Name -->
                    <ng-container matColumnDef="strategyName">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef> Strategy Name</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.name}} </td>
                    </ng-container>

                    <!-- View/Edit Feedback -->
                    <ng-container matColumnDef="viewFeedback">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef style="text-align: center;">
                            View Feedback </th>
                        <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
                            <img src="../../../../assets/img/epg/feedback_icon.svg" alt=""
                                style="cursor: pointer;width: 28px;height: 28px; opacity: 75%;"
                                (click)="viewFeedback(element)">
                        </td>
                    </ng-container>

                    <!-- View Strategy -->
                    <ng-container matColumnDef="viewStrategy">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef style="text-align: center;">
                            View Strategy </th>
                        <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
                            <img src="../../../../assets/img/carbon_view.svg" alt="" style="cursor: pointer;"
                                (click)="viewStrategy(element)">
                        </td>
                    </ng-container>

                    <!-- Modify -->
                    <ng-container matColumnDef="modify">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef style="text-align: center;">
                            Modify</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
                            <img src="../../../../assets/img/edit_icon.svg" alt="" style="cursor: pointer;"
                                (click)="editStrategy(element.user_id == null,element)"
                                [ngClass]="element.user_id == null ? 'disable_edit':''">
                        </td>
                    </ng-container>

                    <!-- Duplicate -->
                    <ng-container matColumnDef="duplicate">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef style="text-align: center;">
                            Duplicate</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
                            <img src="../../../../assets/img/epg/copy_icon.svg" alt="" style="cursor: pointer;"
                                (click)="copyStrategy(element)">
                        </td>
                    </ng-container>

                    <!-- Action -->
                    <ng-container matColumnDef="action">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef style="text-align: center;">
                            Action</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
                            <div class="start_end_btn_main_div" *ngIf="element.status != 'running'"
                                [ngClass]="{'disableStart': gameStatusFlag}">
                                <div class="start_btn_div" (click)="startGame(element.status == 'running', element)">
                                    Start</div>
                            </div>
                            <div class="start_end_btn_main_div" *ngIf="element.status == 'running'">
                                <div class="end_btn_div" (click)="stopGame(element)">End</div>
                            </div>
                        </td>
                    </ng-container>

                    <!-- Game Code -->
                    <ng-container matColumnDef="gameCode">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef style="width: 150px;"> Game Code
                        </th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            <span *ngIf="element.status == 'running';">{{element.session_code}}</span>
                            <span *ngIf="element.status != 'running';">&nbsp;</span>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
                    <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                    <ng-container *ngIf="dataSource.data.length > 0; else noDataMessage">
                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    </ng-container>

                    <ng-template #noDataMessage>
                        <tr mat-row>
                            <td colspan="6" style="background-color: blueviolet; margin-top:40px;">No data available
                            </td>
                        </tr>
                    </ng-template>

                </table>
            </div>
        </div>
    </div>
</div>