<div style="    width: 334px;    background-color: white;
padding:10px;border-radius: 10px;
text-align: center;">
    <div style="font-family: Avenir;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.04px;
    background-color: white;
    border-radius: 8px;
    font-size: 19px;
    margin-bottom: 1rem;">
        Oh no, looks like you used up all your effort points. In the real world, this would
        typically mean big trouble. Good thing this is just a simulation so you can keep learning
    </div>

    <button type="button" class="btn btn-warning" (click)="close()">OKAY</button>
</div>