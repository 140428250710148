<div class="reset_password_main_div">
    <div class="reset_password_sub_div">
        <div class="reset_password_header">Reset password</div>
        <div class="reset_password_content_main_div">
            <div class="reset_password_content">
                <div>New Password</div>
                <div>
                    <div class="passwordToggler" >
                        <input autocomplete="off" [type]="passwordToggleHide?'password':'text'" name="password" id="password" [(ngModel)]="newPassword" >
                        <img src="../../../../assets/img/eye-close-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                        *ngIf="passwordToggleHide" (click)="passwordToggleHide = false">
                        <img src="../../../../assets/img/eye-2-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                        *ngIf="!passwordToggleHide" (click)="passwordToggleHide = true">
                    </div>
                </div>
            </div>
            <div class="reset_password_content">
                <div>Confirm Password</div>
                <div>
                    <div class="passwordToggler" >
                        <input autocomplete="off" [type]="conpasswordToggleHide?'password':'text'" name="confirmPassword" id="confirmPassword" [(ngModel)]="cfPassword" >
                        <img src="../../../../assets/img/eye-close-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                        *ngIf="conpasswordToggleHide" (click)="conpasswordToggleHide = false">
                        <img src="../../../../assets/img/eye-2-line.svg" alt="" style="cursor: pointer;margin-right: 10px;"
                        *ngIf="!conpasswordToggleHide" (click)="conpasswordToggleHide = true">
                    </div>
                </div>
            </div>
        </div>
        <div style="flex: 1;">&nbsp;</div>
        <div class="reser_btn_main_div">
            <div class="reset_pd_btn_div">
                <div (click)="close()">close</div>
                <div (click)="update()">UPDATE</div>
            </div>
        </div>
    </div>
</div>