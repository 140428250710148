import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/https/http.service';

@Component({
  selector: 'app-create-new-game',
  templateUrl: './create-new-game.component.html',
  styleUrls: ['./create-new-game.component.css']
})
export class CreateNewGameComponent implements OnInit {

  gameName:string = ''
  constructor(
    public dialogRef: MatDialogRef<CreateNewGameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http:HttpService,
    private toster: ToastrService,

  ) { }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close(false)
  }
  create(){
    if(this.gameName.length > 0){
      let data = {
        'name' : this.gameName,
        'game_type' : 1
      }
      this.http.createNewGame(data).subscribe((res:any)=>{
        if (res['status']) {
          this.toster.success('Game Created successfully', '', { timeOut: 2000 })
          this.dialogRef.close(true)
        }
        else {
          this.toster.error(res.error[0], '', { timeOut: 2000 })
        }
        }, (err) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
    }
    else{
      this.toster.warning('Please enter the Game Name', '', { timeOut: 2000 })
    }
  }
}
