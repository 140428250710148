import { CUSTOM_ELEMENTS_SCHEMA, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatTooltipModule} from '@angular/material/tooltip';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent, SessionDialog } from './components/header/header.component';
import { StudentLoginComponent } from './components/student-login/student-login.component';
import { UniversityLoginComponent } from './components/university-login/university-login.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AboutGameComponent } from './components/about-game/about-game.component';
import { EffortAlertComponent, RoundsComponent } from './components/rounds/rounds.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import { RoundGameInfoComponent } from './components/round-game-info/round-game-info.component';
import { GameOverviewComponent } from './components/game-overview/game-overview.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from "@angular/material/dialog";
import { EmailStudentSummaryComponent, StudentSummaryComponent } from './components/student-summary/student-summary.component';
import { PendingChangesGuard } from './services/Guards/pending-changes.guard';
import { ReactiveFormsModule } from '@angular/forms';
import { SuperAdminComponent } from './components/super-admin/super-admin.component'; 
import { MatTableModule } from "@angular/material/table";
import {MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule} from '@angular/material/radio';
import { NgxOrgChartModule } from 'ngx-org-chart';
import {MatSliderModule} from '@angular/material/slider';
import { MatPaginatorModule } from "@angular/material/paginator";
import {NgApexchartsModule} from 'ng-apexcharts'
import { EchartsxModule } from 'echarts-for-angular';
import { ReportComponent } from './components/reports/report.component';
import { SuperAdminDashboardComponent } from './components/superAdmin/super-admin-dashboard/super-admin-dashboard.component';
import { SideBarComponent } from './components/superAdmin/side-bar/side-bar.component';
import { AddModifyInstructorComponent, InstructorListComponent } from './components/superAdmin/instructor-list/instructor-list.component';
import { ResetPasswordComponent } from './components/commonComponants/reset-password/reset-password.component';
import { DeleteDialogueComponentComponent } from './components/commonComponants/delete-dialogue-component/delete-dialogue-component.component';
import { AddModifyGame, AddModifyRound, DialogueAddInstructions, DialogueAddModifyQuestion, DialogueAddRound, GameListComponent, DialogueCopyGame, DialogueShowTree, DialogueAddlabel, DialogueMakeFormula } from './components/superAdmin/game-list/game-list.component';
import { CommonModule } from '@angular/common';
import { DialogueConfigureGame, GameConfigureComponent } from './components/superAdmin/game-configure/game-configure.component';
import { DialogueAddEmail, DialogueEditTemplate, DialogueRenameReport, GameReportComponent, GameSummaryComponent } from './components/superAdmin/game-report/game-report.component';
import { ProfileComponent } from './components/superAdmin/profile/profile.component';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSort, MatSortModule} from '@angular/material/sort';

import {DragDropModule} from '@angular/cdk/drag-drop';

import { GameInfoComponent } from './components/epg/game-info/game-info.component';
import { EpgHeaderComponent } from './components/epg/epg-header/epg-header.component';
import { EpgGameRoundsComponent } from './components/epg/epg-game-rounds/epg-game-rounds.component';
import { EpgGameSummaryComponent } from './components/epg/epg-game-summary/epg-game-summary.component';
import { InsSideBarComponent } from './components/instructor-dashboard/ins-side-bar/ins-side-bar.component';
import { InsEpgListComponent, InsFeedbackDialog, InsPlayerCountDialog, InsStrategyCopyDialog, InsStrategyDialog, InsStrategyErrorDialog } from './components/instructor-dashboard/ins-epg-list/ins-epg-list.component';
import { InsEpgSummaryComponent, InsGameReportComponent } from './components/instructor-dashboard/ins-game-report/ins-game-report.component';
import { InstructorRoutingComponent } from './components/instructor-dashboard/instructor-routing/instructor-routing.component';
import { DialogueGameOverview, DialogueRoundQuestions, InsEsgListComponent } from './components/instructor-dashboard/ins-esg-list/ins-esg-list.component';
import { EpgGamelistComponent } from './components/superAdmin/game-list/epg-gamelist/epg-gamelist.component';
import { NgxGraphModule } from '@swimlane/ngx-graph';
import { EpgReportComponent } from './components/epg-report/epg-report.component';
import { CreateNewGameComponent } from './components/commonComponants/create-new-game/create-new-game.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { AddModifyUniversityComponent, InstructorListDialogComponent, UniversityListComponent } from './components/superAdmin/university-list/university-list.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    StudentLoginComponent,
    UniversityLoginComponent,
    AboutGameComponent,
    RoundsComponent,
    RoundGameInfoComponent,
    GameOverviewComponent,
    SessionDialog,
    StudentSummaryComponent,
    SuperAdminComponent,
    EmailStudentSummaryComponent,
    EffortAlertComponent,
    ReportComponent,
    SuperAdminDashboardComponent,
    SideBarComponent,
    InstructorListComponent,
    ResetPasswordComponent,
    DeleteDialogueComponentComponent,
    AddModifyInstructorComponent,
    DialogueAddModifyQuestion,
    DialogueAddInstructions,
    DialogueConfigureGame,
    DialogueEditTemplate,
    DialogueAddRound,
    DialogueGameOverview,
    DialogueRoundQuestions,
    GameSummaryComponent,
    GameListComponent,
    AddModifyGame,
    AddModifyRound,
    GameConfigureComponent,
    GameReportComponent,
    ProfileComponent,
    DialogueCopyGame,
    GameInfoComponent,
    EpgHeaderComponent,
    EpgGameRoundsComponent,
    EpgGameSummaryComponent,
    InsSideBarComponent,
    InsEpgListComponent,
    InsEsgListComponent,
    InsGameReportComponent,
    InstructorRoutingComponent,
    InsStrategyDialog,
    EpgGamelistComponent,
    InsStrategyCopyDialog,
    InsStrategyErrorDialog,
    InsPlayerCountDialog,
    DialogueShowTree,
    DialogueRenameReport,
    InsEpgSummaryComponent,
    DialogueAddEmail,
    InsFeedbackDialog,
    EpgReportComponent,
    CreateNewGameComponent,
    ConfirmDialogComponent,
    DialogueAddlabel,
    DialogueMakeFormula,
    UniversityListComponent,
    AddModifyUniversityComponent,
    InstructorListDialogComponent
    ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    NgbModule,
    BrowserAnimationsModule,
    MatProgressBarModule,
    ToastrModule.forRoot(),
    FormsModule,
    HttpClientModule,
    NgHttpLoaderModule.forRoot(),
    MatDialogModule,
    MatTooltipModule,
    ReactiveFormsModule,
    MatSliderModule,
    MatTableModule,
    MatPaginatorModule,
    MatFormFieldModule,
    MatInputModule,
    NgApexchartsModule,
    EchartsxModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    DragDropModule,
    NgxOrgChartModule,
    NgxGraphModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatSortModule
  ],
  providers: [
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: true } },
    PendingChangesGuard
  ],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
