<div class="err_dialog_main_div">
    <div class="err_dialog_text">
        <span *ngIf="plyrCount != 0">
            Game in Progress! There are currently <b>{{plyrCount}} players</b> actively engaged.
            <br />
            <br />
            <b>Player's Names : </b>
            <span *ngFor="let item of activePlyr;let i = index">
                <span>{{item.name}}</span>
                <span *ngIf="i+1 != plyrCount">, </span>
            </span>
            <br />
            <br />
        </span>
        <b>Are you sure you want to end the game?</b>
    </div>
    <div class="err_dialog_btn_div">
        <button (click)="close()">cancel</button>
        <button (click)="confirm()">End</button>
    </div>
</div>
<style>
    .err_dialog_main_div {
        width: 700px;
        text-align: center;
        background-color: #fff;
        padding: 2rem;
        border-radius: 10px;
    }

    .err_dialog_text {
        color: #1D1E1C;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Avenir;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
        max-height: 80vh;
        overflow-y: auto;
    }

    .err_dialog_btn_div button {
        border: none;
        color: #FFF;
        font-family: Avenir;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 1.28px;
        padding: 0.5rem 2rem;
        margin-top: 1rem;
    }

    .err_dialog_btn_div button:first-of-type {
        color: red;
        background: transparent;
        margin-right: 20px;
    }

    .err_dialog_btn_div button:last-of-type {
        border-radius: 8px;
        background: linear-gradient(90deg, #0D8BBC 3.7%, #20AAE0 100%);

    }
</style>