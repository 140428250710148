import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
  ) { }


  // Login

  superAdminLogin(data: any) {
    return this.http.post(environment.baseUrl + 'superAdminLogin', data)
  }

  universityLogin(data: any) {
    return this.http.post(environment.baseUrl + 'universityLogin', data)
  }

  studentLogin(data: any) {
    return this.http.post(environment.baseUrl + 'studentLogin', data)
  }

  //Student

  getFirstQuestion(player_id: any, game_id: any, round_number: any, ques_id: any) {
    return this.http.get(environment.baseUrl + 'getFirstQuestionAndOptions?player_id=' + player_id + '&game_id=' + game_id + '&round_number=' + round_number + '&ques_id=' + ques_id)
  }
  updateStudentDetails(data: any) {
    return this.http.post(environment.baseUrl + 'updateStudentDetails', data)
  }
  nextQuestion(game_id: any, next_ques_id: any, player_id: any, session_code: any) {
    return this.http.get(environment.baseUrl + 'getNextQuestionAndOptions?game_id=' + game_id + '&next_ques_id=' + next_ques_id + '&player_id=' + player_id)
  }
  startGame(player_id: any, auth_token: any, game_id: any) {
    return this.http.get(environment.baseUrl + 'studentStartGame?player_id=' + player_id + '&auth_token=' + auth_token + '&game_id=' + game_id)
  }
  endGame(player_id: any) {
    return this.http.get(environment.baseUrl + 'studentEndGame?player_id=' + player_id)
  }
  getSummaryData(player_id:any){
    return this.http.get(environment.baseUrl+'getStudentSummary?player_id='+player_id)
  }
  requestReportByEmail(data: any) {
    return this.http.post(environment.baseUrl + 'updateStudentEmailAndScoreImg', data)
  }
  getCurveBallQuestion(player_id:any, game_id: any, round_number: any) {
    return this.http.get(environment.baseUrl + 'getCurveBallQuestionAndOptions?player_id='+ player_id +'&game_id=' + game_id + '&round_number=' + round_number)
  }
  updateCurveBallStudentDtl(data: any) {
    return this.http.post(environment.baseUrl + 'updateStudentCurveBallDtls', data)
  }
  getPlayerGameInfo(session_code:any){
    return this.http.get(environment.baseUrl + 'getPlayerGameInfo?session_code=' + session_code)
  }

  //University

  getAllQuestionAndOption(university_code: any) {
    return this.http.get(environment.baseUrl + 'getQuestionAndOption?university_code=' + university_code)
  }
  programStatus(university_code: any) {
    return this.http.get(environment.baseUrl + 'getUniversityGameStatus?university_code=' + university_code)
  }
  getAllStudentStatus(university_code: any) {
    return this.http.get(environment.baseUrl + 'studentGameStatusCount?university_code=' + university_code)
  }
  getInstructorAllGame(university_code) {
    return this.http.get(environment.baseUrl + 'getInstructorAllGame?university_code=' + university_code)
  }
  startProgarm(data: any) {
    return this.http.post(environment.baseUrl + 'universityStartGame', data)
  }
  endProgram(data: any) {
    return this.http.post(environment.baseUrl + 'universityStopGame', data)
  }
  getAllGameReport(university_code: any) {
    return this.http.get(environment.baseUrl + 'getUniversityStudentSummary?university_code=' + university_code)
  }


  //Super Admin

  addNewInstructor(data: any) {
    return this.http.post(environment.baseUrl + 'addNewInstructor', data)
  }

  updateInstructor(data: any) {
    return this.http.post(environment.baseUrl + 'updateInstructor', data)
  }

  getInstructorsList() {
    return this.http.get(environment.baseUrl + 'getInstructorList')
  }


  resetInstructorPassword(data: any) {
    return this.http.post(environment.baseUrl + 'resetInstructorPassword', data)
  }

  deleteInstructor(data: any) {
    return this.http.post(environment.baseUrl + 'deleteInstructor', data)
  }

  getInstructorGameList() {
    return this.http.get(environment.baseUrl + 'getInstructorGameList')
  }

  configureGame(data: any) {
    return this.http.post(environment.baseUrl + 'configureGame', data);
  }

  getInstuctorsToConfigGame(game_id) {
    return this.http.get(environment.baseUrl + 'getInstuctorsToConfigGame?game_id=' + game_id)
  }

  updateConfigGameStatus(data: any) {
    return this.http.put(environment.baseUrl + 'updateConfigGameStatus', data);
  }

  deleteConfiguredGame(data: any) {
    return this.http.put(environment.baseUrl + 'deleteConfiguredGame', data);
  }
  getGameReport(id:any){
    return this.http.get(environment.baseUrl + 'getGameReport/' + id)
  }
  getAllStudentList() {
    return this.http.get(environment.baseUrl + 'getStudentsReportStatus')
  }
  getOneStudentAllData(data:any){
    return this.http.post(environment.baseUrl+'getStudentSelectedData',data)
  }
  saveGameReport(data:any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post(environment.baseUrl+'saveGameReport',data)
  }
  sendEmail(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post(environment.baseUrl + 'sendEmail', data)
  }
  sendEpgEmail(data: any) {
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post(environment.baseUrl + 'epg/sendEmail', data)
  }


  // -------------------------------- SUPER ADMIN --------------------------------------------


  getGameList() {
    return this.http.get(environment.baseUrl + 'getGameList')
  }
  getGameListESG(gameId:any){
    return this.http.get(environment.baseUrl + 'getEsgGameList/'+ gameId)
  }
  getGameRounds(id: any) {
    return this.http.get(environment.baseUrl + 'getGameRounds/' + id)
  }
  getRoundsQuestions(id:any, data:any) {
    return this.http.post(environment.baseUrl + 'getRoundsQuestions/' + id, data)
  }
  editQuestion(id: any, data: any) {
    return this.http.post(environment.baseUrl + 'editQuestion/' + id, data)
  }
  addGame(data: any) {
    return this.http.post(environment.baseUrl + 'addGame', data)
  }
  addGameByCopy(data: any) {
    return this.http.post(environment.baseUrl + 'addGameByCopy', data)
  }
  getInstructions(id: any) {
    return this.http.get(environment.baseUrl + 'getInstructions/' + id)
  }
  addInstructions(data: any, id: any) {
    let varr = {
      data: data
    }
    return this.http.post(environment.baseUrl + 'addInstructions/' + id, varr);
  }
  addRound(data: any, id: any) {
    return this.http.post(environment.baseUrl + 'addRound/' + id, data);
  }
  esgGameRoundLinkageValidation(data:any){
    return this.http.post(environment.baseUrl + 'esgGameRoundLinkageValidation', data)
  }
  addQuestion(id: any, data: any) {
    return this.http.post(environment.baseUrl + 'addQuestion/' + id, data);
  }
  addcurveBall(id: any, data: any) {
    return this.http.post(environment.baseUrl + 'addcurveBall/' + id, data);
  }
  getInstructorGames(data: any) {
    return this.http.post(environment.baseUrl + 'getInstructorAllGame', data)
  }
  getInstructorGame(data: any) {
    return this.http.get(environment.baseUrl + 'instructor/game/list?instructor_id='+data)
  }
  instructorStartGame(data: any) {
    return this.http.post(environment.baseUrl + 'universityStartGame', data)
  }
  instructorStopGame(data: any) {
    return this.http.post(environment.baseUrl + 'universityStopGame', data)
  }
  updateInstructorStatus(data: any) {
    return this.http.post(environment.baseUrl + 'updateInstructorStatus', data)
  }
  updateGameStatus(data: any) {
    return this.http.post(environment.baseUrl + 'updateGameStatus', data)
  }
  reOrderRounds(data: any) {
    return this.http.post(environment.baseUrl + 'reOrderRounds', data)
  }
  deleteRound(id: any) {
    return this.http.get(environment.baseUrl + 'deleteRound/' + id)
  }
  deleteQuestion(data: any) {
    return this.http.post(environment.baseUrl + 'deleteQuestion', data)
  }
  getGameListEPG(){
    return this.http.get(environment.baseUrl + 'epgSuperAdminStrategyList')
  }
  getStrategyDetails(id: any) {
    return this.http.get(environment.baseUrl + 'getStrategyDetails/' + id)
  }
  getSaReport(id: any) {
    return this.http.get(environment.baseUrl + 'getGameSessionReports?role_id=1&user_id=' + id + '&instructor_id=NULL')
  }
  renameReport(data: any) {
    return this.http.post(environment.baseUrl + 'updateSessionReportName', data)
  }

  getGameSessionReports(role_id: any, user_id: any, instructor_id: any) {
    return this.http.get(environment.baseUrl + 'getGameSessionReports?role_id=' + role_id + '&user_id=' + user_id + '&instructor_id=' + instructor_id)
  }

  updateSessionReportName(data: any) {
    return this.http.post(environment.baseUrl + 'updateSessionReportName', data)
  }

  getEsgSummaryPlayersList(session_id: any) {
    return this.http.get(environment.baseUrl + 'getEsgSummaryPlayersList?session_id=' + session_id)
  }
  getSaEPGReport(id:any){
    return this.http.get(environment.baseUrl + 'getGameSessionReports?role_id=1&user_id='+id+'&instructor_id=NULL')
  }
  createModifyStrategyAdmin(data: any) {
    return this.http.post(environment.baseUrl + 'epgInstructorStrategySave', data)
  }
  getAdminProfileDetails(id:any) {
    return this.http.get(environment.baseUrl + 'getAdminProfileDetails?userId='+id)
  }

  updateAdminProfile (data:any) {
    return this.http.post(environment.baseUrl + 'updateAdminProfile', data)
  }

  updateAdminPassword (data:any) {
    return this.http.post(environment.baseUrl + 'updateAdminPassword', data)
  }

  deletePlayerReport(player_id:any){
    const data = {player_id}
    return this.http.post(environment.baseUrl+'deletePlayerReport',data)
  }
  deleteEpgPlayerReport(player_id:any){
    const data = {player_id}
    return this.http.post(environment.baseUrl+'deleteEpgPlayerReport',data)
  }
  deleteGameReport(session_id:any){
    const data = {session_id}
    return this.http.post(environment.baseUrl+'deleteGameReport',data)
  }
  deleteGame(game_id:any){
    const data = {game_id}
    return this.http.post(environment.baseUrl+'deleteGames',data)
  }
  deleteInstructors(instructor_id:any){
    const data = {instructor_id}
    return this.http.post(environment.baseUrl+'deleteInstructors',data)
  }
  






  //EPG Game Apis Service
  getStrategy(session_code: any) {
    return this.http.get(environment.baseUrl + 'epg/instructor/strategy?session_code=' + session_code)
  }
  updatePlayerData(data: any) {
    return this.http.post(environment.baseUrl + 'epg/players/move/store', data)
  }
  getAllFeebackQues() {
    return this.http.get(environment.baseUrl + 'epg/feedbackQuestionsOptions')
  }
  updateFeedbackAnswer(data: any) {
    return this.http.post(environment.baseUrl + 'epg/feedbackAnswers', data)
  }
  getEpgPlayerSummary(session_code: any, plyr_id: any) {
    return this.http.get(environment.baseUrl + 'epg/players/summary?session_code=' + session_code + '&epg_player_id=' + plyr_id)
  }
  updateEpgPlyrEmail(data) {
    return this.http.post(environment.baseUrl + 'epg/players/email/update', data)
  }


  // Instructor APIs service 
  getInsStrategy(instructor_id: any) {
    return this.http.get(environment.baseUrl + 'epg/instructor/strategy/list?instructor_id=' + instructor_id)
  }
  createModifyStrategy(data: any) {
    return this.http.post(environment.baseUrl + 'epg/instructor/strategy/save', data)
  }
  getRunningPlyrCount(session_code: any) {
    return this.http.get(environment.baseUrl + 'epg/players/running/count?session_code=' + session_code)
  }
  getInsGameReport(ins_id: any) {
    return this.http.get(environment.baseUrl + 'getGameSessionReports?role_id=2&user_id=NULL&instructor_id=' + ins_id)
  }
  getInsSessionReport(session_code: any) {
    return this.http.get(environment.baseUrl + 'epg/instructor/game/summary?session_code=' + session_code)
  }
  getInsFeedbackQuestion(ins_id:any,strategy_id:any){
    return this.http.get(environment.baseUrl+'epg/instructor/feedbackQuestionsOptionsAnswers?instructor_id='+ins_id+'&strategy_id='+strategy_id)
  }
  saveInsFeedbackAnswer(data:any){
    return this.http.post(environment.baseUrl+'epg/instructor/feedbackAnswers' ,data)
  }
  getReportDtls(player_id:any,ins_id:any,strategy_id:any,session_code:any){
    return this.http.get(environment.baseUrl+'epg/instructor/participants/summary?player_id='+player_id+'&instructor_id='+ins_id+'&strategy_id='+strategy_id+'&session_code='+session_code)
  }
  createNewGame(data:any){
    return this.http.post(environment.baseUrl+'createNewGames' ,data)
  }
  addGameLabel(dataPass:any,gameId:any){
    let data = {
      name:dataPass.name,
      value : dataPass.value,
      gameId : gameId
    }
    return this.http.post(environment.baseUrl+'addLabel' ,data)
  }
  getGameLabel(gameId:any){
    return this.http.get(environment.baseUrl + 'getGameLabels/' + gameId)
  }
  getAllGames(){
    return this.http.get(environment.baseUrl + 'getAllGames')
  }
  getGameFormula(id:any){
    return this.http.get(environment.baseUrl + 'getGameFormula/' + id)
  }
  addFormula(id:any,data:any){
    return this.http.post(environment.baseUrl+'addFormula/'+id ,data)
  }
  deleteFormula(id:any){
    return this.http.get(environment.baseUrl + 'deleteFormula/' + id)
  }
  deleteLabel(id:any){
    return this.http.get(environment.baseUrl + 'deleteLabel/' + id)
  }
  getGameDetails(data:any){
    return this.http.post(environment.baseUrl + 'getGameDetails',data)
  }
  editFormula(id:any,data:any){
    return this.http.post(environment.baseUrl + 'editFormula/'+id,data)
  }
  deleteGameEntire(id:any){
    return this.http.get(environment.baseUrl + 'deleteEntireGame/'+id)

  }
  getInstructorDetails(instructor_id: any){
    return this.http.get(environment.baseUrl + 'instructor/details?instructor_id=' + instructor_id)
  }
  getCustomGameReport(id:any){
    return this.http.get(environment.baseUrl + 'customGameReport?game_id=' + id)
  }

  updateCustomGameReport(data:any){
    const headers = new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
    return this.http.post(environment.baseUrl+'customGameReportUpdate', data)
  }

  getAllUniversityList(){
    return this.http.get(environment.baseUrl+'university/instructor/list')
  }
  createNewUniversity(name:string){
    const data = {name}
    return this.http.post(environment.baseUrl+'university/create',data)
  }
  getUniversityList(){
    return this.http.get(environment.baseUrl+'university/list ')
  }
  updateUnivesityDtls(id:any,name:any){
    const data = {name}
    return this.http.put(environment.baseUrl + 'university/edit/' + id, data)
  }
  deleteUniversity(id:any){
    return this.http.delete(environment.baseUrl + 'university/delete/' + id)
  }
}
