import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataShareService {

  constructor() { }
  roundTheameData:any = {
    1: 'Packaging Reduction for Pre-Packaged Items',
    2: 'Eliminating Single use plastic',
    3: 'Carbon Emission Reduction',
    4: 'Food Wastage Reduction',
    5: 'Promoting A Plant-Based Lifestyle',
  }
  restartFlag:boolean = false
  instructorGameId:any = null
  studentId:any;
  univId:any;
  addGame:any;
  gameID:any = 3;
  gameName : any = 'kvh'
  editGameData:any;
  roundData:any;
  roundList:any;
  secretKey='esg_epg_suyash'
  game_types:any=null
  reportId:any;
  userId:any;
  userName:any;

  private userDetailsSubject = new BehaviorSubject<any>({});
  userDetails$ = this.userDetailsSubject.asObservable();

  updateUserDtl(newUserDetails:any): void {
    this.userDetailsSubject.next(newUserDetails);
  }

  getUserDtl(): Observable<any> {
    return this.userDetailsSubject;
  }
}
