import { Component, Inject, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/https/http.service';
import html2canvas from 'html2canvas';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
const emailRegex = new RegExp(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, "gm");
@Component({
  selector: 'app-student-summary',
  templateUrl: './student-summary.component.html',
  styleUrls: ['./student-summary.component.css']
})
export class StudentSummaryComponent implements OnInit {

  constructor(
    private dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService,
  ) { }
  student_id: any
  university_code: any
  gameId: any
  gameType:any
  budgetAllocated: any
  totalExpense: any
  balance: any
  cashInHandProgress: any = 0
  effortValue: any = 0
  esgGrade: any
  esgData: any = {
    1: 'CCC',
    2: 'B',
    3: 'BB',
    4: 'BBB',
    5: 'A',
    6: 'AA',
    7: 'AAA',
  }
  esgProgess: any = 0
  sentimentProgress: any = 0
  sentimentProgressVal: any = 0
  roundOffPercentSentiment: any = 0
  formulaName1: any
  formulaName2: any
  formulaName3: any
  formulaName4: any
  Value1: any
  Value2: any
  Value3: any
  Value4: any
  maxVal1:any
  maxVal2:any
  maxVal3:any
  maxVal4:any
  is_curveBall: any
  priorityFormulaName: any
  priorityFormulaValue: any
  identifier:any = ''
  ngOnInit(): void {
    this.student_id = sessionStorage.getItem('playerId')
    this.identifier = sessionStorage.getItem('studentIdentifier')
    this.fetchData()
  }
  getRound(data){
    return Math.round(data)
  }
  fetchData() {
    this.httpSv.getSummaryData(this.student_id).subscribe((res: any) => {
      if (res['status']) {
        this.gameId = res['result'].game_id;
        const temp = res['result']
        this.gameType = res['result'].game_type;
        if(this.gameType === 1 || this.gameType === 2){
          this.budgetAllocated = temp.budget_allocated
          this.totalExpense = temp.total_expense
          this.balance = Math.round(temp.total_remaining)
          this.cashInHandProgress = Math.ceil((parseInt(this.balance) / parseInt(this.budgetAllocated)) * 100)
          this.effortValue = +this.balance
          // const esgPercent = Math.ceil(((parseInt(temp.esg_score) - 36.2) / 64) * 100)
          const esgPercent = temp.normalisedEsgScore;

          if (esgPercent >= 0 && esgPercent <= 9)
            this.esgGrade = 1
          else if (esgPercent >= 10 && esgPercent <= 20)
            this.esgGrade = 2
          else if (esgPercent >= 21 && esgPercent <= 35)
            this.esgGrade = 3
          else if (esgPercent >= 36 && esgPercent <= 50)
            this.esgGrade = 4
          else if (esgPercent >= 51 && esgPercent <= 64)
            this.esgGrade = 5
          else if (esgPercent >= 65 && esgPercent <= 79)
            this.esgGrade = 6
          else
            this.esgGrade = 7

          // this.esgProgess = Math.ceil(((parseInt(temp.esg_score) - 36.2) / 64) * 100)
          this.esgProgess = temp.normalisedEsgScore;
          // this.esgProgess = temp.esg_score
          if (this.esgProgess > 100)
            this.esgProgess = 100
          // this.sentimentProgressVal = temp.brand_value
          // this.sentimentProgress = temp.brand_value
          // this.sentimentProgressVal = Math.ceil(((parseInt(temp.brand_value) - 32.5) / 64) * 100)
          // this.sentimentProgress = Math.ceil(((parseInt(temp.brand_value) - 32.5) / 64) * 100)
          // this.roundOffPercentSentiment = Math.ceil(((parseInt(temp.brand_value) - 32.5) / 64) * 100)
          this.sentimentProgressVal = temp.normalisedBrandValue;
          this.sentimentProgress = temp.normalisedBrandValue;
          this.roundOffPercentSentiment = temp.normalisedBrandValue;

        } else if(this.gameType > 2) {

          const formula = res.result.formula;
          let checkCurveBall = formula.findIndex(show => show.is_curve_ball ===1);

          if (checkCurveBall !== -1) {
            const priorityFormula = formula.splice(checkCurveBall, 1)[0];
            formula.unshift(priorityFormula);

            this.priorityFormulaName = priorityFormula.name;
            this.priorityFormulaValue = priorityFormula.value;

            this.formulaName1 = priorityFormula.name;
            this.Value1 = priorityFormula.value;

            this.budgetAllocated = 100;
            this.totalExpense = this.Value1;
            this.balance = this.budgetAllocated - this.totalExpense;
          }
          formula.forEach((show, index) =>{
            if(index === 0 && checkCurveBall === -1){
              this.formulaName1 = show.name;
              this.Value1 = show.value;
              this.maxVal1 = show.max
            } else if(index === 1){
              this.formulaName2 = show.name;
              this.Value2 = show.value;
              this.maxVal2 = show.max
            }
            else if(index === 2){
              this.formulaName3 = show.name;
              this.Value3 = show.value;
              this.maxVal3 = show.max;
            }
            else if(index === 3){
              this.formulaName4 = show.name;
              this.Value4 = show.value;
              this.maxVal4 = show.max;
            }
          });
        }
      }
    })
  }
  getBgColor(data: any) {
    switch (data) {
      case 'CCC':
        return 'laggard-bg'
      case 'B':
        return 'laggard-bg'
      case 'BB':
        return 'average-bg'
      case 'BBB':
        return 'average-bg'
      case 'A':
        return 'average-bg'
      case 'AA':
        return 'leader-bg'
      case 'AAA':
        return 'leader-bg'
      default:
        return ''
    }
  }
  imgBase64: any
  emailRequested: boolean = false
  captureImage() {
    this.imgBase64 = null
    let section = document.getElementById('uniqueId');
    if (section) {
      html2canvas(section).then(canvas => {
        const temp = canvas.toDataURL('image/png');
        this.imgBase64 = temp
        const dialogRef = this.dialog.open(EmailStudentSummaryComponent, {
          maxWidth: '450px',
          minWidth: '450px',
          disableClose: true,
          data: {
            fromESG: true,
            student_id: this.student_id,
            university_code: this.university_code,
            // score_image: this.imgBase64,
            identifier:this.identifier,
            report_status: "Requested"
          }
        })
        dialogRef.afterClosed().subscribe((res: any) => {
          this.imgBase64 = null
          if (res) {
            this.emailRequested = true
          } else {

          }
        })
      });

    }
  }
  page: any
  gameInfoFlag: boolean = false
  openGameInfo(data: any) {
    if (data && data !== 'close') {
      this.page = data
      this.gameInfoFlag = true
    }
  }
}
@Component({
  selector: 'dialog-student-email',
  templateUrl: './email_enter_dialog.html',
  styleUrls: ['./student-summary.component.css']
})
export class EmailStudentSummaryComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EmailStudentSummaryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService
  ) { }
  ngOnInit(): void {
    console.log(this.data)
    if (this.data.fromEPG == true) {
      this.epgFlag = true
    } else if (this.data.fromESG == true) {
      this.esgFlag = true
    }
    if(this.data.identifier.length > 0){
      this.email = this.data.identifier;
      this.emailValidate = true
      this.validateEmail()
    }
  }
  epgFlag: boolean = false
  esgFlag: boolean = false
  emailValidate: boolean = false
  email: string = ''
  validateEmail() {
    if (this.email.length > 0) {
      if (this.email.includes('@') && this.email.includes('.')) {
        this.data['email'] = this.email
        if (!this.epgFlag) {
          const data = {
            player_id: this.data.student_id,
            email: this.email,
            report_status: this.data.report_status
          }
          this.httpSv.requestReportByEmail(data).subscribe((res: any) => {
            if (res['status']) {
              this.emailValidate = true
              setTimeout(() => {
                this.close(true)
              }, 5000)
            } else {
              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            }
          }, (err: any) => {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          })
        }
        else if (this.epgFlag) {
          if (this.data.email.match(emailRegex)) {
            const data = {
              player_id: this.data.plyID,
              email: this.data.email
            }
            this.httpSv.updateEpgPlyrEmail(data).subscribe((res: any) => {
              if (res['status']) {
                this.emailValidate = true
                setTimeout(() => {
                  this.close(true)
                }, 5000)
              } else {
                this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
              }
            }, (err: any) => {
              this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
            })
          } else {
            this.toster.warning("Please enter a valid email", '', { timeOut: 2000 })
            return
          }
        }

      } else {
        this.toster.error('Please enter a vaild email ID', '', { timeOut: 2000 })
      }
    } else {
      this.toster.error('Please enter the email id', '', { timeOut: 2000 })
    }
  }
  close(data: boolean) {
    this.dialogRef.close(data)
  }
}