<div class="instructorConatainer" style="font-family: 'Avenir';">
    <div class="gameHeader">
        <div class="gameHeaderLeft">
            <img style="cursor: pointer;" (click)="goBackSummary()" src="../../../../assets/img/backArrow.svg" />
            <p>GAME SUMMARY</p>
        </div>
    </div>

    <div class="gameBody" *ngIf="apiGameList.length != 0">
        <div class="GameTable" style="width: 100%;margin-bottom: 2rem;">
            <div class="web_user_search_main_div" style="justify-content: space-between;">
                <div class="web_user_search_sub_div">
                    <div class="search_icon_inp_div">
                        <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                        <input type="text" class="search_icon_inp" placeholder="Search" (keyup)="applyFilter($event)"
                            autocomplete="off">
                    </div>
                </div>
            <div class="d-flex">
                <div class="view_strategy_main_div" (click)="showStrategy()">
                    <span>View Strategy</span>
                    <img src="../../../../assets//img/carbon_view.svg" alt="">
                </div>
                <div>
                    <button (click)="downloadAll()" class="gradient-btn">Download All</button>
                    <button (click)="sendAllPDF()" class="gradient-btn">Send All</button>
                </div>
            </div>
            </div>
            <div class="web_user_table_main_div">
                <table mat-table matSort (matSortChange)="sortData($event)" [dataSource]="dataSource" class="mat-elevation-z8"
                    style="width: 100%; position: relative;">

                    <!-- Participant Names -->
                    <ng-container matColumnDef="name">
                        <th class="" mat-sort-header mat-header-cell *matHeaderCellDef> Participant Names</th>
                        <td class="" mat-cell *matCellDef="let element; let i = index">{{element.name}} </td>
                    </ng-container>

                    <!-- UPSTART’S Profit -->
                    <ng-container matColumnDef="upstart_price">
                        <th class="common_table_data" mat-sort-header mat-header-cell *matHeaderCellDef>UPSTART’S Profit</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element"> $ {{element.upstart_profit | number: '1.0-0'}}
                        </td>
                    </ng-container>

                    <!-- Price Prediction Accuracy (%) -->
                    <ng-container matColumnDef="price_prediction">
                        <th class="common_table_data" mat-sort-header mat-header-cell *matHeaderCellDef> Price Prediction Accuracy (%)
                        </th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            {{element.prediction_accuracy}}%
                        </td>
                    </ng-container>

                    <!-- Report Requests -->
                    <ng-container matColumnDef="request">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef>Report
                            Requests</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            <div style="display: flex;">
                                <img src="../../../../assets/img/activeMail.svg" *ngIf="element.is_requested && !element.is_report_sent"
                                    style="cursor: default;" />
                                <img src="../../../../assets/img/inactiveMail.svg" *ngIf="!element.is_requested || element.is_report_sent"
                                    style="cursor: default;" />
                                <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img style="width: 20px; display: block;" matTooltipClass="tooltipp"
                                    matTooltipHideDelay="1000" mat-raised-button matTooltip={{element.email}}
                                    src="../../../../assets/img/info.svg" />
                                <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                                <img style="width: 25px;" src="../../../../assets/img/download.png"
                                    (click)="downloadReport(element)" />
                            </div>
                        </td>
                    </ng-container>

                    <!-- Action -->
                    <ng-container matColumnDef="action">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef>Action</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            <div style="display: flex;">
                                <img src="../../../../assets/img/carbon_view.svg" (click)="showReport(element.id)" />
                                <div style="border-right: 1px solid #C2C2C2;margin: 0 5px"> &nbsp;</div>
                                <img src="../../../../assets/img/send.svg" (click)="sendEmail(element)"/>
                                <div style="border-right: 1px solid #C2C2C2;margin: 0 5px" >&nbsp;</div>
                                <img src="../../../../assets/img/mdi-light_delete.svg" (click)="deletePlayer(element.id)" />
                            </div>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
    </div>
    <div class="nogameBody" *ngIf="apiGameList.length==0">
        <div class="noGame">NO GAMES FOUND</div>
        <img src="../../../../../assets/img/nogame.svg" />
    </div>
    <div class="progress-bar" *ngIf="isProcessing">
        <div class="progress-container">
            <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
            <p style="margin-top: 2rem; text-align: center;">{{progressValue}}%</p>
            <p style="margin-top: 5rem; text-align: center;">Your request is being processed..</p>
        </div>
        <div style="margin-top: 3rem;">
            <button class="gradient-btn" (click)="cancel()">cancel</button>     
        </div>
    </div>
</div>