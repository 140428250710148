<div class="instructorConatainer">
    <div class="header">
        <div class="title">
            <p>GAME LIST</p>
        </div>
        <div class="addInstructor">
        </div>
    </div>
    <div class="body">
 
        <div style="border-top: 2px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="search" class="search_icon_inp" placeholder="Search"
                        (keyup)="applyFilter($event)" autocomplete="off">
                </div>
            </div>
    
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">
    
                <!-- Sl NO -->
                <ng-container matColumnDef="sl_no">
                    <th class="" mat-header-cell *matHeaderCellDef> SN. </th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>
    
                <!-- Instructor logo
                <ng-container matColumnDef="university_logo">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>University Logo</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">  <img class="univ_logo" [src]="element.university_logo"/></td>
                </ng-container> -->
    
                <!-- University name -->
                <ng-container matColumnDef="name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Game Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.game_name == null ? 'N/A' : element.game_name}}</td>
                </ng-container>

                    <!-- University name -->
                    <ng-container matColumnDef="gcode">
                        <th class="common_table_data" mat-header-cell *matHeaderCellDef>Game code</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.game_code == null ? 'N/A' : element.game_code}}</td>
                    </ng-container>
    
                <!-- University code -->
                <ng-container matColumnDef="overview">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Game Overview </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <img (click)="handleOverview(element.game_id,element)" src="../../../../assets/img/carbon_view.svg"/>
                    </td>
                </ng-container>

                <!-- City name -->
                <ng-container matColumnDef="status">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef> Game Status </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div *ngIf="element.session_code!=null" class="stop" (click)="stopGame(element)">
                            Stop
                        </div>
                        <div *ngIf="element.session_code==null" class="start" (click)="startGame(element)">
                            Start
                        </div>
                    </td>
                </ng-container>
    
    
                <!-- Reset Password -->
                <ng-container matColumnDef="code">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Session Code</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">                         {{element.session_code == null ? 'N/A' : element.session_code}}
                    </td>
                </ng-container>

            

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                <ng-container  *ngIf="dataSource.data.length > 0; else noDataMessage">
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </ng-container>

                <ng-template #noDataMessage>
                    <tr mat-row>
                        <td colspan="6" style="background-color: blueviolet; margin-top:40px;">No data available</td>
                    </tr>
                </ng-template>
                
            </table>
        </div>
    </div>
</div>