import { Component, OnInit } from '@angular/core';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  constructor(
    private httpSv: HttpService,
    private dataSv: DataShareService,
    private toaster: ToastrService,
  ) {
  }

  ngOnInit(): void {
    this.userId = JSON.parse(sessionStorage.getItem('user_id') || '');
    this.getProfileDtls();
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const inputElement = document.querySelector('ng-select input');
    if (inputElement) {
      inputElement.setAttribute('autocomplete', 'off');
    }
  }
  allState: any[] = []
  selectedState: any = null
  onMaterialGroupChange(event: any) {
    console.log(this.selectedState);
  }
  userId:any
  companyName: string = ''
  mobileNo: string = ''
  email: string = ''
  name: string = ''
  src: string = '../../../assets/img/newdp.jpg'
  address: string = ''
  emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  getProfileDtls() {
    this.httpSv.getAdminProfileDetails(this.userId).subscribe((res:any) => {
      if(res['status']){
        let userData = res['data'];
        this.name = userData.name,
        this.email = userData.email
        this.src = res['profile']
        sessionStorage.setItem('user_img',this.src)
      }
    },(err) => {
        this.toaster.error('Opps! Something wnet wrong', '', { timeOut: 2000 })
    })
  }
  updateProfileDtls() {
    const data = new FormData()
    data.append('id', this.userId)
    data.append('name', this.name)
    data.append('email', this.email)
    if(this.fileData!=null)
    data.append('file', this.fileData)

    if(this.name != ''){
      if(this.email != ''){
        if(this.emailRegex.test(this.email)){
          this.httpSv.updateAdminProfile(data).subscribe((res:any) => {
            if(res['status']){
              let userData = res['data'];
              this.name = userData.name,
              this.email = userData.email
              this.src = res['profile']
              sessionStorage.setItem('user_img',this.src)
              sessionStorage.setItem('user_name', userData.name)
              const userDataSidebar = {
                name: userData.name,
                profile: this.src
              }
              this.dataSv.updateUserDtl(userDataSidebar);
              this.toaster.success('Profile updated successfully.', '', { timeOut: 2000 })
            }
          },(err) => {
              if(err.status == 422){
                this.toaster.warning(err.error['message'], '', { timeOut: 2000 })
              } else{
                this.toaster.error('Opps! Something wnet wrong', '', { timeOut: 2000 })
              }
          })
        } else{
          this.toaster.warning('Please enter a valid email', '', { timeOut: 2000 })
        }
      } else{
        this.toaster.warning('Please enter a email id', '', { timeOut: 2000 })
      }
    } else{
      this.toaster.warning('Please enter name', '', { timeOut: 2000 })
    }
  }

  oldPassword: string = ''
  newPassword: string = ''
  confirmPassword: string = ''
  fileData: any = null
  onImageChange(event: any) {
    let file: File = event.target.files[0];
    this.fileData = file
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.src = e.target.result;
    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }
  updatePassword() {
    if(this.oldPassword != ''){
      if(this.newPassword != ''){
        if(this.confirmPassword != ''){
          if(this.newPassword == this.confirmPassword){
            const data = new FormData()
            data.append('id', this.userId)
            data.append('oldPassword', this.oldPassword)
            data.append('newPassword', this.newPassword)
            data.append('confirmPassword', this.confirmPassword)
            this.httpSv.updateAdminPassword(data).subscribe((res:any) => {
              if(res['status']){
                this.toaster.success('Password updated successfully.', '', { timeOut: 2000 })
                this.oldPassword = '';
                this.newPassword = '';
                this.confirmPassword = '';
              }
            },(err) => {
                if(err.status === 401){
                  this.toaster.warning(err.error['message'], '', { timeOut: 2000 })
                } else{
                  this.toaster.error('Opps! Something wnet wrong', '', { timeOut: 2000 })
                }
            })
          } else{
            this.toaster.warning('Password & Confirom password doesn\'t match.', '', { timeOut: 2000 })
          }
        } else{
          this.toaster.warning('Please confirm the password.', '', { timeOut: 2000 })
        }
      } else{
        this.toaster.warning('Please enter new password.', '', { timeOut: 2000 })
      }
    } else{
      this.toaster.warning('Please enter old password.', '', { timeOut: 2000 })
    }
  }
}
