import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';

@Component({
  selector: 'app-ins-side-bar',
  templateUrl: './ins-side-bar.component.html',
  styleUrls: ['./ins-side-bar.component.css']
})
export class InsSideBarComponent implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();
  constructor(
    private dataShare: DataShareService,
    private route: Router,
    private toast: ToastrService,
    private httpSv: HttpService
  ) { }

  ngOnInit(): void {
    this.instructor_id = sessionStorage.getItem('user_id')
    if (!this.instructor_id) {
      this.route.navigate(['/login'])
      this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 })
    }
    let data = {
      instructor_id: parseInt(this.instructor_id),
    }
    this.httpSv.getInstructorGame(this.instructor_id).subscribe((res: any) => {
      if (res['status']) {

        this.gameTypes = res['data'].filter((item, index, self) => index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item)));
        console.log(this.gameTypes)
       if(this.gameTypes.length == 0){
        this.currentTab = 'gr'
       }
       else{
        this.currentTab = this.gameTypes[0].name.toLowerCase()
       }
        setTimeout(() => {
         this.changeRoute(this.currentTab,this.currentTab == 'gr' ? 0 : this.gameTypes[0].id)
          
        }, 0); 
     }
      else {
        this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toast.error(err.error.message, '', { timeOut: 2000 })
    })
    // if (this.dataShare.game_types != null) {
    //   this.gameTypes = this.dataShare.game_types
    //   if (this.gameTypes.length == 2) this.currentTab = 'esg'
    //   else this.gameTypes[0] == 1 ? this.currentTab = 'esg' : this.gameTypes[0] == 2 ? this.currentTab = 'epg' : this.currentTab = ''
    //   this.changeRoute(this.currentTab)
    // }

    // else if (sessionStorage.getItem('game_types')) {
    //   this.gameTypes = sessionStorage.getItem('game_types')?.split(',')
    //   if (this.gameTypes.length == 2) this.currentTab = 'esg'
    //   else this.gameTypes[0] == 1 ? this.currentTab = 'esg' : this.gameTypes[0] == 2 ? this.currentTab = 'epg' : this.currentTab = ''
    //   this.changeRoute(this.currentTab)
    // }
    // else this.route.navigate(['/login'])
  }


  currentTab: string = ''
  opengameList: boolean = false;
  gameTypes: any = []

  instructor_id: any
  toggleGameList() {
    this.opengameList = !this.opengameList
  }
  changeRoute(route: any,id:any) {
    // if(route!='esg'&& route!='epg'){
    //   this.opengameList=false
    // }
    if(id != 1 && id != 2){
      sessionStorage.setItem('isCustomGame', '1');
    }
    else{
      sessionStorage.setItem('isCustomGame', '0');
    }
    this.dataShare.instructorGameId = id
    this.messageEmitter.emit('a')
    this.currentTab = route
    this.messageEmitter.emit(route)

  }
  logout() {
    sessionStorage.clear()
    this.route.navigate(['/login'])
  }
}
