import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/https/http.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
	myForm!: FormGroup;
	constructor(
		public dialogRef: MatDialogRef<ResetPasswordComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private fb: FormBuilder,
		private toster: ToastrService,
		private httpSv: HttpService,

	) { }
	
	conpasswordToggleHide:boolean=true
	passwordToggleHide:boolean=true
	newPassword:string=''
	cfPassword:string=''
	ngOnInit(): void {
		this.myForm = this.fb.group({
			password: ['', [Validators.required]],
			confirmPassword: ['', [Validators.required]],
		  });
	}
	update(){
		if(this.newPassword != ''){
			if(this.cfPassword != ''){
				if(this.newPassword == this.cfPassword){
					const data = {
						instructor_id: this.data.instructor_id,
						password: this.newPassword
					}
					this.httpSv.resetInstructorPassword(data).subscribe((res: any) => {
						if (res['status']) {
							this.toster.success('Password updated successfully', '', { timeOut: 2000 })
							this.dialogRef.close(true)
						}
						else {
						  this.toster.error(res.error[0], '', { timeOut: 2000 })
						}
					  }, (err) => {
						this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
					  })
					this.dialogRef.close(true)
				} else {
					this.toster.error('Password and confirm password doesn\'t match', '', { timeOut: 2000 })
				}
			} else {
				this.toster.error('Please fill confirm password', '', { timeOut: 2000 })
			}
		} else {
			this.toster.error('Please fill password', '', { timeOut: 2000 })
		}
	}
	close(){
		this.dialogRef.close(false)
	}
}
