import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';

@Component({
  selector: 'app-about-game',
  templateUrl: './about-game.component.html',
  styleUrls: ['./about-game.component.css']
})
export class AboutGameComponent implements OnInit {

  @Output() messageEmitter = new EventEmitter<any>();
  @ViewChild('scrollContainer1', { static: true }) scrollContainer1!: QueryList<ElementRef>;
  onScroll(num: string) {
    if (document.getElementById('scrollContainer' + num)) {
      const h1 = document.getElementById('scrollContainer' + num)?.scrollHeight
      const h2 = document.getElementById('scrollContainer' + num)?.scrollTop
      if (h1 && h2) {
        const diff = h1 - h2
        if (diff - 1 === document.getElementById('scrollContainer' + num)?.clientHeight || diff === document.getElementById('scrollContainer' + num)?.clientHeight) {
          this.showScrollToTopButton = true
        }
        else {
          this.showScrollToTopButton = false
        }
      }
    }
  }
  showScrollToTopButton = false;
  constructor(
    private router: Router,
    private httpSv: HttpService,
    private dataSv: DataShareService,
    private toster: ToastrService
  ) { }
  @Input() condition: any
  headerShowFlag: boolean = true
  playBtnFlag: boolean = false

  gameId: any;
  authToken: any;
  loading: boolean = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.loading = false;
    }, 2000);
    if (this.router.url == '/about') this.playBtnFlag = true
    if (this.router.url == '/rounds') this.headerShowFlag = false
    if (sessionStorage.getItem('gameComplete') == 'true' || sessionStorage.getItem('roundCondition') == 'true') {
      this.router.navigate(['/login'])
    }
    this.getPlayerGameInfo();

    this.gameId = sessionStorage.getItem('gameId')
    this.authToken = sessionStorage.getItem('authToken')
    const id = sessionStorage.getItem('playerId')
    if (id && sessionStorage.getItem('gameSessionCode')) this.playerId = id
    else {
      if (this.router.url !== '/overview') {
        this.router.navigate(['/login'])
        this.toster.error('Oops! Something went wrong, please try again')
      }
      else{
        this.headerShowFlag = false
      }
    }
    setTimeout(() => {
      if (this.page == 0) {
        this.page = 1
      }
    }, 7000)
  }
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    const image = document.getElementById('img_header');
    if (image) {
      const width = image.offsetWidth;
      const element = document.getElementById('content_text_main_div')
      if (element) {
        element.style.width = (width - 30).toString() + 'px'
      }
    } 
  }
  playerInstructions: any = [];
  gameUniIntroText:string= '';
  totalInfoPages: number = 0;
  uniLogo: any;
  infoPages: any = []
  @Input() page!: number;

  changePage(selectedPage:number){
    this.page = selectedPage;
  }

  makeWordsBold(text) {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong style="font-weight: bold">$1</strong>');
  }

  getPlayerGameInfo(){
    const sesison_code = sessionStorage.getItem('gameSessionCode')

    this.httpSv.getPlayerGameInfo(sesison_code).subscribe((res:any) => {
      if(res['status']){
        this.playerInstructions = res['data'];
        sessionStorage.setItem('university_logo', res['data']?.configDtl?.university_logo);
        sessionStorage.setItem('game_detail_text', res['data']?.configDtl?.gameDetailText);
        this.totalInfoPages = res['data']?.pages.length;
        this.infoPages = res['data']?.pages
        const tempPages = res['data']?.pages
        tempPages.forEach((page, index) => {
          const paragraphs = page.desc_text.split('\n\n');
          let desc_text:any = [];
          paragraphs.forEach(paragraph => {
            desc_text.push(paragraph);
          });
          this.infoPages[index].desc_text = desc_text;
        });

        this.uniLogo = res['data']?.configDtl?.university_logo;
        this.gameUniIntroText = res['data']?.configDtl.unitext_desc;
        // var data = sessionStorage.getItem("isCalled")
        var data = 0
        if(!data){
          if (res['data'].configDtl.show_unitext == 1){
            this.page = 0
          } else {
            this.page = 1
          }
        }
        sessionStorage.setItem('isCalled', '1');
      } else{
        this.toster.error('Oops! Something went wrong.', '', { timeOut: 2000 });
      }
    },(err:any) => {
      if (err.status == 422) {
        this.toster.warning(err.error['message'], '', { timeOut: 2000 });
      } else{
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 });
      }

    })
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    const image = document.getElementById('img_header');
    if (image) {
      image.addEventListener('load', () => {
        const width = image.offsetWidth;
        console.log(width)
        const element = document.getElementById('content_text_main_div')
        if (element) {
          element.style.width = (width - 30).toString() + 'px'
        }
      });
    }
    if (document.getElementById('scrollContainer1')) {
      const container = document.getElementById('scrollContainer1')
      if (container) {
        if (container.scrollHeight > container.clientHeight) {
          this.scrollContainer1Flag = true
        } else {
          this.scrollContainer1Flag = false
        }
      }
    }
  }
  scrollDown(num: string) {
    const count = document.getElementById('scrollContainer' + num)?.scrollTop
    if (count || count == 0) {
      document.getElementById('scrollContainer' + num)?.scroll({
        top: count + 150,
        left: 0,
        behavior: "smooth",
      })
    }
  }
  scrollTop(num: string) {
    const count = document.getElementById('scrollContainer' + num)?.scrollTop
    if (count || count == 0) {
      document.getElementById('scrollContainer' + num)?.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      })
    }
  }
  playerId: any
  scrollContainer1Flag: boolean = false
  scrollContainer2Flag: boolean = false
  scrollContainer3Flag: boolean = false
  scrollContainer4Flag: boolean = false
  scrollContainer5Flag: boolean = false

  leftArrow() {
    this.showScrollToTopButton = false
    if (this.page > 1)
      this.page -= 1;
    setTimeout(() => {
      if (this.page == 1) {
        if (document.getElementById('scrollContainer1')) {
          const container = document.getElementById('scrollContainer1')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer1Flag = true
            } else {
              this.scrollContainer1Flag = false
            }
          }
        }
      }
      else if (this.page == 2) {
        if (document.getElementById('scrollContainer2')) {
          const container = document.getElementById('scrollContainer2')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer2Flag = true
            } else {
              this.scrollContainer2Flag = false
            }
          }
        }
      }
      else if (this.page == 3) {
        if (document.getElementById('scrollContainer3')) {
          const container = document.getElementById('scrollContainer3')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer3Flag = true
            } else {
              this.scrollContainer3Flag = false
            }
          }
        }
      }
      else if (this.page == 4) {
        if (document.getElementById('scrollContainer4')) {
          const container = document.getElementById('scrollContainer4')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer4Flag = true
            } else {
              this.scrollContainer4Flag = false
            }
          }
        }
      }else if (this.page == 5) {
        if (document.getElementById('scrollContainer5')) {
          const container = document.getElementById('scrollContainer5')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer5Flag = true
            } else {
              this.scrollContainer5Flag = false
            }
          }
        }
      }
    }, 0)

  }
  rightArrow() {
    console.log('nextFunc')
    this.showScrollToTopButton = false
    if (this.page <= this.totalInfoPages-1)
      this.page += 1;
    setTimeout(() => {
      if (this.page == 1) {
        if (document.getElementById('scrollContainer1')) {
          const container = document.getElementById('scrollContainer1')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer1Flag = true
            } else {
              this.scrollContainer1Flag = false
            }
          }
        }
      }
      else if (this.page == 2) {
        if (document.getElementById('scrollContainer2')) {
          const container = document.getElementById('scrollContainer2')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer2Flag = true
            } else {
              this.scrollContainer2Flag = false
            }
          }
        }
      }
      else if (this.page == 3) {
        if (document.getElementById('scrollContainer3')) {
          const container = document.getElementById('scrollContainer3')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer3Flag = true
            } else {
              this.scrollContainer3Flag = false
            }
          }
        }
      }
      else if (this.page == 4) {
        if (document.getElementById('scrollContainer4')) {
          const container = document.getElementById('scrollContainer4')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer4Flag = true
            } else {
              this.scrollContainer4Flag = false
            }
          }
        }
      }
      else if (this.page == 5) {
        if (document.getElementById('scrollContainer5')) {
          const container = document.getElementById('scrollContainer4')
          if (container) {
            if (container.scrollHeight > container.clientHeight) {
              this.scrollContainer5Flag = true
            } else {
              this.scrollContainer5Flag = false
            }
          }
        }
      }
    }, 0)

  }

  startRound() {
    this.httpSv.startGame(this.playerId, this.authToken, this.gameId).subscribe((res: any) => {
      if (res['status']) {
        sessionStorage.setItem('gameDetails', JSON.stringify(res['result']['game_detail']));
        sessionStorage.setItem('gameRounds', JSON.stringify(res['result']['game_rounds']));
        if(res['result']['is_custom_game']){
          sessionStorage.setItem('customFormula', JSON.stringify(res['result']['formula_lables']));
          sessionStorage.setItem('customLables', JSON.stringify(res['result']['custom_lables']));
          sessionStorage.setItem('isCustomGame', '1');
        }
        else{
          sessionStorage.setItem('isCustomGame', '0');

        }
        sessionStorage.setItem('roundNumber', '1')
        this.router.navigate(['/rounds'])
      }
      else {
        this.toster.error(res.message, '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  opendrawer(data: any) {
    if (data) {
      if (data != 'resume')
        this.page = data
      else
        this.messageEmitter.emit(data)
    }
  }
}
