<div class="instructorConatainer" *ngIf="!isAddGame">
    <div class="header">
        <div class="title"> 
            <p>EPG GAME LIST</p>
        </div>
        <div class="addInstructor">
            <img [ngClass]="Strategies.length==3?'disable_edit':''" (click)="addGame('new',0)" style="margin-top: 20px;" src="../../../../assets/img/EPG.svg"/>
        </div>
    </div>
    <div class="body" *ngIf="Strategies!=0">
        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="search" class="search_icon_inp" placeholder="Search"
                        (keyup)="applyFilter($event)" autocomplete="off">
                </div>
            </div>
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">
    
                <!-- Sl NO -->
                <ng-container matColumnDef="sl_no">
                    <th class="" mat-header-cell *matHeaderCellDef  [style.width.%]="8"> SN. </th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>
    
                <!-- Retail User Name  -->
                <ng-container matColumnDef="name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="24" >Strategy Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">{{element.name}}</td>
                </ng-container>
    
                <!-- Company name -->
                <ng-container matColumnDef="action">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef  [style.width.%]="11"> Action</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex;">
                           
                            <img src="../../../../assets/img/edit_icon.svg" alt="" [ngbTooltip]="editTooltip" (click)="editStrategy(element.id)"
                                 style="cursor: pointer;">
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img src="../../../../assets/img/copy.svg" alt="" [ngbTooltip]="copyTooltip" [ngClass]="Strategies.length==3?'disable_edit':''" (click)="copyStrategy(element.id)"
                                 style="cursor: pointer;">
                        </div>
                    </td>
                </ng-container>
    
                <!-- City name -->
                <ng-container matColumnDef="status">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef  [style.width.%]="10" > Status </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div class=" " id="dropdownMenuLink" 
                        [ngClass]="element.is_active==1?'green':'red'" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                    >
                        {{element.is_active?'Active':'InActive'}}
                    </div>
                    </td>
                </ng-container>
    
               
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <div class="nogameBody" *ngIf="Strategies.length==0">
        <div class="noGame">NO GAMES FOUND</div>
        <img src="../../../../../assets/img/nogame.svg"/>
    </div>
</div>
<app-addModify-game *ngIf="isAddGame" (messageEmitter)="addGame('',0)" ></app-addModify-game>
