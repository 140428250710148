<div class="instructorConatainer" *ngIf="!isAddRound">
    <div class="gameHeader">
        <div class="gameHeaderLeft">
            <img style="cursor: pointer;" (click)="goBackGame()" src="../../../../assets/img/backArrow.svg"/>
            <p *ngIf="addGame == 'new'">CREATE NEW GAME</p>
            <p *ngIf="addGame == 'edit'">EDIT GAME</p>
        </div>
        <div class="gameHeaderRight">
            <div class="gameCancel" (click)="goBackGame()">CANCEL</div>
            <div class="gameSave" (click)="saveGame()" >SAVE</div>
        </div>
    </div>
    <div class="gameDetails">
        <div class="gameFields">
            <label>Enter the {{createdGameName.toUpperCase()}} Game Name<b>*</b></label>
            <input type="text"  [(ngModel)]="gameName" >
        </div>
        <div class="gameFields">
            <label>Enter the {{createdGameName.toUpperCase()}} Game Code<b>*</b></label>
            <input type="text"  [(ngModel)]="gameCode" (input)="onInputChange('gameCode')" maxlength="8">
        </div>
        <div class="gameFields">
            <label>Game Instructions <b>*</b></label>
            <div class="gameAddInstructions" (click)="handleAddInstructions()">
                <img src="../../../../assets/img/addInstructions.svg"/>
                ADD
            </div>
        </div>
        <div class="gameFields">
            <label>Student Unique Identifier<b>*</b></label>
            <mat-slide-toggle
                class="example-margin"
                color="#0D8BBC"
                [(ngModel)]="checked"
                >
            </mat-slide-toggle>   
         </div>
        <!-- FOR ESG GAME  -->
        <div class="gamePoints" *ngIf="createdGameId == 1">
            <label>Game Min-Max Points</label>
            <div class="gamePointsInput">
                <div class="input-container">
                    <label>Max Effort points<b>*</b></label>
                    <input type="text" (keypress)="preventText($event)" name="effortPoints" [(ngModel)]="effortPoints">
                </div>
                <div class="input-container">
                    <label>Min ESG Score<b>*</b></label>
                    <input type="text" (keypress)="preventText($event)" name="minEsgScore" [(ngModel)]="minEsgScore">
                </div>
                <div class="input-container">
                    <label>Max ESG Score<b>*</b></label>
                    <input type="text" (keypress)="preventText($event)" name="maxEsgScore" [(ngModel)]="maxEsgScore">
                </div>
                <div class="input-container">
                    <label>Min Brand Value<b>*</b></label>
                    <input type="text" (keypress)="preventText($event)" name="minBrandValue" [(ngModel)]="minBrandValue">
                </div>
                <div class="input-container">
                    <label>Max Brand Value<b>*</b></label>
                    <input type="text" (keypress)="preventText($event)" name="maxBrandValue" [(ngModel)]="maxBrandValue">
                </div>                
            </div>           
        </div>
        <!-- FOR ALIKE ESG GAME -->
        <div class="gamePoints" *ngIf="createdGameId != 1">
            <label>Score Points</label>
            <div class="gamePointsInput">
                <div class="input-container" *ngFor="let gameLabel of gameLables;let i = index">
                    <div class="d-flex justify-content-between"><label style="margin-top: 10px;">{{gameLabel.name}}<b>*</b></label><div style="margin-top: 10px;"><img (click)="deleteLabel(gameLabel.id)"  style="width: 20px;height: auto;cursor: pointer;" src="../../../../assets/img/cross-svgrepo-com.svg"/></div></div>
                    <input type="text" (keypress)="preventText($event)" name = "{{gameLabel.id}}" [(ngModel)]="gameLabel.value" >
                </div>  
                <div class="gameAddLabel" style="min-width: 12rem;" (click)="handleAddGameLabel()">
                    <img src="../../../../assets/img/addInstructions.svg"/>
                    ADD SCORE LABEL
                </div>    
            </div>           
        </div>
        <div class="gamePoints" *ngIf="createdGameId != 1">
            <label>Score Label</label>
            <div class="gamePointsInput">
                <div class="input-container" *ngFor="let formula of gameFormula;let i = index">
                    <div class="d-flex justify-content-between" style="margin-bottom: 5px;"><label style="margin-top: 10px;">{{formula.label}}<b>*</b></label><div style="margin-top: 10px;">
                        <div style="display: flex;">
                            <img (click)="editFormula(formula)"  style="width: 15px;height: auto;cursor: pointer;margin-right: 5px;" src="../../../../assets/img/edit_icon.svg"/>
                            <img (click)="deleteFormula(formula.id)"  style="width: 20px;height: auto;cursor: pointer;" src="../../../../assets/img/cross-svgrepo-com.svg"/>
                        </div>
                    </div></div>
                    <input type="text" (keypress)="preventText($event)" value="{{formula.formula}}" disabled >
                    <div class="formulaValue">
                        <label style="margin-right: 5px;">Value</label>
                        <input type="text"  [value]="formula.value ? formula.value: 0" disabled >
                    </div>
                    <div class="formulaValue" *ngIf="formula.is_curve_ball == 1">
                        <label style="margin-right: 5px;">Is Effort Point</label>
                        <input type="text"  value="YES" disabled >
                    </div>
                </div>  
                <div class="gameAddLabel" [ngClass]="gameFormula.length>=4 ? 'disabled': ''"  (click)="handleMakeFormula()">
                    <img src="../../../../assets/img/addInstructions.svg"/>
                    GENERATE FORMULA
                </div> 
                   
            </div>           
        </div>
    </div>
    <div style="border-top: 2px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
    <div class="gameBody">
        <div *ngIf="rounds.length==0" class="gameAddRound" (click)="handleAddRounds()">ADD ROUNDS</div>
        <div class="GameTable" *ngIf="rounds.length>0" style="width: 100%;">
        <div class="web_user_search_main_div" style="justify-content: space-between;">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="text" class="search_icon_inp" placeholder="Search"
                        (keyup)="applyFilter($event)" autocomplete="off">
                </div>
            </div>    
            <div class="gameAddRound" style="margin-top: 0; border-radius: 8px;" (click)="handleAddRounds()">ADD ROUNDS</div>
    
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;" cdkDropList
                [cdkDropListData]="dataSource"
                (cdkDropListDropped)="dropTable($event)">
    
                <!-- Sl NO -->
                <ng-container matColumnDef="round_no">
                    <th class="" mat-header-cell *matHeaderCellDef [style.width.%]="15"> Round No </th>
                    <td  class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>
    
                <ng-container matColumnDef="theme_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="51">Round / Theme Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">  {{element.round_name}}</td>
                </ng-container>
                <ng-container matColumnDef="ques_linkage">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="20">Question's Linkage</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element" 
                        [ngClass]="element.status == 'intact'?'green': element.status == 'broken'?'red':null">
                        {{element.status != null ? element.status.toUpperCase() : 'N/A'}}
                    </td>
                </ng-container>
    
                <!-- Modify -->
                <ng-container matColumnDef="modify">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="14"> Modify</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex;">
                            <!-- <img src="../../../../assets/img/edit-game.svg"  alt="" (click)="handleAddInstructions()" style="cursor: pointer;"
                                >
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div> -->
                            <img src="../../../../assets/img/remove_icon_bold.svg" (click)="handleDelete(element.id)" alt="" style="cursor: pointer;"
                                >
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>

                            <img src="../../../../assets/img/edit_icon.svg" alt="" 
                                  (click)="addRound(element)" style="cursor: pointer;">
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img style="cursor: pointer;" cdkDragHandle src="../../../../assets/img/mingcute_dots-line.svg"/>
                        </div>
                    </td>
                </ng-container>
         
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" cdkDrag [cdkDragData]="row"></tr>
            </table>
        </div>
        </div>
    </div>
</div>

  <app-addModify-round *ngIf="isAddRound" (messageEmitterRound)="addRound('')"></app-addModify-round>