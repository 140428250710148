<div class="dialogueConfigureGame" style="width:90dvw;">
    <div class="header">
        <div class="headerTxt">
            Feedback Questions
        </div>
        <img style="cursor: pointer;" (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg" />
    </div>
    <div class="feedback_main_body">
        <div class="feedback_sub_body" *ngIf="gameFeedbackQues.length != 0">
            <div *ngFor="let item of gameFeedbackQues;let i = index;" class="feedback_item_div">
                <!-- Question (MCQ) -->
                <div class="feedQue_question_div">
                    <div class="feedback_question_txt">{{i+1}}] {{item.question}} <span
                            style="color: #FC4754;">&nbsp;*</span></div>
                </div>
                <!-- <div class="feedQue_option_main_div" *ngIf="item.options.length > 0">
                    <button class="feedQue_option_sub_div" (click)="feedbackOptionHandel(item.options[0] , item)"
                        [ngClass]="feedBackData[item.id]?.id == item.options[0].id? 'feedback_option_active':''">
                        <div>a</div>
                        <div>{{item.options[0].option}}</div>
                    </button>
                    <button class="feedQue_option_sub_div" (click)="feedbackOptionHandel(item.options[1] , item)"
                        [ngClass]="feedBackData[item.id]?.id == item.options[1].id? 'feedback_option_active':''">
                        <div>b</div>
                        <div>{{item.options[1].option}}</div>
                    </button>
                    <button class="feedQue_option_sub_div" (click)="feedbackOptionHandel(item.options[2] , item)"
                        [ngClass]="feedBackData[item.id]?.id == item.options[2].id? 'feedback_option_active':''">
                        <div>c</div>
                        <div>{{item.options[2].option}}</div>
                    </button>
                    <button class="feedQue_option_sub_div" (click)="feedbackOptionHandel(item.options[3] , item)"
                        [ngClass]="feedBackData[item.id]?.id == item.options[3].id? 'feedback_option_active':''">
                        <div>d</div>
                        <div>{{item.options[3].option}}</div>
                    </button>
                </div> -->


                <div style="text-align: center;">
                    <form class="example-form">
                        <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
                            <mat-label>Type your answer here...</mat-label>
                            <!-- <textarea matInput #message maxlength="100" placeholder=""> -->
                            <textarea matInput (input)="textAreaAdjust($event)" [name]="'feedBack'+item.id" [(ngModel)]="feedBackData[item.id]"
                                (input)="feedbackTextAreaHandel($event,item)" style="font-size: 18px;"></textarea>
                        </mat-form-field>
                    </form>
                </div>

            </div>
            <!-- Next Btn -->
            <div class="feedback1_next_btn_main_div">
                <div  (click)="saveInsAns()">SAVE</div>
            </div>
        </div>
    </div>
</div>



<style>
    ::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
        background: transparent !important;
        border: 3px solid black !important;
    }
</style>