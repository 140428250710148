import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/https/http.service';
import { DialogueAddEmail, DialogueRenameReport } from '../../superAdmin/game-report/game-report.component';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { InsStrategyDialog } from '../ins-epg-list/ins-epg-list.component';
import { EpgReportComponent } from '../../epg-report/epg-report.component';
import { Sort, MatSortModule } from '@angular/material/sort';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import * as JSZip from 'jszip';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import { interval } from 'd3';

@Component({
  selector: 'app-ins-game-report',
  templateUrl: './ins-game-report.component.html',
  styleUrls: ['./ins-game-report.component.css']
})
export class InsGameReportComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    private router: Router,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataShare: DataShareService
  ) { }

  email: any = '';
  name: any = '';

  ngOnInit(): void {
    this.instructor_id = sessionStorage.getItem('user_id')
    if (!this.instructor_id) {
      this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
      this.router.navigate(['/login'])
    }
    if(this.instructor_id){
      this.httpSv.getInstructorDetails(this.instructor_id).subscribe((res : any) => {
        if(res){
          this.email = res.results[0].email;
          this.name = res.results[0].name;
        }
      }
    )};
    this.getAllReport()
  }

  instructor_id: any
  isGameSummary: any = false;
  selectedFilter: any = '';
  displayedColumns: string[] = ['report_name', 'game_name', 'univ_name', 'univ_id', 'email', 'rename', 'view'];
  dataBM: any[] = []
  dataSource: any = new MatTableDataSource();
  filterByStatus(data: any) {
    this.selectedFilter = data
    if (data == 'all') {
      const filterData = this.apiData
      this.dataSource.data = filterData
    }
    else if (data == 'esg') {
      const filterData = this.apiData.filter((ele: any) => ele.game_type == 1)
      this.dataSource.data = filterData
    }
    else if (data == 'epg') {
      const filterData = this.apiData.filter((ele: any) => ele.game_type == 2)
      this.dataSource.data = filterData
    }
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  epgGameSummary: boolean = false
  esgGameSummary: boolean = false
  sessionCode: any
  togleGameSummary(game_type: any, seesion_code: any = null, sessionId: any = null) {
    this.isGameSummary = !this.isGameSummary
    if (game_type != 2) {
      this.dataShare.reportId = sessionId
      this.esgGameSummary = true
      this.epgGameSummary = false
    } else if (game_type == 2) {
      this.esgGameSummary = false
      this.epgGameSummary = true
      this.sessionCode = seesion_code
    }
    else if (game_type == 'close') {
      this.epgGameSummary = false
      this.esgGameSummary = false
      this.sessionCode = null
    }
    else {
      this.sessionCode = null
      this.toster.error('Oops! Something went wrong')
    }
  }

  renameReport(element: any) {
    const dialogRef = this.dialog.open(DialogueRenameReport, {
      disableClose: false,
      hasBackdrop: true,
      data: {
        element
      }
    }).afterClosed().subscribe((res: any) => {
      if (res)
        this.getAllReport()
    })

  }
  apiData: any[] = []

  getAllReport() {
    this.httpSv.getInsGameReport(this.instructor_id).subscribe((res: any) => {
      if (res['status']) {
        this.dataSource.data = res['data']
        this.apiData = res['data']
      }
      else {
        this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
    })
  }

}






export interface EpgData {
  name: string,
  upstart_price: string,
  price_prediction: number,
  request: number,
  action: number
}


// Epg Summary Page 
// Epg Summary Page 
// Epg Summary Page 
// Epg Summary Page 

@Component({
  selector: 'app-epg-summary-game',
  templateUrl: './ins-epg-summary.component.html',
  styleUrls: ['./ins-game-report.component.css'],
  // standalone: true,
  // imports: [MatSortModule]
})
export class InsEpgSummaryComponent implements OnInit {
  @Output() messageEmitterSummary = new EventEmitter<any>();
  @Input() session_code: any = null
  @Input() instructor_id: any = null
  // @ViewChild(MatSort) sort = new MatSort();
  constructor(
    private toster: ToastrService,
    private httpSv: HttpService,
    private dialog: MatDialog
  ) {
    this.sortedData = this.dataSource.data
  }

  ngOnInit(): void {
    this.getStudentsReport()
  }

  hideLoader(): void {
    const loader = document.querySelector('ng-http-loader') as HTMLElement;
    if (loader) {
      loader.style.display ='none';
    }
  }

  showLoader(): void {
    const loader = document.querySelector('ng-http-loader') as HTMLElement;
    if (loader) {
      loader.style.display ='block';
    }
  }


  sortedData: EpgData[] = [];
  displayedColumns: string[] = ['name', 'upstart_price', 'price_prediction', 'request', 'action'];
  dataSource: any = new MatTableDataSource();
  apiGameList: EpgData[] = []
  goBackSummary() {
    this.messageEmitterSummary.emit()
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showStrategy() {
    this.dialog.open(InsStrategyDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        viewOnly: true,
        strategyData: this.apiGameList['strategy_data'][0]
      }
    })
  }
  deletePlayer(player_id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: { heading: 'Are you sure to delete this player ?' }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.httpSv.deleteEpgPlayerReport(player_id).subscribe((res: any) => {
          if (res['status']) {
            this.ngOnInit()
          } else {
            this.toster.error(res.message, '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
      }
    })
  }
  allDataApi: any = []
  getStudentsReport() {
    this.httpSv.getInsSessionReport(this.session_code).subscribe((res: any) => {
      if (res['status']) {
        this.dataSource.data = res['results']['player_data']
        this.allDataApi = res['results']['player_data']
        // this.dataSource.sort = this.sort
        // this.sortedData = this.dataSource.data
        this.apiGameList = res['results']
      }
      else {
        this.toster.info(res['message'], '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }
  downloadReport(element: any) {
    this.showReport(element.id, false, element, true)
  }
  sendEmail(element: any) {
    if (element.email) {
      this.showReport(element.id, true, element)
    }
    else {
      const dialogRef = this.dialog.open(DialogueAddEmail, {
        disableClose: true,
        hasBackdrop: true,
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          element.email = res;
          this.showReport(element.id, true, element)
        }
      })
    }
  }
  showReport(player_id: any, sendFlag: boolean = false, ele: any = null, downloadFlag: boolean = false) {
    this.httpSv.getReportDtls(player_id, this.instructor_id, this.apiGameList['strategy_data'][0].id, this.session_code).subscribe((res: any) => {
      if (res['status']) {
        const dialogRef = this.dialog.open(EpgReportComponent, {
          hasBackdrop: true,
          disableClose: downloadFlag ? false : true,
          height: '90vh',
          data: {
            data: res['results'],
            flag: sendFlag,
            downloadFlag
          }
        })
        if (sendFlag) {
          dialogRef.afterClosed().subscribe((res: any) => {
            if (res) {
              res.append('email', ele.email)
              res.append('name', ele.name)
              res.append('player_id', player_id)
              this.httpSv.sendEpgEmail(res).subscribe((res: any) => {
                if (res['status']) {
                  this.toster.success('Email sent sucessfully', '', { timeOut: 2000 })
                  this.ngOnInit()
                } else {
                  this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                }
              }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
            }
          })
        }
      }
      else this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
  }
  
  sortedDataCahe: any = {}
  sortData(sort: Sort) {
    const data = this.apiGameList['player_data'];
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    if (sort.active + sort.direction in this.sortedDataCahe) {
      this.dataSource.data = this.sortedDataCahe[sort.active + sort.direction]
      return;
    }
    this.sortedData = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'upstart_price':
          if (isAsc) {
            if (+a.upstart_profit > +b.upstart_profit) return 1
            else if (+a.upstart_profit < +b.upstart_profit) return -1
            else return 0
          } else {
            if (+a.upstart_profit > +b.upstart_profit) return -1
            else if (+a.upstart_profit < +b.upstart_profit) return 1
            else return 0
          }
        case 'price_prediction':
          if (isAsc) {
            if (+a.prediction_accuracy > +b.prediction_accuracy) return 1
            else if (+a.prediction_accuracy < +b.prediction_accuracy) return -1
            else return 0
          } else {
            if (+a.prediction_accuracy > +b.prediction_accuracy) return -1
            else if (+a.prediction_accuracy < +b.prediction_accuracy) return 1
            else return 0
          }
        default:
          return 0;
      }
    });
    this.dataSource.data = this.sortedData
    this.sortedDataCahe[sort.active + sort.direction] = [...this.sortedData]
  }

  updateProgress(totalReports: number): void {
    this.sendAllIndex++;
    this.progressValue = Math.round((this.sendAllIndex / totalReports) * 100);
  }

  sendAllIndex: number = 0
  successReportCount: number = 0
  failedReportCount: number = 0
  isProcessing: boolean = false;
  progressValue: number = 0;
  intervalRef: any;

  sendAllPDF() {
    this.isProcessing = true;
    this.hideLoader();
    this.sendAllIndex = 0;
    this.progressValue = 0; 
    this.successReportCount = 0;
    this.failedReportCount = 0;
    sessionStorage.setItem('reportProgress','true');

    const pdfData = this.dataSource.data;
    const requestedPdfs = pdfData.filter((pdf) => pdf.is_requested == 1 && pdf.is_report_sent == 0);
    const totalpdf = requestedPdfs.length;

    const sendNext = () => {
      if (this.sendAllIndex < totalpdf) {
        const allPdfData = requestedPdfs[this.sendAllIndex];
        this.httpSv.getReportDtls(allPdfData.id, this.instructor_id, this.apiGameList['strategy_data'][0].id, this.session_code).subscribe((res: any) => {
          if (res['status']) {
            const dialogRef = this.dialog.open(EpgReportComponent, {
              hasBackdrop: true,
              disableClose: false,
              height: '90vh',
              data: {
                data: res['results'],
                flag: true,
                downloadFlag: false
              }
            });
            dialogRef.afterClosed().subscribe((dialogRequest: any) => {
              if (dialogRequest) {
                dialogRequest.append('email', allPdfData.email); //allPdfData.email
                dialogRequest.append('name', allPdfData.name);
                dialogRequest.append('player_id', allPdfData.id);

                this.httpSv.sendEpgEmail(dialogRequest).subscribe((mailRequest: any) => {
                  if (mailRequest['status']) {
                    this.successReportCount++;
                  } else {
                    this.failedReportCount++;
                  }
                  this.updateProgress(totalpdf);
                  sendNext();
                }, (err: any) => {
                  this.failedReportCount++;
                  this.updateProgress(totalpdf);
                  sendNext();
                });
              } else {
                this.failedReportCount++;
                this.updateProgress(totalpdf);
                sendNext();
              }
            });
          } else {
            this.failedReportCount++;
            this.updateProgress(totalpdf);
            sendNext();
          }
        }, (err: any) => {
          this.failedReportCount++;
          this.updateProgress(totalpdf);
          sendNext();
        });
      } else {
        this.isProcessing = false;
        this.showLoader();
        this.toster.success(`${this.successReportCount} report(s) sent successfully, ${this.failedReportCount} failed`);
        this.sendAllIndex = 0;
        this.ngOnInit();
        sessionStorage.removeItem('reportProgress');
      }
    };
    sendNext();
  }

  async downloadAll() {
    this.hideLoader();
    this.isProcessing = true;
    this.progressValue = 0; 
    this.successReportCount = 0;
    this.failedReportCount = 0;  
    this.sendAllIndex = 0;
    sessionStorage.setItem('reportProgress','true');

    let pdfData = JSON.parse(JSON.stringify(this.dataSource.data))
    const zip = new JSZip();
    let temp = 0;
    let openedId = -1;
    const totalPdf = pdfData.length;

    let pdfPromises = pdfData.map((allPdfData, index) => {
      return new Promise<Blob>(async (resolve, reject) => {
        if(this.sendAllIndex < totalPdf){
          const downloadPdf = pdfData[this.sendAllIndex];
          downloadPdf.downloadAllReports = true;

          this.httpSv.getReportDtls(allPdfData.id, this.instructor_id, this.apiGameList['strategy_data'][0].id, this.session_code).subscribe((res: any) => {
            if (res['status']) {
              const intervalRef = interval(() => {
                if (index == temp && openedId != index) {
                  openedId++
                  const dialogRef = this.dialog.open(EpgReportComponent, {
                    hasBackdrop: true,
                    disableClose: false,
                    height: '90vh',
                    data: {
                      data: res['results'],
                      flag: false,
                      downloadFlag: false,
                      downloadAll: true
                    }
                  });
                  dialogRef.afterClosed().subscribe((pdfBlob: Blob) => {
                    temp++
                    if (pdfBlob) {
                      zip.file(`${allPdfData.name}.pdf`, pdfBlob);
                      resolve(pdfBlob);
                      this.updateProgress(totalPdf);
                      this.successReportCount++;
                    } else {
                      resolve(pdfBlob)
                      console.error('Failed to generate PDF for report:', allPdfData);
                      this.failedReportCount++;
                      this.updateProgress(totalPdf);
                    }
                  });
                }
                if(allPdfData.length -1 == temp) intervalRef.stop()
              },0)
            } else {
              console.error('Failed to fetch report details for PDF:', allPdfData);
              this.failedReportCount++;
              this.updateProgress(totalPdf);
            }
          });
        }
      });
    });
    Promise.all(pdfPromises)
    .then(() => {
      return zip.generateAsync({ type: 'blob' });
    })
    .then(content => {
      saveAs(content, 'game_reports_epg.zip');
      this.showLoader()
      this.isProcessing = false;
      sessionStorage.removeItem('reportProgress');
      this.toster.success(`${this.successReportCount} report(s) sent successfully, ${this.failedReportCount} failed`);
    })
    .catch(error => {
      console.error('Error creating zip file:', error);
      this.isProcessing = false;
    });
  }

  cancel(){
    this.isProcessing = false;
    this.progressValue = 0;
    this.successReportCount = 0;
    this.failedReportCount = 0;
    this.sendAllIndex = 0;

    this.dialog.closeAll();
    if(this.intervalRef){
      clearInterval(this.intervalRef);
      this.intervalRef == null;
    }
    this.showLoader();
    this.toster.success('Download cancelled');
    location.reload();
  }
}