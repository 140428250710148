import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-delete-dialogue-component',
  templateUrl: './delete-dialogue-component.component.html',
  styleUrls: ['./delete-dialogue-component.component.css']
})
export class DeleteDialogueComponentComponent implements OnInit {

  constructor( 
    public dialogRef: MatDialogRef<DeleteDialogueComponentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ,) { }

  ngOnInit(): void {
  }
  close(){
    this.dialogRef.close(false)
}
  delete(){
    this.dialogRef.close(true)
}
}
