<div>
    <app-header></app-header>
    <div id="uniqueId">
        <div class="main-cointainer">
            <div>
                <div class="heading">
                    GAME SUMMARY
                </div>
                <div class="email-request-main-div" style="margin-top: 0rem; text-align:right; padding-right:1.5rem;" *ngIf="!emailRequested">Send a detailed report via <span
                    (click)="captureImage()">email</span>
                </div>
            </div>
            <div *ngIf="gameType === 1 || gameType ===2">
                <div class="main-content-div">
                    <div class="content-header">
                        <div class="content-heading">Effort Points</div>
                        <div>
                            <!-- <div class="cash-in-hand">
                                <div class="cashInHand-head"></div>
                                <div class="progress-text-div">Cash In Hand</div>
                                <div class="cashInHand-body">
                                    <div style="width: 96%;">
                                        <div class="progress-bar cashInHand-Bar" role="progressbar"
                                            style="background-color: red;height: 15px;" aria-valuenow="75" aria-valuemin="0"
                                            aria-valuemax="100" id="cashInHandBar" [style.width]="cashInHandProgress+'%'"></div>
                                    </div>
                                </div>
                            </div> -->
                            <div class="progress-bar-main-div">
                                <div>
                                    <div class="progress-bar-heading">Effort Points</div>
                                    <div style="background-color: #E3E3E3;border-radius: 10px;">
                                        <div class="progress-bar" role="progressbar"
                                            style="background-color: black;height: 15px;border-radius: 10px;"
                                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                            [style.width]="(cashInHandProgress) + '%'"></div>
                                    </div>
                                </div>
                                <div class="progress-persent-txt">
                                    {{effortValue}}
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div class="content-details-div">
                        <div>
                            <div class="content-sub-heading">Effort Points Allocated</div>
                            <div class="content-details-data alloted-bg">{{budgetAllocated}}</div>
                        </div>
                        <div>
                            <div class="content-sub-heading">Total Points Spent</div>
                            <div class="content-details-data expense-bg"> {{totalExpense}}</div>
                        </div>
                        <div>
                            <div class="content-sub-heading">Points Remaining</div>
                            <div class="content-details-data balance-bg"> {{balance}}</div>
                        </div>
                    </div>
                </div>
                <div class="content-bottom-div">
                    <div class="main-content-div">
                        <div class="content-header">
                            <div class="content-heading">ESG Score</div>
                            <div>
                                <!-- Test mode -->
                                <div class="progress-bar-main-div">
                                    <div>
                                        <div class="progress-bar-heading">ESG Score</div>
                                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                                            <div class="progress-bar" role="progressbar"
                                                style="background-color: black;height: 15px;border-radius: 10px;"
                                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                                [style.width]="(esgProgess) + '%'"></div>
                                        </div>
                                    </div>
                                    <div class="progress-persent-txt">
                                        {{esgProgess}}
                                    </div>
                                </div>
                                <div style="font-size: 16px;">*Normalised ESG score</div>
                                <!-- test mode-->
    
    
    
                                <!-- <div style="width: 96%;">
                                            <div class="progress-bar ESGscore-Bar" role="progressbar" style="height: 15px;"
                                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"
                                                [style.width]="esgProgess+'%'"></div>
                                        </div> -->
    
    
                            </div>
    
                        </div>
                        <div>
                            <div class="rating-scale-text">Rating at the start of game</div>
                            <div class="student-esg-score" [ngClass]="getBgColor(esgData['4'])">{{esgData['4']}}</div>
                        </div>
                        <div>
                            <div class="rating-scale-text">Rating at the end of game</div>
                            <div class="student-esg-score" [ngClass]="getBgColor(esgData[esgGrade])">{{esgData[esgGrade]}}
                            </div>
                        </div>
                        <div>
                            <div class="rating-scale-text">Rating Scale</div>
                        </div>
                        <div class="rating-meter">
                            <div>
                                <div class="rating-sub-meter">
                                    <div class="rating-grade laggard-bg">CCC</div>
                                    <div class="rating-grade laggard-bg">B</div>
                                    <div class="rating-grade average-bg">BB</div>
                                    <div class="rating-grade average-bg">BBB</div>
                                    <div class="rating-grade average-bg">A</div>
                                    <div class="rating-grade leader-bg">AA</div>
                                    <div class="rating-grade leader-bg">AAA</div>
                                </div>
                                <div class="rating-grade-txt-div">
                                    <div class="rating-grade-txt">Laggard</div>
                                    <div class="rating-grade-txt" style="margin-right: 14px;">Average</div>
                                    <div class="rating-grade-txt">Leader</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-content-div">
                        <div class="content-header">
                            <div class="content-heading">Brand Value</div>
                            <div>
                                <!-- <div class="customer-sentiment">
                                    <div class="customerSentiment-head"></div>
                                    <div class="progress-text-div">Customer Sentiment</div>
                                    <div class="cashInHand-body">
                                        <div style="width: 96%;">
                                            <div class="progress-bar customerSentiment-Bar" role="progressbar"
                                                style="background-color: red;height: 15px;" aria-valuenow="75"
                                                aria-valuemin="0" aria-valuemax="100" [style.width]="sentimentProgress+'%'"></div>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="progress-bar-main-div">
                                    <div>
                                        <div class="progress-bar-heading">Brand Value</div>
                                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                                            <div class="progress-bar" role="progressbar"
                                                style="background-color: black;height: 15px;border-radius: 10px;"
                                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                                [style.width]="(sentimentProgress) + '%'"></div>
                                        </div>
                                    </div>
                                    <div class="progress-persent-txt">
                                        {{sentimentProgress}}
                                    </div>
                                </div>
                                <div style="font-size: 16px;">*Normalised brand value</div>
                            </div>
    
                        </div>
                        <div style="text-align: center;">
                            <div>
                                <div class="rating-scale-text" style="text-align: start;">Brand Value at the start of game : <span class="sentiment-percent">50</span>
                                </div>
                            </div>
                            <div>
                                <div class="rating-scale-text" style="text-align: start;">Brand Value at the end of game (normalised): <span class="sentiment-percent">{{sentimentProgress}}</span>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top:1.8rem">
                            <div class="chart">
                                <img src="../../../assets/img/Layer_1.svg" alt="" style="width: 45%;">
                                <img src="../../../assets/img/arrow_chart.svg" alt="" class="arrow-svg"
                                    [style.transform]="'rotateZ('+roundOffPercentSentiment*1.8+'deg)'">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="gameType >2">
                <div class="main-content-div" style="margin-bottom: 1.5rem;">
                    <div class="content-header">
                        <div class="content-heading">{{formulaName1}}</div>
                        <div>
                            <div class="progress-bar-main-div">
                                <div>
                                    <div class="progress-bar-heading">{{formulaName1}}</div>
                                    <div style="background-color: #E3E3E3;border-radius: 10px;">
                                        <div class="progress-bar" role="progressbar"
                                            style="background-color: black;height: 15px;border-radius: 10px;"
                                            aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                            [style.width]="(Value1) + '%'"></div>
                                    </div>
                                </div>
                                <div class="progress-persent-txt">
                                    {{getRound(Value1)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-details-div">
                        <div>
                            <div class="content-sub-heading">Points Allocated</div>
                            <div class="content-details-data alloted-bg">{{getRound(budgetAllocated)}}</div>
                        </div>
                        <div>
                            <div class="content-sub-heading">Total Points Spent</div>
                            <div class="content-details-data expense-bg"> {{getRound(balance)}}</div>
                        </div>
                        <div>
                            <div class="content-sub-heading">Points Remaining</div>
                            <div class="content-details-data balance-bg">{{getRound(totalExpense)}}</div>
                        </div>
                    </div>
                </div>

                <div class="content-bottom-div" *ngIf="formulaName2 !== null && formulaName2 !== undefined">
                    <div class="main-content-div">
                        <div class="content-header">
                            <div class="content-heading">{{formulaName2}}</div>
                            <div>
                                <!-- Test mode -->
                                <div class="progress-bar-main-div">
                                    <div>
                                        <div class="progress-bar-heading">{{formulaName2}}</div>
                                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                                            <div class="progress-bar" role="progressbar"
                                                style="background-color: black;height: 15px;border-radius: 10px;"
                                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                                [style.width]="(Value2) + '%'"></div>
                                        </div>
                                    </div>
                                    <div class="progress-persent-txt">
                                        {{getRound(Value2)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-content-div" *ngIf="formulaName3 !== null && formulaName3 !== undefined">
                        <div class="content-header">
                            <div class="content-heading">{{formulaName3}}</div>
                            <div>
                                <div class="progress-bar-main-div">
                                    <div>
                                        <div class="progress-bar-heading">{{formulaName3}}</div>
                                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                                            <div class="progress-bar" role="progressbar"
                                                style="background-color: black;height: 15px;border-radius: 10px;"
                                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                                [style.width]="(Value3) + '%'"></div>
                                        </div>
                                    </div>
                                    <div class="progress-persent-txt">
                                        {{getRound(Value3)}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-content-div" *ngIf="formulaName4 !== null && formulaName4 !== undefined">
                        <div class="content-header">
                            <div class="content-heading">{{formulaName4}}</div>
                            <div>
                                <div class="progress-bar-main-div">
                                    <div>
                                        <div class="progress-bar-heading">{{formulaName4}}</div>
                                        <div style="background-color: #E3E3E3;border-radius: 10px;">
                                            <div class="progress-bar" role="progressbar"
                                                style="background-color: black;height: 15px;border-radius: 10px;"
                                                aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" id="cashInHandBar"
                                                [style.width]="(Value4) + '%'"></div>
                                        </div>
                                    </div>
                                    <div class="progress-persent-txt">
                                        {{Value4}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>                  