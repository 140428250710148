<div class="insGameOverview">
    <div class="header">
        Game Overview
        <img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>
    <div class="body">
        <div class="gameDetails">
            <div class="gameFields">
                <label>Enter the ESG Game Name<b></b></label>
                <input type="text" disabled="" [(ngModel)]="gameName" >
            </div>
            <div class="gameFields">
                <label>Enter the ESG Game Code<b></b></label>
                <input type="text" disabled=""  [(ngModel)]="gameCode">
            </div>
            <div class="gameFields">
                <label>Game Instructions <b>*</b></label>
                <div class="gameAddInstructions" (click)="handleAddInstructions()">
                    <img style="width: 15px;height: 15px;" src="../../../../assets/img/icons8-view-100.png"/>
                    View
                </div>
            </div>
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;" cdkDropList>
    
                <!-- Sl NO -->
                <ng-container matColumnDef="round_no">
                    <th class="" mat-header-cell *matHeaderCellDef> Round No </th>
                    <td style="width: 25%;" class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>
    
                <ng-container matColumnDef="theme_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Round / Theme Name</th>
                    <td style="width: 100%;" class="common_table_data" mat-cell *matCellDef="let element">  {{element.round_name}}</td>
                </ng-container>
    
                <!-- Modify -->
                <ng-container matColumnDef="modify">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef> View </th>
                    <td style="width: 25%;" class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex;">
                 
                            <img style="cursor: pointer;" (click)="handleViewQuestions(element.id,element.round_name)" src="../../../../assets/img/carbon_view.svg"/>
                        </div>
                    </td>
                </ng-container>
         
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>