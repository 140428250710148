import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from '@angular/material/dialog';
import { ResetPasswordComponent } from '../../commonComponants/reset-password/reset-password.component';
import { DeleteDialogueComponentComponent } from '../../commonComponants/delete-dialogue-component/delete-dialogue-component.component';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/https/http.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-instructor-list',
  templateUrl: './instructor-list.component.html',
  styleUrls: ['./instructor-list.component.css']
})
export class InstructorListComponent implements OnInit {

  constructor(
    private router: Router,
    private httpSv: HttpService,
    private toster: ToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.userId = sessionStorage.getItem('user_id')
    if (!this.userId) {
      this.router.navigate(['/login']);
      this.toster.warning("Something went wrong. Please Login Again", '', { timeOut: 2000 })
    }
    this.getInstructorsList()

  }
  userId: any;
  selectedFilter: any = '';
  displayedColumns: string[] = ['sl_no', 'name', 'university_code', 'email', 'university_name', 'reset_password', 'status', 'modify'];
  dataInstructor: any[] = [];
  dataSource: any = new MatTableDataSource();


  getInstructorsList() {
    this.httpSv.getInstructorsList().subscribe((res: any) => {
      if (res['status']) {
        this.dataSource.data = res['result'];
      }
      else {
        this.toster.error(res.error[0], '', { timeOut: 2000 })
      }
    }, (err) => {
      this.dataSource.data = []
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }
  setStatus(status: any, element: any) {
    let data = {
      instructor_id: element.instructor_id,
      status: status
    }
    this.httpSv.updateInstructorStatus(data).subscribe((res: any) => {
      if (res['status']) {
        this.toster.success('Instructor status updated successfully', '', { timeOut: 2000 });
        this.getInstructorsList()
      } else {
        this.toster.error('Oops! Something went wrong.', '', { timeOut: 2000 });
      }
    })
  }
  // tableDta: boolean = true;
  filterByStatus(data: any) {
    this.selectedFilter = data
    console.log(this.selectedFilter)
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  resetPassword(instructor_id: any) {
    const dialogRef = this.dialog.open(ResetPasswordComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        instructor_id
      }
    });
  }

  confirmDelete(instructor_id: any) {
    const dialogRef = this.dialog.open(DeleteDialogueComponentComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        heading: "Are you sure you want to remove the Instructor?"
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      const data = {
        instructor_id
      }
      if (res) {
        this.httpSv.deleteInstructor(data).subscribe((res: any) => {
          if (res['status']) {
            this.toster.success('Instructor deleted successfully', '', { timeOut: 2000 })
            this.getInstructorsList()
          }
          else {
            this.toster.error(res.error[0], '', { timeOut: 2000 })
          }
        }, (err) => {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
      }
    })
  }

  addInstructor(type: any,) {
    const dialogRef = this.dialog.open(AddModifyInstructorComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        type: type
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getInstructorsList();
      }
    })
  }

  modifyInstructor(type: any, instructor: any) {
    const dialogRef = this.dialog.open(AddModifyInstructorComponent, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        type: type,
        instructor
      }
    });
    dialogRef.afterClosed().subscribe((res: any) => {
      if (res) {
        this.getInstructorsList();
      }
    })
  }
  deleteInstructor(instructor_id) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: { heading: 'Are you sure to delete this instructor ?' }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.httpSv.deleteInstructors(instructor_id).subscribe((res: any) => {
          if (res['status']) {
            this.ngOnInit()
          } else {
            this.toster.error(res.message, '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
      }
    })
  }
}















@Component({
  selector: 'app-add-modify-instructor',
  templateUrl: './add-modify-instructor.component.html',
  styleUrls: ['./instructor-list.component.css']
})
export class AddModifyInstructorComponent implements OnInit {


  constructor(
    public dialogRef: MatDialogRef<AddModifyInstructorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
  ) {

  }
  ngOnInit(): void {
    this.getUniversityList()
    if (this.data.type == 'edit') {
      this.uniName = this.data.instructor.name
      this.uniCode = this.data.instructor.university_code
      this.insEmail = this.data.instructor.email
      this.slectedUniversity = this.data.instructor.university[0]
      this.slectedUniversityId = this.data.instructor.university[0].id
    }
  }
  uniName: string = '';
  src: string = ''
  fileData: any = '';
  uniCode: any;
  insEmail: string = '';
  password: string = '';
  cfPassword: string = '';
  universityList: any = []
  slectedUniversity: any
  slectedUniversityId: any
  uniCodeRegex = /^[0-9A-Z]{8}$/;
  emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  onInputChange() {
    this.uniCode = this.uniCode.toUpperCase();
  }

  onImageChange(event: any) {
    let file: File = event.target.files[0];
    this.fileData = file
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.src = e.target.result;
    };
    const fileDom = document.getElementById('file_inp') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }

  selectUniversity(university: any) {
    this.slectedUniversity = university;
  }

  close() {
    this.dialogRef.close(false)
  }

  save() {
    if (this.uniName != '') {
      if (this.uniCode != '') {
        if (!this.slectedUniversity) {
          this.toster.info('Please select the university name', '', { timeOut: 3000 })
          return
        }
        if (this.uniCodeRegex.test(this.uniCode)) {
          if (this.insEmail != '') {
            if (this.emailRegex.test(this.insEmail)) {
              if (this.data.type == 'add') {
                if (this.password != '') {
                  if (this.cfPassword != '') {
                    if (this.password == this.cfPassword) {
                      const data = {
                        name: this.uniName,
                        email: this.insEmail,
                        university_code: this.uniCode,
                        password: this.password,
                        university_id: this.slectedUniversity.id
                      }
                      this.httpSv.addNewInstructor(data).subscribe((res: any) => {
                        if (res['status']) {
                          this.toster.success(res['message'], '', { timeOut: 2000 })
                          this.dialogRef.close(true)
                        } else {
                          this.toster.error(res['message'], '', { timeOut: 2000 })
                        }
                      }, (err) => {
                        this.toster.warning('Oops! Something went wrong', '', { timeOut: 2000 })
                      })
                    } else {
                      this.toster.warning('Password and confirm password doesn\'t match', '', { timeOut: 2000 })
                    }
                  } else {
                    this.toster.warning('Please fill confirm password', '', { timeOut: 2000 })
                  }
                } else {
                  this.toster.warning('Please fill password', '', { timeOut: 2000 })
                }
              } else if (this.data.type == 'edit') {
                const data = {
                  instructor_id: this.data.instructor.instructor_id,
                  name: this.uniName,
                  email: this.insEmail,
                  university_code: this.uniCode,
                  university_id: this.slectedUniversity.id
                }
                this.httpSv.updateInstructor(data).subscribe((res: any) => {
                  if (res['status']) {
                    this.toster.success('Instructor updated successfully', '', { timeOut: 2000 })
                    this.dialogRef.close(true)
                  }
                  else {
                    this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                  }
                }, (err) => {
                  if (err.status == 404) {
                    this.toster.error(err.error['message'], '', { timeOut: 2000 })
                  } else {
                    this.toster.warning(err.error['message'], '', { timeOut: 2000 })
                  }
                })
              }
            } else {
              this.toster.warning('Please enter a valid email', '', { timeOut: 2000 })
            }
          } else {
            this.toster.warning('Please enter email', '', { timeOut: 2000 })
          }
        } else {
          this.toster.warning('Please enter alpha-numeric 8 characters instructor code', '', { timeOut: 2000 })
        }
      } else {
        this.toster.warning('Please enter instructor code', '', { timeOut: 2000 })
      }
    } else {
      this.toster.warning('Please enter instructor name', '', { timeOut: 2000 })
    }
  }
  getUniversityList() {
    this.httpSv.getUniversityList().subscribe((res: any) => {
      if (res['status']) {
        this.universityList = res['results']
      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 200 })
        this.dialogRef.close(false)
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 200 })
      this.dialogRef.close(false)
    })
  }

}