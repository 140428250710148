import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DialogueAddInstructions, DialogueAddModifyQuestion } from '../../superAdmin/game-list/game-list.component';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-ins-esg-list',
  templateUrl: './ins-esg-list.component.html',
  styleUrls: ['./ins-esg-list.component.css']
})
export class InsEsgListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private dataShare:DataShareService,
    private http:HttpService,
    private toast:ToastrService,
    private route:Router
  ) { }

  ngOnInit(): void {
  this.userId=sessionStorage.getItem('user_id')
  this.instructor_id = sessionStorage.getItem('user_id')
  if(!this.userId){
    this.route.navigate(['/login'])
    this.toast.error("Authentication Failed",'',{timeOut:2000})
  }
  else{
    if(this.dataShare.instructorGameId)
      this.getGames(this.userId)
    else{
      this.http.getInstructorGame(this.instructor_id).subscribe((res: any) => {
        if (res['status']) {
          var gameTypes = res['data'].filter((item, index, self) => index === self.findIndex((t) => JSON.stringify(t) === JSON.stringify(item)));
          this.dataShare.instructorGameId = gameTypes[0].id
          this.getGames(this.userId)
       }
      })
    }
  }
  }
  selectedFilter:any='';
  instructor_id:any = ''
  displayedColumns: string[] = ['sl_no', 'name','gcode' ,'overview', 'status', 'code'];
  dataInstructor: any[] = [];
  dataSource: any = new MatTableDataSource();
  userId:any=null;

  getGames(id:any)
  {
    let data={
      instructor_id:parseInt(id),
      game_type: this.dataShare.instructorGameId
    }
    this.http.getInstructorGames(data).subscribe((res:any)=>{
      if(res['status']){
        this.dataSource.data=res['data']
      }
      else {
        this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toast.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  setStatus(status:any,element:any){

  }
  startGame(element:any){
    const epochNow = Math.floor(new Date().getTime()/1000);
    let data={
      instructor_id:this.userId,
      game_id:element.game_id,
      start_time:epochNow.toString()
    }
    this.http.instructorStartGame(data).subscribe((res:any)=>{
      if(res){
        if(res['status']){
          this.getGames(this.userId)
        } else {
          this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      } else{
        this.toast.warning('The game has been inactivated by admin', '', { timeOut: 2500 })
        this.getGames(this.userId)
        if(this.dataSource.data.length > 0){
          setTimeout(function() {
            location.reload();
          }, 2500);
        }
      }
    }, (err: any) => {
      this.toast.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  stopGame(element:any){
    const epochNow = Math.floor(new Date().getTime()/1000);
    let data={
      session_code:element.session_code,
      end_time:epochNow.toString()
    }
    this.http.instructorStopGame(data).subscribe((res:any)=>{
      if(res['status']){
        this.getGames(this.userId)
      } else {
        this.toast.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toast.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  applyFilter(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  handleOverview(id:any,element:any){
    const dialogRef = this.dialog.open(DialogueGameOverview, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        id:id,
        gameName:element.game_name,
        gameCode:element.game_code,
        session_id: null,
        instructor_id: element.instructor_id
      }
    })
  }
}






@Component({
  selector: 'app-ins-dialoguegame-list',
  templateUrl: './dialogue.game-overview.component.html',
  styleUrls: ['./ins-esg-list.component.css']
})
export class DialogueGameOverview implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogueAddModifyQuestion>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http:HttpService,
    private toast:ToastrService,
    public dialog: MatDialog,
  ) { }

  instructions:any;
  rounds:any;
  gameName:any;
  gameCode:any;
  questions:any=[];
  questionIdArr:any=[];
  nextQuesIdArr:any=[];
  game_id:any;
  curveBallQuestion:any=[];
  dataSource: any = new MatTableDataSource();
  displayedColumns: string[] = ['round_no', 'theme_name', 'modify'];
  ngOnInit(): void {
    this.gameName=this.data.gameName,
    this.gameCode=this.data.gameCode
    this.game_id=this.data.id
    this.getGameDetails(this.game_id);
    this.getInstructions(this.game_id);
    this.getRounds(this.game_id)
    

  }
  close(){
    this.questions=[]
    this.curveBallQuestion=[]
    this.dialogRef.close(false)
  }
  getGameDetails(id:any){

  }
  getRounds(id:any){
    this.http.getGameRounds(id).subscribe((res:any)=>{
      if(res['status']){
        this.rounds=res.data;
        this.dataSource.data=res.data
      }
      else{
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  getInstructions(id:any){
    this.http.getInstructions(id).subscribe((res:any)=>{
      if(res['status']){
        this.instructions=res['data'];
      }
      else{
        this.toast.error(res.message, '', { timeOut: 2000 })
      }
    })
  }
  handleAddInstructions(){
    const dialogRef = this.dialog.open(DialogueAddInstructions, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        gameName:this.gameName,
        data:this.instructions,
        from:'ins'
      }
    }).afterClosed().subscribe((res:any)=>{
      if(res.status){
       
      }
    }) 
  }
  handleViewQuestions(id:any,name:any){
    let data = {
      overview:true, 
      session_id: this.data.session_id,
      game_id: this.data.id, 
      instructor_id: this.data.instructor_id
    }
    this.http.getRoundsQuestions(id, data).subscribe((res:any)=>{
      if(res['status']){
        let index;
        let data= res['data'];
        this.questionIdArr=[null];
        this.nextQuesIdArr=[];
        this.curveBallQuestion=[];
        data.forEach((element:any,index:any) => {
          if(element.curve_ball==1){
          index=index;
          this.curveBallQuestion.push(element);
          } else if(element.curve_ball==0){
            this.questionIdArr.push(element.id);
          }
        });
        data.forEach((element:any,index:any) => {
          if(element.curve_ball==0){
            this.nextQuesIdArr.push([]);
            element.options.forEach(element => {
              this.nextQuesIdArr[this.nextQuesIdArr.length-1].push(this.questionIdArr.indexOf(element.next_ques_id));
            });
          }
        });
        let varr:any = data.filter((element:any)=>element.curve_ball!=1)
        this.questions=varr 
        const dialogRef = this.dialog.open(DialogueRoundQuestions, {
          disableClose: true,
          hasBackdrop: true,
          data:{
            header:name,
            questions:this.questions,
            curveBallQuestion:this.curveBallQuestion,
            nextQuesIdArr:this.nextQuesIdArr
          }
        }).afterClosed().subscribe((res:any)=>{
          if(res.status){
           
          }
        }) 
      }
    })
   
  }
}







@Component({
  selector: 'app-ins-dialoguequestions-list',
  templateUrl: './dialogue.round-questions.component.html',
  styleUrls: ['./ins-esg-list.component.css']
})
export class DialogueRoundQuestions implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogueAddModifyQuestion>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http:HttpService,
    private toast:ToastrService,
    public dialog: MatDialog,
  ) { }

  ls(d:any){
    console.log(d)
  }
  isCustomGame:any = 0
  ngOnInit(): void {
    this.isCustomGame = sessionStorage.getItem('isCustomGame');
    console.log(this.isCustomGame,'aa')
  }
  close(){
    this.dialogRef.close(false)
  }
}