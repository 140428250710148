<div class="newGameContainer">
    <div class=newGameHeading>
        <div>Create New Game</div>
        <div><img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/></div>
    </div>
    <div class="newGameBody">
        <div class="reset_password_content">
            <div style="text-align: center;">Game Name</div>
            <div class="passwordToggler" >
                <input autocomplete="off"  name="gameName" id="gameName" [(ngModel)]="gameName" >
            </div>
        </div>
    </div>
    <div class="reser_btn_main_div">
        <div class="reset_pd_btn_div">
            <div (click)="close()">close</div>
            <div (click)="create()">CREATE</div>
        </div>
    </div>
</div>