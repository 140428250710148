<div class="insQuestions">
    <div class="header">
        {{data.header}}
        <img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>
    <div class="body">
        <div class="gameBody" style="flex-direction: column;">
            <div class="questionList" *ngFor="let question of data.questions;let i=index"  >
                <div class="questionListHeader" style="position: relative; color: black;font-weight: 500;" >
                    <label>Question {{i+1}}</label>
                    <!-- <img *ngIf="!isOnEdit && editableQuestion.id==question.id" src="../../../../assets/img/mdi-light_delete.svg"/> -->
                    <div style="display: flex;">
                        <!-- <img style="width: 25px;margin-right: 20px;" *ngIf="editableQuestion.id!=question.id" (click)="handleEdit(question);$event.stopPropagation()" src="../../../../assets/img/edit_icon.svg"/>
                        <div  class="saving" *ngIf="isOnEdit && editableQuestion.id==question.id">
                            <img src="../../../../assets/img/mdi-light_delete.svg" style="margin-right: 2rem;"/>
                            <img *ngIf="saved" src="../../../../assets/img/saved.svg" />
                            <img *ngIf="unsaved" src="../../../../assets/img/unsaved.svg" (click)="savingQuestion();$event.stopPropagation()"/>
                        </div>
                    <div *ngIf="isOnEdit && editableQuestion.id!=question.id">
                        <img src="../../../../assets/img/mdi-light_delete.svg"/>
                    </div> -->
                </div>

                </div>
                <div class="questionListQuestions">
                    <label>{{question.ques_text
                    }}</label>
                </div>
                <div class="questionListOptions">
                    <div class="listOptionContainer" style="margin:2rem 0">
                        <div class="listOptions" style="color: black;font-weight: 500;">
                            <p>Answers</p>
                        </div>
                        <div class="listNxtQuestion"style="color: black;font-weight: 500;">
                            <p>Next Question ID No.</p>
                        </div>
                    </div>
                    <div class="listOptionContainer" *ngFor="let option of question.options;let j= index">
                        <div class="listOptions optionOuterBox" >
                            <div class="optionTxt" (click)="ls( question.labels[j])" ><label >{{option.option_text}}</label></div>
                            <div class="optionPoints" *ngIf="isCustomGame == 0">
                                <div>
                                    <label style="font-weight: 600; color: black;">Effort Points :</label> 
                                    <label style="margin-left: 5px;" [ngClass]="option.budget?.toString().charAt(0)=='-'?'red':'green'" >{{option.budget}}</label>
                                </div>
                                <div>
                                    <label style="font-weight: 600;color: black;">ESG :</label> 
                                    <label style="margin-left: 5px;" [ngClass]="option.esg_score?.toString().charAt(0)=='-'?'red':'green'">{{option.esg_score}}</label>
                                </div>
                                <div>
                                    <label style="font-weight: 600;color: black;">Brand Value :</label> 
                                    <label style="margin-left: 5px;" [ngClass]="option.brand_value?.toString().charAt(0)=='-'?'red':'green'">{{option.brand_value}}</label>
                                </div>
                                <div>
                                    <label style="font-weight: 600;color: black;">Response Rate :</label>
                                    <label style="font-weight: 500;color: black; margin-left:0.2rem">{{option?.option_response}}%</label>
                                </div>
                            </div>
                            <div class="optionPoints" *ngIf="isCustomGame == 1">
                                <div *ngFor="let lable of question.labels[j]">
                                    <label style="font-weight: 600; color: black;">{{lable.name}} :</label> 
                                    <label style="margin-left: 5px;" [ngClass]="lable.value?.toString().charAt(0)=='-'?'red':'green'" >{{lable.value}}</label>
                                </div>
                                <div>
                                    <label style="font-weight: 600;color: black;">Response Rate :</label>
                                    <label style="font-weight: 500;color: black; margin-left:0.2rem">{{option?.option_response}}%</label>
                                </div>
                            </div>
                        </div>
                        <div class="listNxtQuestion nxtQuestionBox" style="color: #0D8BBC;">
                            <label >{{data.nextQuesIdArr[i][j] == 0 ? 'N/A' : data.nextQuesIdArr[i][j]}}</label>                       
                        </div>
                    </div>
                  
                </div>
            </div>
            <div class="curveBall" *ngIf="data.curveBallQuestion.length>0">
                <div class="curveBallHeader">
                    CURVE BALL QUESTION
                </div>
                <div class="questionList" *ngFor="let question of data.curveBallQuestion;let i=index">
                <div class="questionListHeader">
                    <label class="curveHeader">CURVE BALL QUESTION</label>
                </div>
                <div class="questionListQuestions">
                    <label >{{question.ques_text
                    }}</label>
                </div>
                <div class="questionListOptions">
                    <div class="listOptionContainer" style="margin:2rem 0">
                        <div class="listOptions">
                            <p style="font-weight: 500;color: black;">Answers</p>
                        </div>
                        <div>
                            <p style="font-weight: 500;color: black;">Response Rate</p>
                        </div>
                    </div>
                    <div class="listOptionContainer" *ngFor="let option of question.options">
                        <div class="listOptions optionOuterBox" *ngIf="option.is_active == 1">
                            <div class="optionTxt  curveBallOptionHeader">{{option.option_text}}</div>
                            <div class="optionTxt curveBallOptionBody" style="margin-top: 10px;">{{option.popup}}</div>
                            
                        </div>
                        <div>
                            <label  style="font-weight: 500;color: black;">{{option?.option_response}}%</label>
                        </div>
                    </div>
                  
                </div>
            </div>
            </div>
    </div>
    </div>
</div>