import { CanDeactivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';


export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {
  constructor(private router: Router){}
  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    console.log('can dsscd')
    // if there are no pending changes, just allow deactivation; else confirm first
    // return component.canDeactivate() ?
    //   true :
    //   confirm('WARNING: You game will be restart. Press Cancel to resume or OK to restart the game.') === true  ? console.log('asdfsadf')  : console.log('else');
    if (component.canDeactivate()) {
      console.log('aaaa')
      return true
    }
    else {
      console.log('bbbb')
      if (sessionStorage.getItem('temp') == 'true') {
        sessionStorage.setItem('temp','false')
        return false
      }
      else {
        if (confirm('WARNING: You game will be restart. Press Cancel to resume or OK to exit from the game.') === true) {
          console.log('okay')
          window.location.reload()
          return true
        }
        else {
          console.log('else')
          return false
        }
      }
    }

  }
}