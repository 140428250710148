<div class="dialogueConfigureGame">
    <div class="header">
        <div class="headerTxt">
            Configure New Game
        </div>
        <img style="cursor: pointer;" (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>
    <div class="body">
        <div class="bodyFillables">
            <div class="contents">
                <label>Select Game Code with Name<b>*</b></label>
                <mat-form-field class="selectOption">
                    <mat-select  name="gameCode"  style="padding: 17px 25px;background-color: #F3F3F3;border-radius: 6px; display: inline-block;">
                        <mat-option [value]="game" *ngFor="let game of gameList" (click)="selectGame(game)">{{game.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="contents">
                <label>Select Instructor Code <b>*</b></label>
                <mat-form-field class="selectOption">
                    <mat-select  name="uniCode" (click)="selectedGame == undefined ? checkIsGameSelected() : null"  style="padding: 17px 25px;border-radius: 6px; background-color: #F3F3F3;display: inline-block;">
                        <mat-option [value]="instructor" *ngFor="let instructor of instructorList" (click)="selectInstructor(instructor)" [disabled]="selectedGame == undefined">{{instructor.label}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="autoFillable">
            <div class="contents">
                <label>Instructor Name </label>
                <input type="text" [value]="instructorName" disabled/>
            </div>
            <div class="contents">
                <label>Instructor Email Address</label>
                <input type="text" [value]="instructorEmail" disabled/>
            </div>
            <div class="contents">
                <label>Status<b>*</b></label>
                <div class="dropdown">
                    <div class=" " id="dropdownMenuLink" class="dropdownStatus" style="display: flex;justify-content: space-between;background-color: #F3F3F3;border-radius: 6px; align-items: center;width: 80%;" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div>{{status}}</div><img style="width: 20px;" src="../../../../assets/img/chevron-down-svgrepo-com.svg"/>
                    </div>
                    <ul class="dropdown-menu" name="dropdown" aria-labelledby="dropdownMenuLink">
                        <li class="dropdown-item green" 
                          (click)="setStatus('active')" >
                            Active</li>
                        <li class="dropdown-item red" 
                           (click)="setStatus('inactive')" >
                            InActive</li>
                    </ul>
                </div>      
            </div>
            <div class="contents">
                <div class="ces_pd_inp_sub_div">
                    <label for="number">Show Introduction page<b>*</b></label>
                    <div class="imgSelection">
                        <mat-slide-toggle
                                class="example-margin"
                                [color]="color"
                                [checked]="checked"
                                [disabled]="disabled"
                                (change)="change($event)"
                                >
                            </mat-slide-toggle>
                    </div>
                </div>
            </div>
            <div class="contents">
                <div class="ces_pd_inp_sub_div">
                    <label for="number">University Logo<b>*</b></label>
                    <div class="imgSelection">
                        <img *ngIf="src.length>0" class="shownImg" [src]="src" alt="">
                        <div class="uploadImg" (click)="fileInput_image.click()">
                            <img src="../../../../assets/img/upload.svg"/>
                            Upload
                            <input #fileInput_image hidden id="file_inp" style="position: absolute;left: 0;" type="file" accept="image/png,image/jpg,image/svg" (change)="onImageChange($event)">
                        </div>
                    </div>
                </div>
            </div>
            <div class="contents">
                <label>University name</label>
                <input style="background-color: white;" type="text" [(ngModel)]="universityName" />
            </div>

            <div class="contents"  *ngIf="show">
                <label>Description<b>*</b></label>
                <textarea style="background-color: white;" type="text" [(ngModel)]="universityDesc" ></textarea>
            </div>

        </div>
    </div>
    <div class="footer">
        <div class="cancel" (click)="close()">
            Cancel
        </div>
        <div class="save" (click)="save()">
            SAVE
        </div>
    </div></div>