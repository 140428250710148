<div class="parent_comp_main_container">
    <!-- <app-epg-header></app-epg-header> -->
    <div class="comp_main_container">
        <div style="position: absolute;"><img src="../../../../assets/img/epg/logo.svg" alt="" class="epg_game_cam_logo"></div>
        <div class="game_info_main_div">
            <div class="game_info_sub_div">

                <div *ngIf="game_info_page == 1" class="page_wise_main_div">
                    <div class="page_wise_heading">GAME OVERVIEW</div>
                    <div class="page_wise_sub_heading">Welcome to the Entry Pricing Game</div>
                    <div class="page_wise_txt">
                        This is a study of marketing decisions, specifically the decision of whether to enter a new
                        market or not and of what price to choose.
                        While firms choose to enter markets and compete on many factors, price is often the most
                        important one. When price is perceived as the most important factor, a firm will typically only
                        enter when it believes it can price its product lower than the competition and still cover the
                        costs of entering the market.
                        <br><br>
                        In the game you are about to play, you are a consultant hired by UPSTART, a brand new
                        firm trying to compete against ESTD CO which already markets a product identical to UPSTART’s.
                        The fact that your products are identical, combined with the fact that the products are a
                        commodity, makes price the most important factor in UPSTART’s competition with ESTD CO.
                        <br><br>
                        ESTD CO always operates in the same geographical market. Your job is to decide, at the
                        beginning of every quarter,
                        whether or not to compete in this market. So, at the beginning of every quarter you will make a
                        decision about whether or not to enter the market and what your price should be in that market.
                        If you decide not to compete, i.e., if you decide to stay out in any quarter, your product
                        supply for this market is diverted to another market without any cost to you. If you decide to
                        enter, you must cover the fixed costs of entry. In general, you would be more capable of doing
                        this against a competitor who prices high than one who prices low. More specifically, if ESTD CO
                        prices high (say $500 as in the table below), you are best off entering with a low price (since
                        your profit, $300,000, is the highest with this move). On the other hand, if ESTD CO prices low
                        (say $400 as in the table below), you are best off staying out (you lose nothing), or even
                        entering with a high price (you lose $50,000) than entering with a low price (you lose
                        $100,000).
                        <br><br>
                        <div>
                            <div class="upstart_moves_txt">
                                UPSTART’S Moves & Profits
                            </div>
                            <div class="market_game_table_main_div">
                                <div class="market_game_table_left_div">
                                    <div>&dollar;500</div>
                                    <div>ESTD CO’S PRICE</div>
                                    <div>&dollar;400</div>
                                </div>
                                <div class="grid_css">
                                    <div class="market_game_table_main_div_entery_txt">Enter with
                                        High Price</div>
                                    <div class="market_game_table_main_div_entery_txt">Enter with
                                        Low Price</div>
                                    <div class="market_game_table_main_div_entery_txt">Stay Out</div>
                                    <div class="gamw_info_box">&dollar;200000</div>
                                    <div class="gamw_info_box">&dollar;300000</div>
                                    <div class="gamw_info_box">&dollar;0</div>
                                    <div class="gamw_info_box">-&dollar;50000</div>
                                    <div class="gamw_info_box">-&dollar;100000</div>
                                    <div class="gamw_info_box">&dollar;0</div>
                                </div>
                            </div>
                        </div>
                        <br><br>
                        At the beginning of every quarter, before you make your decision, there is information to help
                        you predict what ESTD CO’s price will be in that quarter. UPSTART&#39;s profits, therefore,
                        depend upon your ability to predict what ESTD CO’s price will be and then make the right market
                        entry and price decision for UPSTART.
                    </div>
                </div>

                <!-- Page 2 -->
                <div *ngIf="game_info_page == 2" class="page_wise_main_div">
                    <div class="page_wise_heading">GAME INSTRUCTIONS</div>
                    <div class="page_wise_sub_heading">Specific Instructions</div>
                    <div class="page_wise_txt">
                        At the beginning of every quarter you will be given general information on factors that may
                        affect ESTD CO’s price in that quarter for that market. This information will be on four factors
                        which are:
                        <br><br>
                        1) ESTD CO’s stated goals (as indicated by its announcements). Announcements will suggest one of
                        two prices: high or low.
                        <br><br>
                        2) ESTD CO’s costs (as provided by your competitive analysis department). Cost information will
                        suggest one of two
                        levels of cost: high or low.
                        <br><br>
                        3) The nature of market demand (as provided by a market analyst). Information will suggest that
                        demand has either risen or fallen.
                        <br><br>
                        4) The nature of competition (as provided by your previous move). Note: Only your most recent
                        move and not all your previous moves may affect ESTD CO’s price in any quarter. Thus, ESTD CO
                        may react to competition only to the extent to which it may react to your most recent move.
                        <br><br>
                        You are free to use this information as you wish. Remember, not all this information is
                        necessarily important. Specifically, your competitor may use none or only a subset of the
                        factors above to make its pricing decisions. For example, it may only use its costs and market
                        demand in setting its price. Also, ESTD CO may change its strategy at some point in the
                        interaction.
                        For example, it may change from basing its price on cost and demand factors to basing its price
                        on announcements and
                        the nature of competition.
                        <br><br>
                        After reading the information, you will make your decision for that quarter. You may choose one
                        of three options: stay out, enter with a low price, or enter with a high price. ESTD CO
                        simultaneously sets it own price, which can be any value between a low price of $400 or a high
                        price of $500.
                        <br><br>
                        When you have entered your choice, you will be informed of ESTD CO’s price and your profit for
                        that period. You will make similar decisions until the assignment is over. The assignment will
                        last about 20 quarters.
                        <br><br>
                        Trial periods. Before beginning the real assignment, you will play two trial rounds against an
                        imaginary firm to familiarise you with the procedure before the actual assignment begins. Note
                        that the trial periods are only for practice and need not reveal anything about
                        what strategy you should use in the actual assignment.
                        <br><br>
                        Performance. Your performance will be based on UPSTART’s cumulative profits as well as reports
                        to UPSTART on your assessment of ESTD CO’s strategy over the length of the assignment.
                    </div>
                </div>

                <!-- Page 3 -->
                <div *ngIf="game_info_page == 3" class="page_wise_main_div">
                    <div class="page_wise_heading">MY ROLE</div>
                    <div class="page_wise_sub_heading">Welcome to the EPG Marketing game</div>
                    <div class="page_wise_txt">
                        This simulation is developed by CEL in collaboration with Cambridge Judge Business School. It is
                        July 2023 and you are the CMO of Greenco Supermarket, a global retail organisation,
                        headquartered in
                        London, UK. Your goal is to This simulation is developed by CEL in collaboration with Cambridge
                        Judge Business School.
                        <br><br>
                        It is July 2023 and you are the CMO of Greenco Supermarket, a global retail organisation,
                        headquartered in London, UK. Your goal is to This simulation is developed by CEL in
                        collaboration with Cambridge Judge Business School. It is July 2023 and you are the CMO of
                        Greenco Supermarket, a global retail organisation, headquartered in London, UK.
                    </div>
                </div>
                <!-- <div style="flex: 1;">&nbsp;</div> -->
                <div class="game_info_btn_main_div">
                    <div class="game_info_btn_sub_div">
                        <div class="game_info_paginitor">
                            <div class="epg_paginitor" [ngClass]="game_info_page == 1?'epg_paginitor_avtive':''"
                                (click)="game_info_page = 1">&nbsp;</div>
                            <div class="epg_paginitor" [ngClass]="game_info_page == 2?'epg_paginitor_avtive':''"
                                (click)="game_info_page = 2">&nbsp;</div>
                            <!-- <div class="epg_paginitor" [ngClass]="game_info_page == 3?'epg_paginitor_avtive':''"
                                (click)="game_info_page = 3">&nbsp;</div> -->
                        </div>
                        <button (click)="changeGameInfo()" class="next_btn">Next</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>