import { Component, ViewChild, OnInit, Injectable, Inject, AfterViewChecked } from "@angular/core";
import { ChartComponent } from "ng-apexcharts";
import { BarChart } from "echarts/charts";
import { TooltipComponent, GridComponent, LegendComponent } from "echarts/components";
import jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from "html2pdf.js"
import { ToastrService } from 'ngx-toastr';



import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexLegend,
  ApexChart
} from "ng-apexcharts";
import { HttpService } from "src/app/services/https/http.service";
import { DataShareService } from "src/app/services/data.service/data-share.service";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogueEditTemplate } from "../superAdmin/game-report/game-report.component";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { DomSanitizer } from "@angular/platform-browser";

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

@Injectable()
@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
  @ViewChild("chart") chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public horizontalChart: Partial<ChartOptions> | any;
  readonly echartsExtentions!: any[];
  echartsOptions: object | any = {};
  pieLegend: ApexLegend = {
    show: false
  }

  ESG_career_workbook: any = 'https://goingcircular.notion.site/ESG-Career-Strategy-Workbook-3a96e4d3afc242c48ae5422eda8a2e8e';
  ESG_feedback_googleForm: any = 'https://forms.gle/wvPKkptKHJtBH9vDA';
  imageCheckCount: any = 0
  effortPoints: any;
  totalEffortPoints: any;
  curveBallPoints: any;
  studentId: any;
  univId: any;
  sessionDate: any;
  showPageMentors: any = true;
  ESGScore: any;
  brandValue: any;
  avg_effortPoints: any;
  mentorDataApi: any;
  avg_esgScore: any;
  effortStatus: any;
  otherPoints: any;
  totalPoints: any;
  avg_otherPoints: any;
  showSpinnerFlag: any = false
  highest_esgScore: any;
  avg_brandValue: any;
  brandStatus: any;
  mentorData: any = [{ 'name': '111' }, {}, {}]
  studentName: any;
  mentorImage: any[] = [{ name: '111', image: 'null', desc: '' }, { name: '111', image: 'null', desc: '' }, { name: '111', image: 'null', desc: '' }]
  gameImage: any;
  generatedData: any;
  esgGrade: any;
  esg: any;
  effort: any;
  brand: any;
  game_logo: any;
  esgGradeTxt: any;
  label: any = null
  totalLicensecolors: any = ['#7CC3B5', '#FFFFFF'];
  univName: any;
  date: any
  linkData: any;
  base64Image: any;
  imageCount: any = 0;
  gameId: any
  gameType:any
  game_start_time: any;
  game_end_time: any;
  durationMinutes: any;
  durationSeconds: any;
  player_date:any;
  customValue1: any;
  customValue2: any;
  customValue3: any;
  customValue4: any;
  max_value1: any;
  max_value2: any;
  max_value3: any;
  max_value4: any;
  avg_value1: any;
  avg_value2: any;
  avg_value3: any;
  avg_value4: any;
  value1: any;
  value2: any;
  value3: any;
  value4: any;
  text_value1: any;
  text_value2: any;
  text_value3: any;
  text_value4: any;
  customStatus1: any;
  customStatus2: any;
  customStatus3: any;
  customStatus4: any;
  custom1:any;
  custom2:any;
  custom3:any;
  custom4:any;
  yaxis!: {
    min: 0;
    max: 100;
    tickAmount: 25;
  };
  sessionId: any = ''
  constructor(
    private http: HttpClient,
    private httpSv: HttpService,
    private sanitizer: DomSanitizer,
    private toast: ToastrService,
    public dialogRef: MatDialogRef<ReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dataShare: DataShareService) {
    this.echartsExtentions = [BarChart, TooltipComponent, GridComponent, LegendComponent];
    this.horizontalChart = {
      series: [
        {
          data: [44, 55, 41, 64, 22]
        }
      ],
      chart: {
        type: "bar",
        height: 430
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: "top"
          }
        }
      },
      dataLabels: {
        enabled: true,
        offsetX: -6,
        style: {
          fontSize: "12px",
          colors: ["#fff"]
        }
      },
      stroke: {
        show: true,
        width: 1,
        colors: ["#fff"]
      },
      xaxis: {
        type: "category",
        // categories: ['Set up a refill stations so customers have to bring their own containers',
        //             'Join the plastics pact',
        //             'Stick to the current  ESG strategy roadmap versus following competitor',
        //             'Reduce the Ready Meal Stock by 10%',
        //             'Piggybacking viral Marrketing'],
        categories: ['0%', '25%', '50%', '75%', '100%'],
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          hideOverlappingLabels: false,
          trim: true,
          minHeight: 40
        }
      },
      yaxis: {
        min: 0,
        max: 200,
        tickAmount: 4,
        show: true,
        showAlways: true,
        showForNullSeries: true,
        seriesName: 'aaaa',
      }

    };

  }

  getStudentScore(data: any) {
    // this.ngOnInit()
    this.httpSv.getOneStudentAllData(data).subscribe((res: any) => {
      if (res['status']) {
        
        //common deatils between two pdf
        this.gameId = res['result'].student_details.game_id;
        this.gameType = res['result'].student_details.game_type;
        this.studentName = res['result'].student_name
        this.univName = res['result'].univ_name;
        this.date = res['result'].date;
        this.generatedData.univ_name = this.univName,
        this.generatedData.date = this.date.slice(0, 10);
        this.generatedData.id = res['result'].student_details.id
        this.sessionDate = res['result'].student_details.updated_at.split(' ')[0];

        this.game_start_time =new Date(res['result'].student_details.player_start_time);
        this.game_end_time = new Date(res['result'].student_details.player_end_time);
        if (this.game_start_time && this.game_end_time) {
          const durationInMilliseconds = this.game_end_time.getTime() - this.game_start_time.getTime();
          this.durationMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
          this.durationSeconds = Math.floor((durationInMilliseconds % (1000 * 60)) / 1000);
        }
        this.player_date = this.game_start_time.toISOString().split('T')[0];
        
        if(res.result.student_details.game_type === 1 || res.result.student_details.game_type === 2){
          this.linkData = res['result'].game_links
          this.mentorData = res['result'].game_mentor
          this.mentorDataApi = res['result'].game_mentor
          this.curveBallPoints = res['result'].curveBallPoints;

          let flag = 0
          this.mentorData.map((res: any) => {
            if (res.desc.length == 0)
              flag = 1
            if (res.path != null) {
              this.imageCount += 1
            }
          })

          if (flag)
            this.showPageMentors = false
          this.mentorImage = []
          this.mentorData.map((res: any) => {
            this.fetchImage(res.path, 'mentor', res)
          })
          if (res['result'].game_logo) {
            this.fetchImage(res['result'].game_logo, '')
            this.imageCount += 3
          }

          this.brandValue = res['result'].normalised_brand_value;
          this.ESGScore = res['result'].normalised_esg_score;
          // this.ESGScore = Math.ceil(((parseInt(this.ESGScore) - 36.2) / 64) * 100)
          if (this.ESGScore > 100) this.ESGScore = 100
                  
          // this.brandValue =  Math.ceil(((parseInt(this.brandValue) - 32.5) / 64) * 100)
          this.effortPoints = res['result'].total_spent;
          this.totalEffortPoints = 100 + this.curveBallPoints;
          this.avg_brandValue = res['result'].average_brand_value;
          this.avg_esgScore = res['result'].average_esg_score;
          this.avg_effortPoints = res['result'].average_effort_points;          
          this.esg = res['result'].esg_desc

          this.otherPoints = res['result'].otherPoints;
          // this.totalPoints = this.brandValue + this.ESGScore + this.effortPoints;
          this.avg_otherPoints = Math.ceil(this.totalPoints / 100) * 100

          this.effortStatus = this.effortPoints > this.avg_effortPoints ? 'HIGHER' :
                              this.effortPoints < this.avg_effortPoints ? 'BELOW' : 'EQUAL';  
          this.brandStatus = this.brandValue > this.avg_brandValue ? 'HIGHER' : 'LOWER';
          this.highest_esgScore = res['result'].highest_esg_score;
          let val = 100 - parseInt(this.ESGScore)
          this.brand = res['result'].brand_desc.replace('YYYY', this.brandStatus).replace('XX', this.avg_brandValue);
          this.effort = res['result'].effort_desc.replace('YYYY', this.effortStatus).replace('XX', this.avg_effortPoints);

          console.log(this.imageCount, 'aaaaaaaaaa')
          const esgPercent = this.ESGScore;
          if (esgPercent >= 0 && esgPercent <= 9) {
            this.esgGrade = 1
            this.esgGradeTxt = 'CCC'
          }
          else if (esgPercent >= 10 && esgPercent <= 20) {
            this.esgGrade = 2
            this.esgGradeTxt = 'B'
          }
          else if (esgPercent >= 21 && esgPercent <= 35) {
            this.esgGrade = 3
            this.esgGradeTxt = 'BB'
          }
          else if (esgPercent >= 36 && esgPercent <= 50) {
            this.esgGrade = 4
            this.esgGradeTxt = 'BBB'
          }
          else if (esgPercent >= 51 && esgPercent <= 64) {
            this.esgGrade = 5
            this.esgGradeTxt = 'A'
          }
          else if (esgPercent >= 65 && esgPercent <= 79) {
            this.esgGrade = 6
            this.esgGradeTxt = 'AA'
          }
          else {
            this.esgGrade = 7
            this.esgGradeTxt = 'AAA'
          }
            
        } else if(res.result.student_details.game_type > 2){

          const customVal = res.result.formula;
          if(res.result.formula.length >0){
          
            this.linkData = JSON.parse(customVal[0].custom_game_report[0].link_text);
            this.mentorData = JSON.parse(customVal[0].custom_game_report[0].sugg_name);
            this.mentorDataApi = JSON.parse(customVal[0].custom_game_report[0].sugg_name);
            let flag = 0

            this.mentorData.map((res: any) => {
              if (res.desc.length == 0)
                flag = 1
              if (res.path != null) {
                this.imageCount += 1
              }
            })

            if (flag)
            this.showPageMentors = false

            this.mentorImage = []
            this.mentorData.map((res: any) => {
              this.fetchImage(res.path, 'mentor', res)
            })
            if (res['result'].game_logo) {
              this.fetchImage(res['result'].game_logo, '')
              this.imageCount += 3
            }

            customVal.forEach((show, index) =>{
              if(index === 0){
                this.customValue1 = show.name;
                this.max_value1 = show.max;
                this.value1 = show.value;
                this.avg_value1 = show.averageValue;
                this.text_value1 =show.custom_game_report[0].text
              } else if(index === 1){
                this.customValue2 = show.name;
                this.max_value2 = show.max;
                this.value2 = show.value;
                this.avg_value2 = show.averageValue;
                this.text_value2 =show.custom_game_report[0].text
              } else if(index === 2){
                this.customValue3 = show.name;
                this.max_value3 = show.max;
                this.value3 = show.value;
                this.avg_value3 = show.averageValue;
                this.text_value3 =show.custom_game_report[0].text
              } else if(index === 3 ){
                this.customValue4 = show.name;
                this.max_value4 = show.max;
                this.value4 = show.value;
                this.avg_value4 = show.averageValue;
                this.text_value4 = show.custom_game_report[0].text
              }
            });

            this.customStatus1 = this.max_value1 > this.avg_value1 ? 'HIGHER' : 'LOWER';
            this.customStatus2 = this.max_value2 > this.avg_value2 ? 'HIGHER' : 'LOWER';
            this.customStatus3 = this.max_value3 > this.avg_value3 ? 'HIGHER' : 'LOWER';
            if(customVal[0])
            this.custom1 = customVal[0].custom_game_report[0].text.replace('YYYY', this.customStatus1).replace('XX', this.avg_value1);
            if(customVal[1])
            this.custom2 = customVal[1].custom_game_report[0].text.replace('YYYY', this.customStatus1).replace('XX', this.avg_value1);
            if(customVal[2])
            this.custom3 = customVal[2].custom_game_report[0].text.replace('YYYY', this.customStatus1).replace('XX', this.avg_value1);
            if(this.customValue4 !== null && this.customValue4 !== undefined){
              this.customStatus4 = this.max_value4 > this.avg_value4 ? 'HIGHER' : 'LOWER';
              this.custom4 = customVal[3].custom_game_report[0].text.replace('YYYY', this.customStatus1).replace('XX', this.avg_value1);
            }

          }
        }

        if (this.data.element.sendEmail) {
          let sendData = setInterval(() => {
            console.log("checking send email", this.imageCheckCount,this.imageCount)
            if (this.imageCheckCount >= this.imageCount || this.imageCheckCount >= this.imageCount - 1) {
              clearInterval(sendData)
              this.toast.info('Email trigger in process', '', { timeOut: 5000 })
              this.convetToPDF(this.generatedData)
            }
          }, 100);
          // setTimeout(() => {
          //   clearInterval(sendData); 
          //   console.log("Interval cleared due to timeout");
          //   this.convetToPDF(this.generatedData)
          // }, 10000);
        }
        if (this.data.element.sendAllEmail) {
          let sendData = setInterval(() => {
            console.log("checking send all", this.imageCheckCount)
            if (this.imageCheckCount >= this.imageCount || this.imageCheckCount >= this.imageCount - 1 ) {
              clearInterval(sendData)
              //this.toast.info('Email triggered', '', { timeOut: 5000 })
              this.convetToPDF(this.generatedData, false, true)
            }
          }, 100);
          // setTimeout(() => {
          //   clearInterval(sendData); 
          //   console.log("Interval cleared due to timeout");
          //   this.convetToPDF(this.generatedData, false, true)
          // }, 10000);
        }

        if (this.data.isDownload) {
          let sendData = setInterval(() => {
            console.log("checking doqnload", this.imageCheckCount,this.imageCount)
            if (this.imageCheckCount >= this.imageCount || this.imageCheckCount >= this.imageCount - 1) {
              clearInterval(sendData)
              this.convetToPDF(this.generatedData, true)
            }
          }, 100);
          // setTimeout(() => {
          //   clearInterval(sendData); 
          //   console.log("Interval cleared due to timeout");
          //   this.convetToPDF(this.generatedData, true)
          // }, 10000);
        }
        if (this.data.element.isDownloadAll) {
          let sendData = setInterval(() => {
            console.log("checking download all", this.imageCheckCount,this.imageCount)
            if (this.imageCheckCount >= this.imageCount || this.imageCheckCount >= this.imageCount - 1) {
              clearInterval(sendData)
              this.convetToPDF(this.generatedData, false, false, true)
            }
          }, 100);
          // setTimeout(() => {
          //   clearInterval(sendData); 
          //   console.log("Interval cleared due to timeout");
          //   this.convetToPDF(this.generatedData, false, false, true)
          // }, 10000);
        }
        else {
          // alert(this.data.isDownloadAll)
        }
      
      }
      else {
        this.toast.error("Oops !!, There seems to be a problem. Please try again")
      }
    }, (err: any) => {
      if (this.data.element.isDownloadAll || this.data.element.sendAllEmail) {
        // alert(1)
        this.dialogRef.close(false)
        setTimeout(() => {
          document.body.click()
        }, 500)
      }
    })
  }
  splitOriginalData(data: any) {
    if (data)
      return data.split(' ')
    return []
  }
  title = 'html-to-pdf-angular-application';
  public convetToPDF(value: any, isDownload = false, isSendAll = false, isDownloadAll = false) {
    var data2: any = document.getElementById('wrapper')?.innerHTML;
    this.showSpinnerFlag = true
    var opt = {
      margin: 0,
      filename: 'myfile.pdf',
      image: { type: 'jpeg', quality: 1 },
      html2canvas: { scale: 3, scrollY: 0 },
      // jsPDF:        { unit: 'in', format: 'letter', orientation: 'portrait' }
    };
    this.showSpinnerFlag = false
    console.log("CONVET TO PDF ",isDownload,'a',isSendAll,'aa',isDownloadAll)
    // New Promise-based usage:
    let doc = html2pdf().set(opt).from(data2).toPdf()
      .get('pdf').then((pdf) => {
        var pageCount = pdf.internal.getNumberOfPages();
        if (pageCount > 3)
          pdf.deletePage(4)
        if (!isDownload && !isDownloadAll) {
          setTimeout(() => {
            var blob = pdf.output("blob");
            const data = new FormData()
            data.append('email', value.email)
            data.append('name', value.name)
            data.append('univ_name', value.univ_name)
            data.append('date', value.date)
            data.append('id', value.id)
            data.append('file', blob)
            this.httpSv.sendEmail(data).subscribe((res: any) => {
              if (res['status']) {
                if (!isSendAll) {
                  this.toast.success("Email sent successfully", '', { timeOut: 2000 })
                } else {
                  this.close();
                }
                this.dialogRef.close(true)
              }
              else {
                this.toast.error("Something went wrong", '', { timeOut: 2000 })
                this.dialogRef.close(false)
                setTimeout(() => {
                  document.body.click()
                }, 500)
              }
            }, (err: any) => {
              this.dialogRef.close(false)
              setTimeout(() => {
                document.body.click()
              }, 500)
            })
          }, 0);
        }
        else if (!isDownload && isDownloadAll) {
          let pdfBlob = pdf.output("blob");
          this.dialogRef.close(pdfBlob)
          setTimeout(() => {
            document.body.click()
          }, 500)

        }
        else {
          this.showSpinnerFlag = false
          pdf.save(value.name + '.pdf')
          this.toast.success("Downloaded successfully", '', { timeOut: 2000 })
          this.dialogRef.close(true)
        }
      })
  }
  close() {
    this.dialogRef.close(true)
    setTimeout(() => {
      document.body.click()
    }, 500)
  }
  ngOnInit(): void {
    if (this.data.isDownload) {
      let wrapper = document.getElementById('wrapper');
      wrapper?.classList.add('hideWrapper')
    }
    if (this.data.report) {
      this.studentId = this.dataShare.studentId;
      this.univId = this.dataShare.univId;
      this.gameId = this.dataShare.gameID;
      if(this.gameId === 1 || this.gameId === 2){
        this.esg = this.data.report.esg_score_desc
        this.effort = this.data.report.effort_point_desc
        this.brand = this.data.report.brand_value_desc
        this.linkData = JSON.parse(this.data.report.link_text.replaceAll(/&quot;/ig, '"'))
        this.mentorData = JSON.parse(this.data.report.sugg_name)
        this.mentorImage = []
        this.mentorData.map((res: any) => {
          this.fetchImage(res.path, 'mentor')
        })
      } else if(this.gameId > 2){
        const customVal = this.data.report;
        customVal.forEach((show, index) =>{
          if(index === 0){
            this.customValue1 = show.name;
            this.max_value1 = show.max;
            this.value1 = show.value;
            this.avg_value1 = show.averageValue;
            this.text_value1 =show.text
          } else if(index === 1){
            this.customValue2 = show.name;
            this.max_value2 = show.max;
            this.value2 = show.value;
            this.avg_value2 = show.averageValue;
            this.text_value2 =show.text
          } else if(index === 2){
            this.customValue3 = show.name;
            this.max_value3 = show.max;
            this.value3 = show.value;
            this.avg_value3 = show.averageValue;
            this.text_value3 =show.text
          } else if(index === 3 ){
            this.customValue4 = show.name;
            this.max_value4 = show.max;
            this.value4 = show.value;
            this.avg_value4 = show.averageValue;
            this.text_value4 = show.text
          }
        });
        this.customValue1 = this.data.report[0].name;
        this.effort = this.data.report.effort_point_desc
        this.brand = this.data.report.brand_value_desc
        this.linkData = JSON.parse(this.data.report[0].link_text);
        this.mentorData = JSON.parse(this.data.report[0].sugg_name);
        this.mentorImage = []
        this.mentorData.map((res: any) => {
          this.fetchImage(res.path, 'mentor')
        })
      }
    } else {
      this.studentId = this.data.element.id;
      this.univId = this.data.element.university_code;
      this.sessionId = this.data.element.session_id
      this.generatedData = {
        player_id: this.studentId,
        session_id: this.sessionId,
        university_code: this.univId,
        email: this.data.element.email,
        name: this.data.element.name,
        univ_name: '',
        date: '',
        id: 0
      }
      this.getStudentScore(this.generatedData)
    }
  }
  fetchImage(url: any, from: any, data: any = null): void {
    const headers = new HttpHeaders()
      .set('Accept', 'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7')
      .set('Access-Control-Allow-Origin', '*')
    this.http.get(url, { responseType: 'blob', withCredentials: true, headers })
      .subscribe(
        (blob: Blob) => {
          this.convertToBase64(blob, from, data);
        },
        error => {
          console.error('Error fetching image:', error);
        }
      );
  }
  convertToBase64(blob: Blob, from: any, data: any = null): void {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64data = reader.result as string;
      if (from == 'mentor') {
        this.mentorImage.push(
          {
            image: this.sanitizer.bypassSecurityTrustResourceUrl(base64data),
            name: data.name,
            desc: data.desc
          }
        )
        if (this.mentorImage.length == this.mentorDataApi.length) {
          let results: any = []
          this.mentorDataApi.forEach((key) => {
            var found = false;
            this.mentorImage = this.mentorImage.filter((item: any) => {
              if (!found && item.name == key.name) {
                // item.image = key.image
                results.push(item);
                found = true;
                return false;
              } else
                return true;
            })
          })
          console.log(results)
          this.mentorImage = [...results]
        }
      }
      else
        this.game_logo = this.sanitizer.bypassSecurityTrustResourceUrl(base64data)

    };
    reader.readAsDataURL(blob);
  }
  imageCheck() {
    this.imageCheckCount += 1
  }
}

