<div class="dialogueAddInstructions">
    <div class="header">
        <p>Add Instructions</p>
        <div class="headerRight">
            <div class="addPages" *ngIf="data.from!='ins'" (click)="addPage()">
                <img src="../../../../assets/img/addBlue.svg"/>
                ADD PAGES
            </div>
            <img (click)="close()" class="close" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
        </div>
    </div>
    <div>
        <span class="note" style="margin: 2px 2px 2px 10px;">Note :- Use double asterisks **<span style="font-weight: bold;">to make text bold</span>** and double Enter key to start text with new paragraph.</span>
    </div>
    <div class="body"  #scrollContainer>
        <div class="bodyContents">
            <div class="firstContent">
                <label>ESG Game Name <b *ngIf="data.from!='ins'">*</b></label>
                <input type="text" value={{data.gameName}} disabled/>
            </div>

            <div class="content" *ngFor="let i of pages;let val=index">
                <label>Page {{val+1 }} :<b *ngIf="data.from!='ins'"> *</b></label>
                <div class="contentRight">
                    <div class="contentRightHeader">
                        <div class="contentHeader">
                            <label>Header :</label>
                            <input type="text" [disabled]="data.from=='ins'?true:false" (keyup)="handleDataEnter($event,'heading_text',val)" [(ngModel)]='pages[val].heading_text' />
                        </div>
                        <div class="contentHeader">
                            <label>Title :</label>
                            <input type="text" [disabled]="data.from=='ins'?true:false" (keyup)="handleDataEnter($event,'title',val)" [(ngModel)]='pages[val].title'/>
                        </div>
                    </div>
                    <div class="contentRightBody">
                        <textarea [disabled]="data.from=='ins'?true:false" (keyup)="handleDataEnter($event,'desc_text',val)" [(ngModel)]='pages[val].desc_text'></textarea>
                    </div>
                </div>
                <div>
                    <img (click)="deletePage(val)" *ngIf="data.from!='ins'" style="padding: 0 5px;cursor: pointer;" src="../../../../assets/img/remove_icon_bold.svg"/>
                </div>
            </div>
        </div>
    </div>
    <div class="contentFooter" *ngIf="data.from!='ins'">
            <div class="gameCancel" style="color:#D43738;margin-right: 3rem;" (click)="close()">CANCEL</div>
            <div class="gameSave" style="margin-right: 2rem;" (click)="save()">SAVE</div>
    </div>
</div>