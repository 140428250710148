<div class="saDashboardContainer">
    <div class="sideBar">
            <app-side-bar (messageEmitter)="changeTab($event)" [addGame]="addGame.asObservable()" (createNewGame)="createNewGame()"></app-side-bar>
    </div>
    <div class="body">
        <app-university-list *ngIf="tabSelected=='university_list'"></app-university-list>
        <app-instructor-list *ngIf="tabSelected=='instructor'"></app-instructor-list>
        <app-game-list [isNewGame]="isNewGame" [gameName]="tabSelected" *ngIf="tabSelected=='esg'"></app-game-list>
        <app-game-list [isNewGame]="isNewGame" [gameName]="tabSelected" *ngIf="isNewGame"></app-game-list>
        <app-epg-gamelist *ngIf="tabSelected=='epg'"></app-epg-gamelist>
        <app-game-configure *ngIf="tabSelected=='gc'"></app-game-configure>
        <app-game-report *ngIf="tabSelected=='gr'"></app-game-report>
        <app-profile *ngIf="tabSelected=='profile'"></app-profile>
    </div>
</div>
