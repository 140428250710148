<div class="addInstructorContainer">
    <div class="containerHeader">
        <div>
            <span *ngIf="data.type == 'add'">Add University</span>
            <span *ngIf="data.type == 'edit'">Update University</span>
        </div>
        <div>
            <img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
        </div>
    </div>
    <div class="containerBody">
        <div class="fieldValues">
            <div class="ces_pd_inp_sub_div">
                <label for="uniName">University Name<b>*</b></label>
                <input type="text" name="uniName" [(ngModel)]="uniName" required="true">
            </div>
        </div>
    </div>
    <div class="footer">
        <div class="cancel" (click)="close()">
            Cancel
        </div>
        <div class="save" (click)="save()">
            SAVE
        </div>
    </div>
</div>