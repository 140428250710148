<div class="dialogueAddRound">
    <div class="header">
        New Game
        <img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>
        <div class="body">
            <div class="content">
                <label>Game Name <b>*</b></label>
                <input id="gameName" type="text" [(ngModel)]="gameName">
            </div>
            <div class="content">
                <label>Game Code <b>*</b></label>
                <input id="gameCode" type="text" [(ngModel)]="gameCode" (input)="onInputChange()" maxlength="8">
            </div>
        </div>

    <div class="contentFooter">
        <div class="gameCancel" style="color:#D43738;margin-right: 3rem;" (click)="close()">CANCEL</div>
        <div class="gameSave" style="margin-right: 2rem;" (click)="save()">SAVE</div>
    </div>
</div>