<div class="instructorConatainer">
    <div class="header">
        <div class="title">
            <p>GAME CONFIGURE</p>
        </div>
        <div class="addInstructor">
            <img (click)="configureGame()" style="margin-top: 20px;" src="../../../../assets/img/gc.svg"/>
        </div>
    </div>
    <div class="body">
        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="text" class="search_icon_inp" placeholder="Search"
                        (keyup)="applyFilter($event)" autocomplete="off">
                </div>
            </div>
    
            <div class="dropdown">
                <button class="btn filter_btn" id="dropdownMenuLink" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../../../../assets/img/filter_icon.svg" alt="">Filters
                </button>
                <ul class="dropdown-menu" name="dropdown" aria-labelledby="dropdownMenuLink">
                    <li class="dropdown-item" 
                         (click)="filterByStatus('all')">
                        All</li>
                    <li class="dropdown-item" 
                        (click)="filterByStatus('1')">
                        ESG</li>
                    <li class="dropdown-item" 
                        (click)="filterByStatus('2')">
                        EPG</li>
                </ul>
            </div>  
            <div *ngIf="selectedFilter!='all' && selectedFilter.length>0" class="selectedFilter">
                {{selectedFilter=='all'?'':selectedFilter}}<img (click)="filterByStatus('all')" src="../../../../assets//img/carbon_close-outline.svg"/>
            </div>            
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">
    
                <!-- Sl NO -->
                <ng-container matColumnDef="sl_no">
                    <th class="" mat-header-cell *matHeaderCellDef> SN. </th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>
    
                <!-- Retail User Name  -->
                <ng-container matColumnDef="game_type">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Game Type</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.name}}
                    </td>
                </ng-container>
    
                <!-- Company name -->
                <ng-container matColumnDef="game_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Game Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> 
                        {{element.game_name == null ? 'N/A' : element.game_name}}
                    </td>
                </ng-container>
    
                <!-- City name -->
                <ng-container matColumnDef="game_code">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Game Code</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.game_code == null ? 'N/A' : element.game_code}}
                    </td>
                </ng-container>
    
                <!-- Status -->
                <ng-container matColumnDef="instructor_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Instructor Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">                        
                        {{element.instructor_name == null ? 'N/A' : element.instructor_name}}
                    </td>
                </ng-container>
    
                <!-- Reset Password -->
                <ng-container matColumnDef="university_code">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Instructor Code</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.university_code == null ? 'N/A' : element.university_code}}
                    </td>
                </ng-container>

                <!-- Reset Password -->
                <ng-container matColumnDef="email">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>Instructor Email Address</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.email == null ? 'N/A' : element.email}}
                    </td>
                </ng-container>
                <!-- Reset Password -->
                <ng-container matColumnDef="status">
                    <th class="common_table_data " style="z-index: 10 !important;" mat-header-cell *matHeaderCellDef>Status</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div class="dropdown">
                            <div class=" " id="dropdownMenuLink" style="display: flex;justify-content: space-between;align-items: center;width: 80%;" 
                                [ngClass]="element.game_status=='inactive' ? 'red' : element.status=='active' ? 'green' : 'red'" type="button" data-bs-toggle="dropdown" aria-expanded="false"
                            >
                                {{element.game_status == 'inactive' ? (element.game_status | uppercase) : (element.status | uppercase)}}<img style="width: 20px;" src="../../../../assets/img/chevron-down-svgrepo-com.svg"/>
                            </div>
                            <ul class="dropdown-menu" name="dropdown" aria-labelledby="dropdownMenuLink">
                                <li class="dropdown-item green" 
                                    (click)="element.game_status == 'inactive' ? gameInactiveToast(element) : setStatus('active',element)">
                                    Active</li>
                                <li class="dropdown-item red" 
                                    (click)="element.game_status == 'inactive' ? gameInactiveToast(element) : setStatus('inactive',element)">
                                    InActive</li>
                            </ul>
                        </div>      
                    </td>
                </ng-container>
                <!-- Modify -->
                <ng-container matColumnDef="remove">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef> Action</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex;">
                            <img src="../../../../assets/img/mdi-light_delete.svg" (click)="confirmDelete(element)" alt="" style="cursor: pointer;">
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
</div>