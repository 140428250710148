<!-- <app-header (messageEmitter)="opendrawer($event)"></app-header> -->

<div class="about-div" (click)="opendrawer(false)">
    <div style="position:relative;">
        <div class="left_leaf_div"><img src="../../../assets/img/left-leaf.svg" alt="" srcset=""></div>
        <div class="right_top_leaf_div"><img src="../../../assets/img/right-up-leaf.svg" alt="" srcset=""></div>
        <div class="right_bottom_leaf_div"><img src="../../../assets/img/right-down-leaf.svg" alt="" srcset=""></div>
        <div class="content_text_img_div">
            <img src="../../../assets/img/header.svg" alt="" srcset="" id="img_header">
            <img class="marketImg" src="../../../assets/img/greenco_supartmart_logo.svg" *ngIf="pageNo==2">
        </div>
        <div class="content_text_main_div" id="content_text_main_div">

            <div class="about-content" *ngIf="pageNo==1">
                <h2>ESG Marketing Game</h2>
                <h3>How well can you integrate strategic ESG values into your organisations’ marketing operations?</h3>
                <div class="replace-p" #scrollContainer1 id="scrollContainer1" (scroll)="onScroll('1')">
                    <p>It's a warm September 2023, Climate Change is on everyone's minds as the UK recovers from the
                        hottest
                        summer in the past 30 years.
                    </p>
                    <p>
                        You have been brought on board to understand and market to the ever-changing needs of the
                        customer
                        and the business. You commission a consumer study to find that sustainability is still top
                        priority
                        for UK consumers despite cost-of-living crisis. 65% of UK consumers think about the
                        environmental
                        impact of the items they buy, and 63% said they would only shop with a retailer offering
                        sustainable
                        delivery options. In addition, 91% agree that sustainable packaging, doing social good, and a
                        brand’s ethical reputation are all important when deciding who to spend with.
                    </p>
                    <p>
                        You are tasked with stabilising revenue, improving Greenco's brand equity while ensuring
                        sustainability remains top priority. Are you ready for the challenge?
                    </p>
                    <div *ngIf="!showScrollToTopButton && scrollContainer1Flag" (click)="scrollDown('1')" class="scroll_down_div"
                    data-toggle="tooltip" data-placement="top" title="Scroll down">
                    <img src="../../../assets/img/chevron-down-svgrepo-com.svg" alt=""
                        style="width: 30px;cursor: pointer;">
                </div>
                <div *ngIf="showScrollToTopButton && scrollContainer1Flag" (click)="scrollTop('1')" class="scroll_down_div"
                    data-toggle="tooltip" data-placement="top" title="Scroll to top">
                    <img src="../../../assets/img/chevron-up-svgrepo-com.svg" alt=""
                        style="width: 30px;cursor: pointer;">
                </div>
                </div>
                <div class="right_arrow_main_div">
                    <img src="../../../assets/img/right-arrow.svg" alt="" srcset="" (click)="rightArrow()" class="rightArrow">
                </div>

            </div>
            <div class="about-content" *ngIf="pageNo==2">

                <h2>About the Company</h2>
                <h3>What you need to know</h3>
                <div class="replace-p" id="scrollContainer2" (scroll)="onScroll('2')">
                    <div> &quot;Greenco Supermarket&quot; is retail company that offers a wide range of products
                        including
                        fresh produce, groceries, health and wellness items, household supplies, and personal care
                        products.
                        Greenco's high-quality products and trusted brand are core to what makes the company among the
                        Top 3
                        retailers in the country as customers trust them to provide value.
                    </div>
                    <p>
                        Greenco Retail is committed to sustainability and minimizing thier environmental impact. The
                        company
                        has announced their goal to become a net zero business across the entire value chain by 2040.
                        Achieving net zero would mean fundamentally changing how they make, move and sell their
                        products,
                        and the new leadership team has been brought on to give this area a a sharper focus by embedding
                        sustainability across key workstreams.
                    </p>
                    <p>
                        You have been informed of 5 key themes across which business changes are being implemented:
                    </p>
                    <p><b>Packaging Reduction for Pre-Packaged Items: </b> This will include implementing a
                        "packaging-light" approach, where we look for ways to reduce the amount of packaging used while
                        still protecting the integrity of the product. </p>
                    <p><b>Eliminating Single-Use Plastic: </b> We will work to reduce the amount of packaging used for
                        our
                        products, particularly single-use plastic packaging.</p>
                    <p><b>Promoting A Plant-Based Lifestyle: </b> In addition to reducing the carbon footprint of meat
                        and
                        dairy, driving growth of plant-based products and investing in innovation to support new product
                        development with meat and dairy alternatives.
                    </p>
                    <p><b>Food Wastage Reduction:</b> We will establish a food waste reduction program. This program
                        should
                        involve all departments within the organization and should aim to minimize food wastage
                        throughout
                        the supply chain. </p>
                    <p><b>Carbon Emission Reduction: </b>Reducing carbon emissions across products and value chains.
                    </p>
                    <div *ngIf="!showScrollToTopButton && scrollContainer2Flag" (click)="scrollDown('2')" class="scroll_down_div"
                        data-toggle="tooltip" data-placement="top" title="Scroll down">
                        <img src="../../../assets/img/chevron-down-svgrepo-com.svg" alt=""
                            style="width: 30px;cursor: pointer;">
                    </div>
                    <div *ngIf="showScrollToTopButton && scrollContainer2Flag" (click)="scrollTop('2')" class="scroll_down_div"
                        data-toggle="tooltip" data-placement="top" title="Scroll to top">
                        <img src="../../../assets/img/chevron-up-svgrepo-com.svg" alt=""
                            style="width: 30px;cursor: pointer;">
                    </div>
                </div>
                <div class="right_arrow_main_div">
                    <img src="../../../assets/img/right-arrow.svg" alt="" srcset="" (click)="rightArrow()" class="rightArrow">
                </div>
                <div class="left_arrow_main_div">
                    <img src="../../../assets/img/left-arrow.svg" alt="" srcset="" (click)="leftArrow()" class="leftArrow">
                </div>
            </div>
            <div class="about-content" *ngIf="pageNo==3">
                <h2>How to play</h2>
                <h3>Objective: Choose the most appropriate decision from the given options to maximise Greenco's Brand
                    Value
                    and ESG Score without running out of Effort Points. </h3>
                <div class="replace-p" id="scrollContainer3" (scroll)="onScroll('3')" #scrollContainer3>
                    <p><b>ESG Score: </b> An ESG score assigns a quantitative metric to the environmental, social and
                        governance (ESG) efforts undertaken by an organization. Measured on a scale of 1 to 100, your
                        ESG
                        score will determine your ESG rating at the end of the game. You will be assigned a starting
                        score
                        of 50.</p>
                    <p><b>Brand Value: </b> Calculated as a combination of awareness, sentiment and loyalty, Brand Value
                        is
                        also measured on a scale of 1 to 100. Pro Tip- Don't let your brand value drop too low or it may
                        impact revenue. You will be assigned a starting score of 50.</p>
                    <p><b>Effort Points: </b>Representative of a combination of time, effort and resources, effort
                        points
                        are your currency as you make decisions through the game.You will be assigned 150 effort points
                        at
                        the start of the game. </p>

                    <p>Track your progress as you make decisions for each of the 5 key sustainability themes using the
                        meters at the top of the screen. Remember, once a decision is made, you can't change the
                        outcome- so
                        choose wisely. And watch out for any curve balls that may come your way through the course of
                        the
                        game- unfortunately, much like in the real world, not everything is in your control!</p>
                    <div *ngIf="!showScrollToTopButton && scrollContainer3Flag" (click)="scrollDown('3')" class="scroll_down_div"
                        data-toggle="tooltip" data-placement="top" title="Scroll down">
                        <img src="../../../assets/img/chevron-down-svgrepo-com.svg" alt=""
                            style="width: 30px;cursor: pointer;">
                    </div>
                    <div *ngIf="showScrollToTopButton && scrollContainer3Flag" (click)="scrollTop('3')" class="scroll_down_div"
                        data-toggle="tooltip" data-placement="top" title="Scroll to top">
                        <img src="../../../assets/img/chevron-up-svgrepo-com.svg" alt=""
                            style="width: 30px;cursor: pointer;">
                    </div>
                </div>
                <div class="left_arrow_main_div">
                    <img src="../../../assets/img/left-arrow.svg" alt="" srcset="" (click)="leftArrow()" class="leftArrow">
                </div>
            </div>
            <div class="paginator">
                <div class="paginator-one">
                    <img *ngIf="pageNo!=1" src="../../../assets/img/1_non-selected.svg">
                    <img *ngIf="pageNo==1" src="../../../assets/img/1-selected.svg">
                </div>
                <div class="paginator-one">
                    <img *ngIf="pageNo!=2" src="../../../assets/img/2_non-selected.svg">
                    <img *ngIf="pageNo==2" src="../../../assets/img/2-selected.svg">
                </div>
                <div class="paginator-one">
                    <img *ngIf="pageNo==3" src="../../../assets/img/3-selected.svg">
                    <img *ngIf="pageNo!=3" src="../../../assets/img/3_non-selected.svg">
                </div>
            </div>
            <div class="playBtn" (click)="startRound()" *ngIf="pageNo == 3"><img src="../../../assets/img/play-btn.svg">
            </div>
        </div>
    </div>
</div>