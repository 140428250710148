<div class="dialogueAddInstructions tree">
    <!-- <div class="header">
        </div> -->
    <div class="body">
                <ngx-graph
  class="chart-container"
  [links]="links"
  [nodes]="nodes"
 
  layout="dagreCluster"
>
<ng-template #nodeTemplate let-node>
    <svg:g
 
      class="node"
      ngx-tooltip
  
      [matTooltip]="node.label"
    >
      <svg:rect
        [attr.width]="node.dimension.width"
        [attr.height]="node.dimension.height"
        [attr.fill]="node.data.color"
      />
      <svg:text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2">
        {{ getIdValue(node.id)}}
      </svg:text>
    </svg:g>
  </ng-template>
</ngx-graph>
    </div>
    </div>