<div >
    <app-header [activeOverview]="gameOverViewFlag" (messageEmitter)="switchOverViewFlag($event)"
        (flagEmitter)="opendrawer($event)" [programStaus]="programStatus"
        (startStopFlag)="refresh($event)"></app-header>
    <div  *ngIf="!gameInfoFlag">
        <div class="main-cointainer" *ngIf="gameOverViewFlag">
            <div class="side-nav-bar">
                <div class="side-bar-main-div">
                    <div *ngFor="let item of queAllData | keyvalue">
                        <div *ngIf="sideNavCondition(item.key)">
                            <div (click)="changeRoundHandeler(item.key)">{{getTheameName(item.key)}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-main-div">
                <div class="heading" style="    display: flex;justify-content: space-between;">
                    Game Overview
                    <a href="https://miro.com/app/board/uXjVMGepKWk=/?share_link_id=96483710622" target="_blank"
                        class="btn btn-primary" role="button" data-bs-toggle="button">Branched
                        questions</a>
                </div>
                <div class="sub-heading">
                    <div>
                        Theme: {{theameName}}
                    </div>
                    <div>
                        Round: {{selectedRound}}
                    </div>
                </div>
                <div>
                    <div class="que-main-div" *ngFor="let item of queAllData[selectedRound];let i = index">
                        <div class="queNo">
                            Question {{i+1}}
                        </div>
                        <div>
                            {{item.question_text}}
                        </div>
                        <div class="option-main-div">
                            <div class="option-heading">
                                <div>
                                    Answers
                                </div>
                                <div>
                                    Response rate
                                </div>
                            </div>
                            <div>
                                <div class="option-content" *ngFor="let option of item.options">
                                    <div class="option-box">
                                        <div>
                                            {{option.option_text}}
                                        </div>
                                        <div class="option-imapact">
                                            <div *ngIf="item.options.length > 2">
                                                Effort Points
                                                <span *ngIf="impactHandel('cash',option.budget) == '+'"
                                                    style="margin-left: 10px;">
                                                    <img src="../../../assets/img/plus-large-svgrepo-com.svg" alt=""
                                                        class="impact-img" style="width: 13px;">
                                                    <span
                                                        style="font-style: initial; color: #035802;">{{option.budget}}</span>
                                                </span>
                                                <span *ngIf="impactHandel('cash',option.budget) == '-'"
                                                    style="margin-left: 10px;">
                                                    <img src="../../../assets/img/minus-svgrepo-com.svg" alt=""
                                                        class="impact-img" style="width: 13px;">
                                                    <span
                                                        style="font-style: initial; color: #ff0000;">{{option.budget}}</span>
                                                </span>
                                            </div>
                                            <div *ngIf="item.options.length <= 2">
                                                Effort Points
                                                <span style="margin-left: 10px;">
                                                    <img src="../../../assets/img/plus-large-svgrepo-com.svg" alt=""
                                                        class="impact-img" style="width: 13px;">
                                                    <span
                                                        style="font-style: initial; color: #035802;">{{option.budget}}</span>
                                                </span>
                                            </div>
                                            <div>
                                                ESG
                                                <span *ngIf="impactHandel('esg',option.esg_score) == '+'"
                                                    style="margin-left: 10px;">
                                                    <img src="../../../assets/img/plus-large-svgrepo-com.svg" alt=""
                                                        class="impact-img" style="width: 13px;">
                                                    <span
                                                        style="font-style: initial; color: #035802;">{{option.esg_score
                                                        == null ? '0' : option.esg_score.toString().replace('+','')}}</span>
                                                </span>
                                                <span *ngIf="impactHandel('esg',option.esg_score) == '-'"
                                                    style="margin-left: 10px;">
                                                    <img src="../../../assets/img/minus-svgrepo-com.svg" alt=""
                                                        class="impact-img" style="width: 13px;">
                                                    <span
                                                        style="font-style: initial; color: #ff0000;">{{option.esg_score.toString().replace('-','')}}</span>
                                                </span>
                                            </div>
                                            <div>
                                                Brand Value
                                                <span
                                                    *ngIf="impactHandel('brand_value',option.brand_value).at(0) == '+'"
                                                    style="margin-left: 10px;">
                                                    <img src="../../../assets/img/plus-large-svgrepo-com.svg" alt=""
                                                        class="impact-img" style="width: 13px;">
                                                    <span style="font-style: initial; color: #035802;">{{option.brand_value}}</span>
                                                </span>
                                                <span
                                                    *ngIf="impactHandel('brand_value',option.brand_value) == '-'"
                                                    style="margin-left: 10px;">
                                                    <img src="../../../assets/img/minus-svgrepo-com.svg" alt=""
                                                        class="impact-img" style="width: 13px;">
                                                    <span style="font-style: initial; color: #ff0000;">{{option.brand_value}}</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="option-value" *ngIf="programStatus == 'ended'">
                                        {{getResponsePercentage(option.option_response)}}%
                                        <!-- {{option.option_response}} -->
                                    </div>
                                    <div class="option-value" *ngIf="programStatus == 'started'">
                                        0%
                                        <!-- {{option.option_response}} -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="main-cointainer" *ngIf="!gameOverViewFlag">
            <div class="side-nav-bar">
                <div>
                    <div class="side-bar-main-div">
                        <div *ngFor="let item of noOfReport;let i = index" (click)="changeReport(i)">
                            <!-- {{noOfReport.length - i}} -->
                            {{sideNavSummaryName[i]}}
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-main-div" *ngIf="!showMsg">
                <div class="heading">
                    Game Summary
                </div>
                <div>
                    <div class="summary-heading-main-div">
                        <div class="common-grid summary-heading">
                            <div>&nbsp;</div>
                            <div>
                                Effort Points
                                <img src="../../../assets/img/chevron-down-svgrepo-com.svg" alt=""
                                    style="width: 25px;cursor: pointer;" *ngIf="!effortSortFlag"
                                    (click)="handleSorting('low' , 'effort')">
                                <img src="../../../assets/img/chevron-up-svgrepo-com.svg" alt=""
                                    style="width: 25px; cursor: pointer;" *ngIf="effortSortFlag"
                                    (click)="handleSorting('high' , 'effort')">
                            </div>
                            <div>
                                ESG Score
                                <img src="../../../assets/img/chevron-down-svgrepo-com.svg" alt=""
                                    style="width: 25px;cursor: pointer;" *ngIf="!esgSortFlag"
                                    (click)="handleSorting('low' , 'esg')">
                                <img src="../../../assets/img/chevron-up-svgrepo-com.svg" alt=""
                                    style="width: 25px; cursor: pointer;" *ngIf="esgSortFlag"
                                    (click)="handleSorting('high' , 'esg')">
                            </div>
                            <div>
                                Brand Value
                                <img src="../../../assets/img/chevron-down-svgrepo-com.svg" alt=""
                                    style="width: 25px;cursor: pointer;" *ngIf="!brandValueSortFlag"
                                    (click)="handleSorting('low' , 'brandVal')">
                                <img src="../../../assets/img/chevron-up-svgrepo-com.svg" alt=""
                                    style="width: 25px; cursor: pointer;" *ngIf="brandValueSortFlag"
                                    (click)="handleSorting('high' , 'brandVal')">
                            </div>
                        </div>
                        <div class="common-grid summary-sub-heading">
                            <div>&nbsp;</div>
                            <div>Points remaining VS Points spent</div>
                            <div class="">
                                <!-- <div>E</div>
                                <div>S</div>
                                <div>G</div>
                                <div>Overall</div> -->
                                <div>&nbsp;</div>
                            </div>
                            <!-- <div>%</div> -->
                            <div>&nbsp;</div>
                        </div>
                    </div>
                    <div>
                        <div class="common-grid summary-content"
                            *ngFor="let item of allData[selectedReport].student_summary">
                            <div class="name-div">{{item.student_name}}</div>
                            <div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar"
                                        style="background-color: #CCD5AE; cursor: pointer; min-width: 15px;"
                                        [style.width]="getPercentage('1',item.budget_allocated,item.budget)+'%'"
                                        [title]="item.budget == null ? 0 : item.budget">
                                        <span style="padding-left: 4px; font-weight: bold;">{{item.budget == null ? 0 : item.budget}}</span>
                                        <!-- &#163; getCash('remain',item.budget_allocated,item.budget) -->
                                    </div>
                                    <div class="progress-bar" role="progressbar"
                                        style="background-color: #D8D8D8; cursor: pointer; min-width: 15px; text-align: end;"
                                        [style.width]="getPercentage('2',item.budget_allocated,item.budget)+'%'"
                                        [title]="getCash('spent',item.budget_allocated,item.budget)">
                                       <span style="padding-right: 4px; font-weight: bold;"> {{getCash('spent',item.budget_allocated,item.budget)}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="common-txt " style="text-align: center;">
                                <!-- <div>{{item.easy}}</div>
                                <div>{{item.social}}</div>
                                <div>X</div> -->
                                <div>{{getCeilValue(((+item.esg_score - 36.2) / 64) * 100)}} / 100</div>
                            </div>
                            <div class="common-txt" style="text-align: center;">
                               {{getCeilValue(((+item.brand_value - 32.5)/64)*100) }} / 100
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="showMsg" class="notFound">
                No Summary Found
            </div>
        </div>
    </div>
    <!-- <app-round-game-info style="position: absolute;bottom: 0;z-index: 11;" *ngIf="gameInfoFlag" condition="true"
        [pageNo]="page"></app-round-game-info> -->
        <app-about-game *ngIf="gameInfoFlag" [page]="page"></app-about-game>
</div>
