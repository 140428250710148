<div class="confirm_main_div_container">
    <div class="confirm_dialog_heading" [innerHTML]="heading"></div>
    <div class="confirm_dialog_btn_wrapper_div">
        <div class="cancel" (click)="close(false)">
            No
        </div>
        <div class="save" (click)="close(true)">
            Yes
        </div>
    </div>
</div>