<div class="addInstructorContainer" style="min-width: 56vw;max-width: 90vw; min-height: fit-content;">
    <div class="containerHeader">
        <div>
            <span>Instructor List</span>
        </div>
        <div>
            <img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg" />
        </div>
    </div>
    <div style="padding: 10px;">
        <ng-container *ngIf="!emptyDataFlag;else noAssignment">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th scope="col">SN.</th>
                    <th scope="col">Name</th>
                    <th scope="col">Instructor Code</th>
                    <th scope="col">Email Id</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let item of instructorList;let i = index">
                    <tr>
                        <th scope="row">{{i+1}}</th>
                        <td>{{item.name}}</td>
                        <td>{{item.university_code}}</td>
                        <td>{{item.email}}</td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        </ng-container>
        <ng-template #noAssignment>
            <div style="text-align: center;font-size: 20px;padding: 60px 0;">
                No Instructor Assigned
            </div>
        </ng-template>
        
    </div>
</div>