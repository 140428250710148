<div class="addLabelConatiner">
    <div class=newGameHeading>
        <div>Create Score Variable</div>
        <div><img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/></div>
    </div>
    <div class="newGameBody">
        <div class="reset_password_content">
            <div style="text-align: center;">Score Variable</div>
            <div class="passwordToggler" >
                <input autocomplete="off"  name="gameLabel" id="gameLabel" [(ngModel)]="gameLabel"  >
            </div>
        </div>
        <div class="reset_password_content">
            <div style="text-align: center;">Initial Value</div>
            <div class="passwordToggler" >
                <input autocomplete="off"  type="number" name="gameValue" id="gameValue" [(ngModel)]="gameValue"  >
            </div>
        </div>
    </div>
    <div class="reser_btn_main_div">
        <div class="reset_pd_btn_div">
            <div (click)="close()">close</div>
            <div (click)="create()">CREATE</div>
        </div>
    </div>
</div>