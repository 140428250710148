import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StudentLoginComponent } from './components/student-login/student-login.component';
import { AboutGameComponent } from './components/about-game/about-game.component';
import { RoundsComponent } from './components/rounds/rounds.component';
import { GameOverviewComponent } from './components/game-overview/game-overview.component';
import { PendingChangesGuard } from './services/Guards/pending-changes.guard';
import { StudentSummaryComponent } from './components/student-summary/student-summary.component';
import { SuperAdminComponent } from './components/super-admin/super-admin.component';
import { ReportComponent } from './components/reports/report.component';
import { SuperAdminDashboardComponent } from './components/superAdmin/super-admin-dashboard/super-admin-dashboard.component';
import { GameInfoComponent } from './components/epg/game-info/game-info.component';
import { EpgGameRoundsComponent } from './components/epg/epg-game-rounds/epg-game-rounds.component';
import { EpgGameSummaryComponent } from './components/epg/epg-game-summary/epg-game-summary.component';
import { InsEpgListComponent } from './components/instructor-dashboard/ins-epg-list/ins-epg-list.component';
import { InstructorRoutingComponent } from './components/instructor-dashboard/instructor-routing/instructor-routing.component';

const routes: Routes = [
  {
    path: '',
    redirectTo:'login', pathMatch: 'full',
  },
  {
    path: 'login',
    component: StudentLoginComponent,
  },
  {
    path: 'superAdmin',
    component: SuperAdminComponent,
  },
  {
    path: 'about',
    component: AboutGameComponent,
  },
  {
    path: 'rounds',
    component: RoundsComponent, 
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'summary',
    component: StudentSummaryComponent,
  },
  {
    path: 'overview',
    component: GameOverviewComponent,
  },
  {
    path:'superadmin',
    component:SuperAdminComponent,
  },
  {
    path:'report',
    component:ReportComponent,
  },
  {
    path:'superAdminDashboard',
    component:SuperAdminDashboardComponent,
  },

  // Epg game Routing 
  {
    path:'aboutGame',
    component:GameInfoComponent,
  },
  {
    path:'gameRound',
    component:EpgGameRoundsComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path:'gameSummary',
    component:EpgGameSummaryComponent,
  },
  //Instructor
  {
    path:'instructor',
    component: InstructorRoutingComponent,
  },

  {
    path:'**',
    component: StudentLoginComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash:true})],
  exports: [RouterModule],
  providers:[
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    PendingChangesGuard
  ]
})
export class AppRoutingModule { }
