<div class="dialogueConfigureGame">
    <div class="header">
        <div class="headerTxt">
            {{heading}}
        </div>
        <img style="cursor: pointer;" (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg" />
    </div>
    <div class="body">
        <div class="gameDetails">
            <div class="gameFields">
                <label>Strategy Name:<b>*</b></label>
                <input type="text" [disabled]="viewOnlyFlag" [(ngModel)]="strategyName">
            </div>
            <div class="gameFields">
                <label>Description:<b>*</b></label>
                <input type="text" [disabled]="viewOnlyFlag" [(ngModel)]="strategyDescription">
            </div>
        </div>
        <div class="gameDetails">
            <div class="gameFields">
                <label>Strategy For First Half:<b>*</b></label>
                <input type="text" [disabled]="viewOnlyFlag" [(ngModel)]="strategyFirstHalf">
            </div>
            <div class="gameFields">
                <label>Strategy For Second Half:<b>*</b></label>
                <input type="text" [disabled]="viewOnlyFlag" [(ngModel)]="strategySecondHalf">
            </div>
        </div>
        <div class="gameDetails">
            <div class="gameFields">
                <label>Student Unique Identifier</label>
                <mat-slide-toggle class="example-margin" color="primary" [(ngModel)]="indentifierChecked">
                </mat-slide-toggle>
            </div>
        </div>
        <div>
            <div class="strategy_matrix_main_div">
                <div class="coefficient_main_div">
                    <div class="coefficient_heading">Coefficient Matrix</div>
                    <div class="grid_4_center coefficient_sub_heading">
                        <div>G</div>
                        <div>C</div>
                        <div>D</div>
                        <div>M</div>
                    </div>
                    <div class="grid_4_center coefficient_inp_main_div" *ngFor="let item of strategyData;let i = index"
                        [id]="'matrix'+i">
                        <input type="text" [(ngModel)]="strategyData[i].coefficient_matrix_G"
                            [disabled]="viewOnlyFlag" [ngClass]="errorIndex == i?'sumError':''"
                            (keydown)="allowOnlyDigits($event)" (keydown)="maxtrixKeyDown(i)">
                        <input type="text" [(ngModel)]="strategyData[i].coefficient_matrix_C"
                            [disabled]="viewOnlyFlag" [ngClass]="errorIndex == i?'sumError':''"
                            (keydown)="allowOnlyDigits($event)" (keydown)="maxtrixKeyDown(i)">
                        <input type="text" [(ngModel)]="strategyData[i].coefficient_matrix_D"
                            [disabled]="viewOnlyFlag" [ngClass]="errorIndex == i?'sumError':''"
                            (keydown)="allowOnlyDigits($event)" (keydown)="maxtrixKeyDown(i)">
                        <input type="text" [(ngModel)]="strategyData[i].coefficient_matrix_M"
                            [disabled]="viewOnlyFlag" [ngClass]="errorIndex == i?'sumError':''"
                            (keydown)="allowOnlyDigits($event)" (keydown)="maxtrixKeyDown(i)">
                    </div>
                </div>
                <div class="coefficient_main_div">
                    <div class="coefficient_heading">States</div>
                    <div class="grid_3_center coefficient_sub_heading">
                        <div>G</div>
                        <div>C</div>
                        <div>D</div>
                    </div>
                    <div class="grid_3_center coefficient_inp_main_div" *ngFor="let item of strategyData;let i = index">
                        <input type="text" [(ngModel)]="strategyData[i].state_G" [disabled]="viewOnlyFlag"
                            (keydown)="allowOnlyBinary($event)" min="0" max="1" (input)="handleInput($event)">
                        <input type="text" [(ngModel)]="strategyData[i].state_C" [disabled]="viewOnlyFlag"
                            (keydown)="allowOnlyBinary($event)" min="0" max="1" (input)="handleInput($event)">
                        <input type="text" [(ngModel)]="strategyData[i].state_D" [disabled]="viewOnlyFlag"
                            (keydown)="allowOnlyBinary($event)" min="0" max="1" (input)="handleInput($event)">
                    </div>
                </div>
            </div>
            <div class="strategy_save_btn_div" *ngIf="!viewOnlyFlag">
                <div (click)="close()">CANCEL</div>
                <div (click)="saveStrategy()">SAVE</div>
            </div>
        </div>
    </div>
</div>