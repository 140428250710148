<!-- ADD QUESTION -->

<div class="addQuestion" *ngIf="data.type=='question'">
    <div class="header">
        <label>Add Question</label>
        <img (click)="close()" style="cursor: pointer;" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>  
    <div class="body">
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Theme Name <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <input type="text" disabled [(ngModel)]="themeName">
            </div>  
        </div>
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Question - 1 <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <textarea placeholder="Type the question text here" [(ngModel)]="questionTxt"></textarea>
            </div>  
        </div>
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Option - 1 <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <!-- FOR ESG GAME -->
                <div class="bodyContentOptionTop" *ngIf="!isCustomgame">
                    <div class="optionInside">
                        <div class="optionInsideContent">
                            <label>Effort Points :</label>
                            <input type="text"  (keypress)="preventText($event)" [(ngModel)]="budget1"/>
                        </div>
                        <div class="optionInsideContent">
                            <label>ESG :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="esg1"/>
                        </div>
                        <div class="optionInsideContent">
                            <label>Brand Value :</label>
                            <input type="text" (keypress)="preventText($event)"  [(ngModel)]="brandValue1"/>
                        </div>
                    </div>
                </div>

                <!-- FOR CUSTOM ESG GAME -->
                <div class="bodyContentOptionTop" *ngIf="isCustomgame">
                    <div class="optionInside" >
                        <div class="optionInsideContent" *ngFor="let labels of customLabelsValuesOption1;let i = index">
                            <label>{{labels.name}} :</label>
                            <input type="number"  [(ngModel)]="customLabelsValuesOption1[i].value"/>
                        </div>
                    </div>
                </div>
                <textarea class="optionTxtBox" placeholder="Type the option text here" [(ngModel)]="option1"></textarea>
            </div>  
        </div>
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Option - 2 <b>*</b></label>
            </div>
            <div class="bodyContentsRight">

                <!-- FOR ESG GAME -->
                <div class="bodyContentOptionTop" *ngIf="!isCustomgame">
                    <div class="optionInside">
                        <div class="optionInsideContent">
                            <label>Effort Points :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="budget2"/>
                        </div>
                        <div class="optionInsideContent">
                            <label>ESG :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="esg2"/>
                        </div>
                        <div class="optionInsideContent">
                            <label>Brand Value :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="brandValue2"/>
                        </div>
                    </div>
                </div>

                <!-- FOR CUSTOM ESG GAME -->
                <div class="bodyContentOptionTop" *ngIf="isCustomgame">
                    <div class="optionInside" >
                        <div class="optionInsideContent" *ngFor="let option2label of customLabelsValuesOption2;let j = index">
                            <label>{{option2label.name}} :</label>
                            <input type="number"   [(ngModel)]="customLabelsValuesOption2[j].value"/>
                        </div>
                    </div>
                </div>
                <textarea class="optionTxtBox" placeholder="Type the option text here" [(ngModel)]="option2"></textarea>
            </div>  
        </div>
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Option - 3 <b>*</b></label>
            </div>
            <div class="bodyContentsRight">

                 <!-- FOR ESG GAME -->
                <div class="bodyContentOptionTop" *ngIf="!isCustomgame">
                    <div class="optionInside">
                        <div class="optionInsideContent">
                            <label>Effort Points :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="budget3"/>
                        </div>
                        <div class="optionInsideContent">
                            <label>ESG :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="esg3"/>
                        </div>
                        <div class="optionInsideContent">
                            <label>Brand Value :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="brandValue3"/>
                        </div>
                    </div>
                </div>


                 <!-- FOR CUSTOM ESG GAME -->
                 <div class="bodyContentOptionTop" *ngIf="isCustomgame">
                    <div class="optionInside" >
                        <div class="optionInsideContent" *ngFor="let option3label of customLabelsValuesOption3;let k = index">
                            <label>{{option3label.name}} :</label>
                            <input type="number"  [(ngModel)]="customLabelsValuesOption3[k].value"/>
                        </div>
                    </div>
                </div>
                <textarea class="optionTxtBox" placeholder="Type the option text here" [(ngModel)]="option3"></textarea>
            </div>  
        </div>
        
    </div>
    <div class="contentFooter">
        <div class="gameCancel" style="color:#D43738;margin-right: 3rem;" (click)="close()">CANCEL</div>
        <div class="gameSave" style="margin-right: 2rem;" (click)="save()">SAVE</div>
    </div>
</div>

<!-- ADD CURVE BALL -->


<div class="addQuestion" *ngIf="data.type=='curveball'">
    <div class="header">
        <label>Add Curve Ball Question</label>
        <img (click)="close()" style="cursor: pointer;" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>  
    <div class="body">
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Theme Name <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <input type="text" disabled [(ngModel)]="themeName">
            </div>  
        </div>
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Question - 1 <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <textarea placeholder="Type the question text here" [(ngModel)]="questionCB"></textarea>
            </div>  
        </div>

        <!-- FOR ESG GAME -->
        <div class="bodyContents" *ngIf="!isCustomgame">
            <div class="bodyContentsLeft">
                <label>Option - 1 <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <div class="bodyContentOptionTop">
                    <div class="optionInside">
                        <div class="optionInsideContent">
                            <label>Effort Points :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="budget1" [min]="0"/>
                        </div>
                    </div>
                </div>
                <textarea class="optionTxtBox" placeholder="Type the option text here" [(ngModel)]="curveOption1"></textarea>
                <textarea class="optionTxtBox" placeholder="Type the popup text here" [(ngModel)]="curvePopup1"></textarea>
            </div>  
        </div>
        <div class="bodyContents" *ngIf="!isCustomgame">
            <div class="bodyContentsLeft">
                <label>Option - 2 </label>
            </div>
            <div class="bodyContentsRight">
                <div class="bodyContentOptionTop">
                    <div class="optionInside">
                        <div class="optionInsideContent">
                            <label>Effort Points :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="budget2" [min]="0"/>
                        </div>
                    </div>
                </div>
                <textarea class="optionTxtBox" placeholder="Type the option text here" [(ngModel)]="curveOption2"></textarea>
                <textarea class="optionTxtBox" placeholder="Type the popup text here" [(ngModel)]="curvePopup2"></textarea>
            </div>  
        </div>



        <!-- FOR CUSTOM ESG GAME -->

        <div class="bodyContents" *ngIf="isCustomgame">
            <div class="bodyContentsLeft">
                <label>Option - 1 <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <div class="bodyContentOptionTop">
                    <div class="optionInside">
                        <div class="optionInsideContent">
                            <label>{{curveBallCustomLabel.toUpperCase()}} :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="budget1" [min]="0"/>
                        </div>
                    </div>
                </div>
                <textarea class="optionTxtBox" placeholder="Type the option text here" [(ngModel)]="curveOption1"></textarea>
                <textarea class="optionTxtBox" placeholder="Type the popup text here" [(ngModel)]="curvePopup1"></textarea>
            </div>  
        </div>
        <div class="bodyContents" *ngIf="isCustomgame">
            <div class="bodyContentsLeft">
                <label>Option - 2 </label>
            </div>
            <div class="bodyContentsRight">
                <div class="bodyContentOptionTop">
                    <div class="optionInside">
                        <div class="optionInsideContent">
                            <label>{{curveBallCustomLabel.toUpperCase()}} :</label>
                            <input type="text" (keypress)="preventText($event)" [(ngModel)]="budget2" [min]="0"/>
                        </div>
                    </div>
                </div>
                <textarea class="optionTxtBox" placeholder="Type the option text here" [(ngModel)]="curveOption2"></textarea>
                <textarea class="optionTxtBox" placeholder="Type the popup text here" [(ngModel)]="curvePopup2"></textarea>
            </div>  
        </div>
        
    </div>
    <div class="contentFooter">
        <div class="gameCancel" style="color:#D43738;margin-right: 3rem;" (click)="close()">CANCEL</div>
        <div class="gameSave" style="margin-right: 2rem;" (click)="save()">SAVE</div>
    </div>
</div>