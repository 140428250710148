<div class="sideBarContainer">
    <div class="logo">
        <p>Game</p>
    </div>
    <div class="sideBarBody">
        <div class="selectables">
            <!-- <div class="sideBarItems" *ngIf="gameTypes.includes('1') || gameTypes.includes(1) "
                [ngClass]="currentTab=='esg'?'selectedItem':''" (click)="changeRoute('esg')">
                <p>ESG</p>
            </div>
            <div class="sideBarItems" *ngIf="gameTypes.includes('2') || gameTypes.includes(2)"
                [ngClass]="currentTab=='epg'?'selectedItem':''" (click)="changeRoute('epg')">
                <p>EPG</p>
            </div> -->
            <div class="sideBarItems" *ngFor="let game of gameTypes"
            [ngClass]="currentTab==game.name.toLowerCase()?'selectedItem':''" (click)="changeRoute(game.name.toLowerCase(),game.id)">
            <p>{{game.name.toUpperCase()}}</p>
            </div> 
            <div class="sideBarItems" [ngClass]="currentTab=='gr'?'selectedItem':''" (click)="changeRoute('gr',0)">
                <p>Game Report</p>
            </div>
        </div>
    </div>
    <div class="profile">
        <div class="logout_main_div" (click)="logout()">
            <p>Logout</p> <img  src="../../../../assets/img/logout.svg" />
        </div>
    </div>
</div>