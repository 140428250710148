<div class="addFormula">
    <div class=newGameHeading>
        <div>Create Score Label</div>
        <div><img (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg"/></div>
    </div>
    <div class="formulaBody">
        <div class="reset_password_content" style="margin-bottom: 1rem;">
            <div style="text-align: center;">Score Label</div>
            <div class="passwordToggler" style="width: 30%;" >
                <input autocomplete="off"  name="gameLabel" id="gameLabel" [(ngModel)]="labelName" style="border: 1px solid #B8B8B8; background-color: #fff;" >
            </div>
            <div style="text-align: center;">Initial Value</div>
            <div class="passwordToggler" style="width: 30%;">
                <input autocomplete="off" type="number" name="gameLabel" (keypress)="checkLabel($event)" id="gameLabel" [(ngModel)]="labelValue" style="border: 1px solid #B8B8B8; background-color: #fff;" 
                #labelInput="ngModel" required pattern="^[0-9]*$" >
                 
            </div>
            <!-- <mat-form-field style="border: 1px solid #B8B8B8; background-color: #fff;border-radius: 8px; padding:4px 10px">
                <mat-label>Is Effort Point</mat-label>
                <mat-select [(ngModel)]="isCurveBall">
                    <mat-option value="1">Yes</mat-option>
                    <mat-option value="0">No</mat-option>
                </mat-select>
            </mat-form-field> -->

            <div style="text-align: center;">Is Effort Point</div>
            <div class="passwordToggler" style="width: 30%;">
                <div style="border: 1px solid #B8B8B8; background-color: #fff; border-radius: 8px; padding:6px 10px">
                    <mat-select [(ngModel)]="isCurveBall">
                        <mat-option value="1">Yes</mat-option>
                        <mat-option value="0">No</mat-option>
                    </mat-select>
                </div>
            </div>
            
        </div>
        <div class="formulaDisplay w-100 d-flex">
            <input type="text" disabled [(ngModel)]="showFormula"  >
        </div>
        <div class="operatorsList"> 
             <div *ngFor="let operator of operators" class="operators" (click)="makeFormula(operator,0)">
                {{operator}}
             </div>
             <div class="operators" (click)="delete()">
                DELETE
             </div>
        </div>
        <div class="gameLables">
            <div *ngFor="let label of gameLabelss" (click)="makeFormula(label,1)" class="label">
                {{label.name}}
             </div>
        </div>
    </div>
    <div class="reser_btn_main_div">
        <div class="reset_pd_btn_div">
            <div (click)="close()">close</div>
            <div *ngIf="!isEdit" (click)="create()">CREATE</div>
            <div *ngIf="isEdit" (click)="create()">UPDATE</div>
        </div>
    </div>
</div>