import { Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/services/https/http.service';
@Component({
  selector: 'app-epg-game-rounds',
  templateUrl: './epg-game-rounds.component.html',
  styleUrls: ['./epg-game-rounds.component.css'],
})
export class EpgGameRoundsComponent implements OnInit {

  constructor(
    private router: Router,
    private httpSv: HttpService,
    private toster: ToastrService,
    private renderer: Renderer2,
    private el: ElementRef
  ) { }
  gameConditionFlag: boolean = false
  @ViewChild('comp_sub_container') private myScrollContainer!: ElementRef;
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(): Observable<boolean> | boolean {
    if (this.gameConditionFlag) {
      return false
    }
    else {
      return true
    }
  }
  ngOnInit(): void {
    if (sessionStorage.getItem('epgEnd') == '1' || !sessionStorage.getItem('playerId')) {
      this.gameConditionFlag = false
      this.router.navigate(['/login'])
    }

    this.sessionCode = sessionStorage.getItem('gameSessionCode')
    this.player_id = sessionStorage.getItem('playerId')
    if (!this.sessionCode || !this.player_id) {
      this.gameConditionFlag = false
      this.router.navigate(['/login'])
    }
    this.getStrategyByCode()
    this.getAllFeedBackQuestion()
  }
  sessionCode: any
  stratagyID: any
  player_id: any
  totalGameLen: any
  displayFlag: string = 'trail'
  questionNumber: number = 0
  playerPerviousMove: any
  playerPerviousMoveForDisplay: any
  // roundMoveFlag: boolean = true
  trailCompletedFlag: boolean = false

  epgTrialStrategy: any[] = [
    // {
    //   D: Math.floor(Math.random() * 2),
    //   C: Math.floor(Math.random() * 2),
    //   S: Math.floor(Math.random() * 2)
    // },
    // {
    //   D: Math.floor(Math.random() * 2),
    //   C: Math.floor(Math.random() * 2),
    //   S: Math.floor(Math.random() * 2)
    // },
  ]
  epgMainStrategy: any[] = []
  midGameFeedbackQues: any[] = []
  finalGameFeedbackQues: any[] = []
  selectedBid: any = 'null'



  startTrail() {
    this.displayFlag = 'gameQues'
    this.gameConditionFlag = false  //make this true for starting page gaurd
    sessionStorage.setItem('epgEnd', '2')

  }
  startGame() {
    this.displayFlag = 'gameQues'
    sessionStorage.setItem('roundRefresh', 'true')
  }
  // Handling bid selection for temprory
  handleBidSelection(option: any) {
    if (this.selectedBid == 'null') {
      this.selectedBid = option
      this.handleBid(this.selectedBid, this.pridicteValue)
    }
    else return
  }
  //Handling Precdiction
  pridicteValue: any = 'null'
  handlePredictionSelection(option: any) {
    if (this.pridicteValue == 'null'){
      this.pridicteValue = option
      this.scrollToBottom()
    }
    else return
  }
  scrollToBottom() {
    setTimeout(()=>{
      const div = document.getElementById('comp_main_container');
      console.log(div)
      if (div) {
        // div.scrollTop = div.scrollHeight;
        div.scrollTo({
          top: div.scrollHeight,
          behavior: 'smooth'
        });
      }
    },0)

  }
  // handling the bid with high or low 
  handleBid(option: any, prediction: any) {
    if (option != 'null' && prediction != 'null') {
      if (option == 'high') {
        this.moveToNextQuestion('High Price', prediction)
      }
      else if (option == 'low') {
        this.moveToNextQuestion('Low Price', prediction)
      }
      else if (option == 'not') {
        this.moveToNextQuestion('Stay Out', prediction)
      }

    }
  }
  moveData: any = 3
  result_flag: boolean = false
  result_data: any = {
    actual_estd_price: null,
    your_profit_in_this_quarter: null,
    your_cumulative_profit: null
  }
  // function to move to next question
  moveToNextQuestion(option: string, prediction: any) {
    // Condition for moving from trail round to main round 
    if (!this.trailCompletedFlag && this.questionNumber + 1 == 2) {
      this.result_flag = true
      const temp = 500 - ((this.epgTrialStrategy[this.questionNumber].coefficient_matrix_G * this.epgTrialStrategy[this.questionNumber].state_G) + (this.epgTrialStrategy[this.questionNumber].coefficient_matrix_C * this.epgTrialStrategy[this.questionNumber].state_C) + (this.epgTrialStrategy[this.questionNumber].coefficient_matrix_D * this.epgTrialStrategy[this.questionNumber].state_D) + (this.epgTrialStrategy[this.questionNumber].coefficient_matrix_M * this.questionNumber == 0 ? 0 : this.playerPerviousMoveForDisplay == 'Low Price' ? 0 : 1))
      this.result_data.actual_estd_price = temp >= 450 ? 'High' : 'Low'
      if (this.selectedBid == 'high') {
        if (temp >= 450) {
          this.result_data.your_profit_in_this_quarter = 200000;
        } else {
          this.result_data.your_profit_in_this_quarter = -50000;
        }
      }
      else if (this.selectedBid == 'low') {
        if (temp >= 450) {
          this.result_data.your_profit_in_this_quarter = 300000;
        } else {
          this.result_data.your_profit_in_this_quarter = -100000;
        }
      }
      else if (this.selectedBid == 'not') {
        this.result_data.your_profit_in_this_quarter = 0;
      }
      this.result_data.your_cumulative_profit = this.trailFirstProftSum + this.result_data.your_profit_in_this_quarter;
      setTimeout(() => {
        try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
          this.scrollToBottom()
        } catch (err) { }
      })
    }
    // Condition to check is round is main round  
    else if (this.trailCompletedFlag) {
      this.playerPerviousMove = option
      let data = {
        epg_player_id: this.player_id,
        epg_strategy_values_id: this.epgMainStrategy[this.questionNumber + 1 - 1].id,
        move: this.playerPerviousMove == 'High Price' ? 0 : this.playerPerviousMove == 'Low Price' ? 1 : this.playerPerviousMove == 'Stay Out' ? 2 : null,
        prev_move: this.moveData,
        prediction: this.pridicteValue == 'high' ? 0 : 1,
        session_code: this.sessionCode
      }
      this.moveData = this.playerPerviousMove == 'High Price' ? 0 : this.playerPerviousMove == 'Low Price' ? 1 : this.playerPerviousMove == 'Stay Out' ? 2 : null
      this.httpSv.updatePlayerData(data).subscribe((res: any) => {
        if (res['status']) {
          if (res['results'] == 'running') {
            this.result_flag = true
            this.result_data.actual_estd_price = res.actual_estd_price
            this.result_data.your_profit_in_this_quarter = res.your_profit_in_this_quarter
            this.result_data.your_cumulative_profit = res.your_cumulative_profit
            setTimeout(() => {
              try {
                this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
                this.scrollToBottom()
              } catch (err) { }
            })
            if (this.trailCompletedFlag) {
              sessionStorage.setItem('epgquen', JSON.stringify(this.questionNumber + 1))
              sessionStorage.setItem('epgprevmove', this.moveData)
            }
          } else {
            this.gameConditionFlag = false
            this.router.navigate(['/gameSummary'])
          }
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
    }
    // If it is trail and first question 
    else {
      this.result_flag = true
      // this.result_data.actual_estd_price = 'low'
      const temp = 500 - ((this.epgTrialStrategy[this.questionNumber].coefficient_matrix_G * this.epgTrialStrategy[this.questionNumber].state_G) + (this.epgTrialStrategy[this.questionNumber].coefficient_matrix_C * this.epgTrialStrategy[this.questionNumber].state_C) + (this.epgTrialStrategy[this.questionNumber].coefficient_matrix_D * this.epgTrialStrategy[this.questionNumber].state_D) + (this.epgTrialStrategy[this.questionNumber].coefficient_matrix_M * this.questionNumber == 0 ? 0 : this.playerPerviousMoveForDisplay == 'Low Price' ? 0 : 1))
      this.result_data.actual_estd_price = temp >= 450 ? 'High' : 'Low'
      if (this.selectedBid == 'high') {
        if (temp >= 450) {
          this.result_data.your_profit_in_this_quarter = 200000;
        } else {
          this.result_data.your_profit_in_this_quarter = -50000;
        }
      }
      else if (this.selectedBid == 'low') {
        if (temp >= 450) {
          this.result_data.your_profit_in_this_quarter = 300000;
        } else {
          this.result_data.your_profit_in_this_quarter = -100000;
        }
      }
      else if (this.selectedBid == 'not') {
        this.result_data.your_profit_in_this_quarter = 0;
      }
      // this.result_data.your_profit_in_this_quarter = Math.floor((Math.random() * 10000) + 1);
      this.result_data.your_cumulative_profit = this.result_data.your_profit_in_this_quarter;
      this.trailFirstProftSum =  this.result_data.your_cumulative_profit
      this.playerPerviousMove = option
      setTimeout(() => {
        try {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
          this.scrollToBottom()
        } catch (err) { }
      })

      // this.changeQuestion()
    }
  }
  trailFirstProftSum:any
  changeQuestion() {
    const ele = document.getElementById('game_main_cointainer')
    if (ele) {
      ele.scrollTo(0, 0)
    }
    if (+this.questionNumber + 1 == 2 && !this.trailCompletedFlag) {

      this.questionNumber = 0
      this.trailCompletedFlag = true
      this.playerPerviousMove = null
      this.displayFlag = 'mainPopup'
      this.pridicteValue = 'null'
      this.selectedBid = 'null'
      this.result_flag = false
      this.result_data = {
        actual_estd_price: null,
        your_profit_in_this_quarter: null,
        your_cumulative_profit: null
      }
      const eleRef = document.getElementsByClassName('question_parent_div')[0]
      if (eleRef) {
        eleRef.classList.remove('animation_class')
        setTimeout(() => {
          eleRef.classList.add('animation_class')
        })
      }
    }
    else {
      // Condition for checking half game is competed for not for feedback question 
      if (this.questionNumber + 1 == Math.floor(this.totalGameLen / 2)) {
        this.displayFlag = 'feedQue1'
        sessionStorage.setItem('feedback1', 'false')
      }
      // Condition for checking game is competed for not for feedback question(final one) 
      else if (this.questionNumber + 1 == this.totalGameLen) {
        this.displayFlag = 'feedQue2'
        sessionStorage.setItem('feedback2', 'false')
      }
      setTimeout(() => {
        this.pridicteValue = 'null'
        this.selectedBid = 'null'
        this.result_flag = false
        this.result_data = {
          actual_estd_price: null,
          your_profit_in_this_quarter: null,
          your_cumulative_profit: null
        }
        this.questionNumber++;
        const eleRef = document.getElementsByClassName('question_parent_div')[0] as HTMLElement;
        if (eleRef) {
          this.renderer.removeClass(eleRef, 'animation_class');
          const offsetHeight = eleRef.offsetHeight;
          this.renderer.addClass(eleRef, 'animation_class');
          // if (this.trailCompletedFlag) {
          //   sessionStorage.setItem('epgquen', this.questionNumber.toString())
          //   sessionStorage.setItem('epgprevmove', this.moveData)
          // }

        }
      })
    }
    this.playerPerviousMoveForDisplay = this.playerPerviousMove
  }
  textAreaAdjust(event: Event): void {
    const element = event.target as HTMLTextAreaElement;
    element.style.height = '0.5px';
    element.style.height = (1 + element.scrollHeight) + 'px';
  }

  get getYear() {
    const year = Math.ceil((this.questionNumber + 1) / 4)
    return year
  }
  get getQuestionNum() {
    let year = Math.ceil((this.questionNumber + 1) / 4)
    let num = (this.questionNumber + 1) - (4 * (year - 1))
    return num
  }
  feedBackData: any = {}
  firstFeedBackOptionNextBtnFlag: boolean = false
  secondFeedBackOptionNextBtnFlag: boolean = false
  feedbackOptionHandel(optionData: any, data: any) {
    this.feedBackData[data.id] = {
      id: optionData.id,
      ans: optionData.option
    }
    if (this.questionNumber == Math.floor(this.totalGameLen / 2)) {
      if (Object.keys(this.feedBackData).length == this.midGameFeedbackQues.length) {
        const isNullish = Object.values(this.feedBackData).every((value: any) => {
          if (typeof value === 'string') {
            return value.trim() === '';
          }
          return true; // treat non-string values as truthy
        });

        this.firstFeedBackOptionNextBtnFlag = !isNullish
      }
      else this.firstFeedBackOptionNextBtnFlag = false
    } else {
      if (Object.keys(this.feedBackData).length == this.finalGameFeedbackQues.length) {
        const isNullish = Object.values(this.feedBackData).every((value: any) => {
          if (typeof value === 'string') {
            return value.trim() === '';
          }
          return true; // treat non-string values as truthy
        });

        this.secondFeedBackOptionNextBtnFlag = !isNullish
      }
      else this.secondFeedBackOptionNextBtnFlag = false
    }
  }
  feedbackTextAreaHandel(val: any, data: any) {
    if (val.target.value && val.target.value != '' && val.target.value.trim() != '') {
      this.feedBackData[data.id] = val.target.value
      if (this.questionNumber == Math.floor(this.totalGameLen / 2)) {
        if (Object.keys(this.feedBackData).length == this.midGameFeedbackQues.length) this.firstFeedBackOptionNextBtnFlag = true
        else this.firstFeedBackOptionNextBtnFlag = false
      }
      else {
        if (Object.keys(this.feedBackData).length == this.finalGameFeedbackQues.length) this.secondFeedBackOptionNextBtnFlag = true
        else this.secondFeedBackOptionNextBtnFlag = false
      }
    }
    else this.questionNumber == Math.floor(this.totalGameLen / 2) ? this.firstFeedBackOptionNextBtnFlag = false : this.secondFeedBackOptionNextBtnFlag = false
  }

  //Post APIs
  firstFeedBackRound() {
    if (!this.firstFeedBackOptionNextBtnFlag) return
    else {
      let data: any = {
        epg_player_id: this.player_id,
        session_code: this.sessionCode,
        is_game_completed: 0,
        answers: []
      }
      this.midGameFeedbackQues.forEach((ele: any) => {
        if (this.feedBackData[ele.id]?.id) {
          data['answers'].push({
            epg_feedback_question_id: ele.id,
            answer_id: this.feedBackData[ele.id]?.id
          })
        }
        else {
          data['answers'].push({
            epg_feedback_question_id: ele.id,
            answer: this.feedBackData[ele.id]
          })
        }
      })
      this.httpSv.updateFeedbackAnswer(data).subscribe((res: any) => {
        if (res['status']) {
          if (res['results'] == 'running') {
            this.displayFlag = 'gameQues'
            sessionStorage.setItem('feedback1', 'true')
            this.feedBackData = {}
          } else {
            this.gameConditionFlag = false
            this.router.navigate(['/gameSummary'])
          }
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
    }
  }
  secondFeedbackQuestion() {
    if (!this.secondFeedBackOptionNextBtnFlag) return
    else {
      let data: any = {
        epg_player_id: this.player_id,
        session_code: this.sessionCode,
        is_game_completed: 1,
        answers: []
      }
      this.finalGameFeedbackQues.forEach((ele: any) => {
        if (this.feedBackData[ele.id]?.id) {
          data['answers'].push({
            epg_feedback_question_id: ele.id,
            answer_id: this.feedBackData[ele.id]?.id
          })
        }
        else {
          data['answers'].push({
            epg_feedback_question_id: ele.id,
            answer: this.feedBackData[ele.id]
          })
        }
      })
      this.httpSv.updateFeedbackAnswer(data).subscribe((res: any) => {
        if (res['status']) {
          this.feedBackData = {}
          this.gameConditionFlag = false
          sessionStorage.setItem('feedback2', 'true')
          this.router.navigate(['/gameSummary'])
        }
        else {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }
      }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
    }
  }




  // Get APIs
  getStrategyByCode() {
    this.httpSv.getStrategy(this.sessionCode).subscribe((res: any) => {
      if (res['status']) {
        this.stratagyID = res['results'][0].id
        this.epgMainStrategy = res['results'][0].strategy_values
        this.totalGameLen = this.epgMainStrategy.length
        this.epgTrialStrategy = res['results'][0].strategy_values.slice(0, 2)
        console.log(this.epgTrialStrategy)
        if (sessionStorage.getItem('roundRefresh') == 'true') {
          this.loadDataWithLocal()
        }
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
  }
  getAllFeedBackQuestion() {
    this.httpSv.getAllFeebackQues().subscribe((res: any) => {
      if (res['status']) {
        const que = res['results']
        this.midGameFeedbackQues = que.filter((ele: any) => ele.is_after_10th_period == 1)
        this.finalGameFeedbackQues = que.filter((ele: any) => ele.is_after_10th_period == 0)
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
  }



  // Resume Data On Refresh 
  loadDataWithLocal() {
    if (sessionStorage.getItem('epgquen')) {
      let localQueNo: any = sessionStorage.getItem('epgquen')
      if (localQueNo !== null) {
        localQueNo = parseInt(localQueNo, 10)
        if (!isNaN(localQueNo)) {
          if (localQueNo <= this.totalGameLen) {
            if (sessionStorage.getItem('epgprevmove')) {
              if (localQueNo == 10) {
                if (sessionStorage.getItem('feedback1') == 'true') this.displayFlag = 'gameQues'
                else if (sessionStorage.getItem('feedback1') == 'false') this.displayFlag = 'feedQue1'
                else this.displayFlag = 'gameQues'
              }
              else if (localQueNo == 20) {
                if (sessionStorage.getItem('feedback2') == 'true') this.router.navigate(['/gameSummary'])
                else if (sessionStorage.getItem('feedback2') == 'false') this.displayFlag = 'feedQue2'
                else this.displayFlag = 'gameQues'
              }
              else this.displayFlag = 'gameQues'
              this.trailCompletedFlag = true
              this.questionNumber = localQueNo
              this.moveData = sessionStorage.getItem('epgprevmove')
              this.moveData = parseInt(this.moveData, 10)
              this.playerPerviousMove = this.moveData == 0 ? 'High Price' : this.moveData == 1 ? 'Low Price' : this.moveData == 2 ? 'Stay Out' : null
              this.playerPerviousMoveForDisplay = this.playerPerviousMove
            }
            else {
              this.gameConditionFlag = false
              this.router.navigate(['/login'])
              alert('1')
              this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
            }
          } else {
            this.gameConditionFlag = false
            this.router.navigate(['/login'])
            alert('2')
            this.toster.error('Oops! something went wrong', '', { timeOut: 2000 })
          }
        } else {
          this.gameConditionFlag = false
          alert('3')
          this.router.navigate(['/login'])
        }
      } else {
        this.gameConditionFlag = false
        this.router.navigate(['/login'])
      }
    } else {
      this.gameConditionFlag = false
      this.router.navigate(['/login'])
    }
  }
}
