import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-game-info',
  templateUrl: './game-info.component.html',
  styleUrls: ['./game-info.component.css']
})
export class GameInfoComponent implements OnInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('epgEnd') == '1' || sessionStorage.getItem('epgEnd') == '0'){
      this.router.navigate(['/login'])
    }
    if(!sessionStorage.getItem('gameSessionCode') && !sessionStorage.getItem('playerId')){
      this.router.navigate(['/login'])
    }
  }
  game_info_page:number = 1
  changeGameInfo(){
    if(this.game_info_page != 2){
      this.game_info_page ++
    }
    else{
      this.router.navigate(['/gameRound'])
    }
  }
}
