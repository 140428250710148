<div class="instructorConatainer" style="font-family: 'Avenir';">
    <div class="gameHeader">
        <div class="gameHeaderLeft">
            <img style="cursor: pointer;" (click)="goBackSummary()" src="../../../../assets/img/backArrow.svg"/>
            <p >GAME SUMMARY</p>
        </div>
    </div>
    <!-- <div class="gameSummarySelect">
        <label>CHOOSE GAME REPORT</label>
        <mat-form-field class="selectOption">
            <mat-select  name="food" >
                <mat-option [value]="round" *ngFor="let round of rounds">{{round}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->

    <div style="border-top: 2px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
    <div class="gameBody">
        <div class="GameTable" style="width: 100%;">
        <div class="web_user_search_main_div" style="justify-content: space-between;">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="text" class="search_icon_inp" placeholder="Search"
                        (keyup)="applyFilter($event)" autocomplete="off">
                </div>
            </div>   
            <div style="display: flex;">
                <button class="gradient-btn" (click)="downloadAllCSV()">Download CSV</button>
                <button (click)="downloadAll()" class="gradient-btn">Download All</button>
                <button (click)="sendAllReport()" class="gradient-btn">Send All</button>
                <img src="../../../../assets/img/refresh.svg" (click)="refreshPage()" style="width: auto; height: 45px;"/>
            </div> 
        </div>
        <div class="web_user_table_main_div" *ngIf="!isCustomGame">
            <table mat-table  matSort [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">
                    <!-- Sl NO -->
                    <ng-container matColumnDef="sl_no">
                        <th class="" mat-sort-header mat-header-cell *matHeaderCellDef [style.width.%]="8">Sl No</th>
                        <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                    </ng-container>
                <!-- Sl NO -->
                <ng-container matColumnDef="name">
                    <th class="" mat-header-cell mat-sort-header *matHeaderCellDef [style.width.%]="20"> Student/ Player
                        Names</th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{element.name}} </td>
                </ng-container>
    
                <ng-container matColumnDef="points">
                    <th class="common_table_data" mat-sort-header="total_expense" mat-header-cell *matHeaderCellDef [style.width.%]="25">Effort Points
                        (Points spent vs. Points remaining)</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div class="reportContainer">
                            <div class="pointRemaining"  [style.width]="element.total_expense+'%'" >
                                {{element.total_expense>0?element.total_expense:0}}
                            </div>
                            <div class="pointTotal" [style.width]="element.budget+'%'">
                                {{element.budget>0?element.budget:0}}
                            </div>
                        </div>
                    </td>
                </ng-container>
    
                <!-- Modify -->
                <ng-container matColumnDef="esg_score">
                    <th class="common_table_data" mat-sort-header mat-header-cell *matHeaderCellDef [style.width.%]="10"> ESG Score</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element" style="padding-left: 1.2rem;">
                      {{element.esg_score}}
                    </td>
                </ng-container>

                <ng-container matColumnDef="brand_value">
                    <th class="common_table_data" mat-sort-header mat-header-cell *matHeaderCellDef [style.width.%]="10">Brand Value</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element" style="padding-left: 1.2rem;">  {{element.brand_value}}</td>
                </ng-container>

                <ng-container matColumnDef="request">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10">Report Requests</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> 
                        <div style="display: flex;">
                            <img *ngIf="element.report_status=='Requested'" src="../../../../assets/img/activeMail.svg"/>
                            <img *ngIf="element.report_status!='Requested'" src="../../../../assets/img/inactiveMail.svg"/>
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img  style="width: 20px; display: block;" matTooltipClass="tooltipp" matTooltipHideDelay="1000"
                            mat-raised-button matTooltip={{element.email}} src="../../../../assets/img/info.svg" />
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img style="width: 25px;" src="../../../../assets/img/download.png" (click)="downloadReport(element)"/>
                        </div>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="action">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10">Action</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> 
                        <div style="display: flex;">
                            <img src="../../../../assets/img/carbon_view.svg" (click)="viewReport(element)"/>
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img src="../../../../assets/img/send.svg" (click)="sendEmail(element)"/>
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px" >&nbsp;</div>
                            <img src="../../../../assets/img/mdi-light_delete.svg" (click)="deletePlayer(element.id)" />
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        <div class="web_user_table_main_div" *ngIf="isCustomGame">
            <table mat-table  matSort [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">
                    <!-- Sl NO -->
                <ng-container matColumnDef="sl_no">
                    <th class="" mat-sort-header mat-header-cell *matHeaderCellDef [style.width.%]="8">Sl No</th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>
                <ng-container matColumnDef="name">
                    <th class="" mat-sort-header mat-header-cell *matHeaderCellDef [style.width.%]="8">Name</th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{element.name}} </td>
                </ng-container>
                <ng-container *ngFor="let column of customDisplayColumn" [matColumnDef]="column.name">
                    <div *ngIf="!column.is_curve_ball">
                        <th mat-header-cell *matHeaderCellDef>{{ column.name | titlecase }}</th>
                        <td mat-cell *matCellDef="let element" >{{ getCustomValueName(element, column.id)}}
                        </td>
                    </div>
                    <div *ngIf="column.is_curve_ball">
                        <th class="common_table_data" mat-sort-header="total_expense" mat-header-cell *matHeaderCellDef [style.width.%]="25">{{column.name}}
                            (Points spent vs. Points remaining)</th>
                        <td class="common_table_data" mat-cell *matCellDef="let element">
                            <div class="reportContainer">
                                <div class="pointRemaining"  [style.width]="getCustomValueName(element, column.id)+'%'" >
                                    {{getCustomValueName(element, column.id)>0?getCustomValueName(element, column.id):0}}
                                </div>
                                <div class="pointTotal" [style.width]="100-getCustomValueName(element, column.id)+'%'">
                                    {{100-getCustomValueName(element, column.id)>0?100-getCustomValueName(element, column.id):0}}
                                </div>
                            </div>
                        </td>
                    </div>
                    </ng-container>
                <ng-container matColumnDef="request">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10">Report Requests</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> 
                        <div style="display: flex;">
                            <img *ngIf="element.report_status=='Requested'" src="../../../../assets/img/activeMail.svg"/>
                            <img *ngIf="element.report_status!='Requested'" src="../../../../assets/img/inactiveMail.svg"/>
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img  style="width: 20px; display: block;" matTooltipClass="tooltipp" matTooltipHideDelay="1000"
                            mat-raised-button matTooltip={{element.email}} src="../../../../assets/img/info.svg" />
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img style="width: 25px;" src="../../../../assets/img/download.png" (click)="downloadReport(element)"/>
                        </div>
                    </td>
                </ng-container>
                
                <ng-container matColumnDef="action">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10">Action</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> 
                        <div style="display: flex;">
                            <img src="../../../../assets/img/carbon_view.svg" (click)="viewReport(element)"/>
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img src="../../../../assets/img/send.svg" (click)="sendEmail(element)"/>
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px" >&nbsp;</div>
                            <img src="../../../../assets/img/mdi-light_delete.svg" (click)="deletePlayer(element.id)" />
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
        </div>
    </div>
    <div class="progress-bar" *ngIf="isProcessing">
        <div class="progress-container">
            <mat-progress-bar mode="determinate" [value]="progressValue"></mat-progress-bar>
            <p style="margin-top: 2rem; text-align: center;">{{progressValue}}%</p>
            <p style="margin-top: 5rem; text-align: center;">Your request is being processed..</p>
        </div>
        <div style="margin-top: 3rem;">
            <button class="gradient-btn" (click)="cancel()">cancel</button>     
        </div>
    </div>
</div>

