import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/https/http.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-university-list',
  templateUrl: './university-list.component.html',
  styleUrls: ['./university-list.component.css']
})
export class UniversityListComponent implements OnInit {

  constructor(
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.getInstructorsList()
  }
  displayedColumns: string[] = ['sl_no', 'name', 'no_of_instructor', 'view', 'action'];
  dataInstructor: any[] = [];
  dataSource: any = new MatTableDataSource();


  addUniversity() {
    const dialogRef = this.dialog.open(AddModifyUniversityComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type: 'add'
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ngOnInit()
      }
    })
  }
  modifyUniversity(element_data: any) {
    const dialogRef = this.dialog.open(AddModifyUniversityComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: {
        type: 'edit',
        university: element_data
      }
    })
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.ngOnInit()
      }
    })
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  deleteUniversity(id: any) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: { heading: 'Are you sure to delete this university ?' }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.httpSv.deleteUniversity(id).subscribe((res: any) => {
          if (res['status']) {
            this.ngOnInit()
          } else {
            this.toster.error(res.message, '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        })
      }
    })
  }

  viewAllInstructor(element_data: any) {
    const dialogRef = this.dialog.open(InstructorListDialogComponent, {
      hasBackdrop: true,
      disableClose: true,
      data: element_data
    })
  }

  getInstructorsList() {
    this.httpSv.getAllUniversityList().subscribe((res: any) => {
      if (res['status']) {
        this.dataSource.data = res['results'];
      }
      else {
        this.dataSource.data = [];
        this.toster.error(res.error[0], '', { timeOut: 2000 })
      }
    }, (err) => {
      this.dataSource.data = []
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }
}








@Component({
  selector: 'app-add-modify-university',
  templateUrl: './add_modify_new_university_dialog.html',
  styleUrls: ['./university-list.component.css']
})
export class AddModifyUniversityComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<AddModifyUniversityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
  ) { }
  ngOnInit(): void {
    if (this.data.type == 'edit') {
      this.uniName = this.data.university.name
    }
  }
  uniName: any
  close() {
    this.dialogRef.close(false)
  }
  save() {
    if (!this.uniName || this.uniName == '' || this.uniName == null) {
      this.toster.info('Please enter the university name')
      return
    }
    else {
      if (this.data.type == 'add') {
        this.httpSv.createNewUniversity(this.uniName).subscribe((res: any) => {
          if (res['status']) {
            this.toster.success('University Added Successfully', '', { timeOut: 2000 })
            this.dialogRef.close(true)
          }
          else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          if (err.status == 422) {
            this.toster.info('This university already exists.', '', { timeOut: 3000 })
          } else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        })
      }
      else if (this.data.type == 'edit') {
        this.httpSv.updateUnivesityDtls(this.data.university.id, this.uniName).subscribe((res: any) => {
          if (res['status']) {
            this.toster.success('University Updated Successfully', '', { timeOut: 2000 })
            this.dialogRef.close(true)
          }
          else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          if (err.status == 422) {
            this.toster.info('This university already exists.', '', { timeOut: 3000 })
          } else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        })
      }
    }
  }
}





@Component({
  selector: 'app-instructor-list-dialog',
  templateUrl: './instructor_list_diaolog.html',
  styleUrls: ['./university-list.component.css']
})
export class InstructorListDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<InstructorListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toster: ToastrService,
    private httpSv: HttpService,
  ) { }
  ngOnInit(): void {
    this.instructorList = this.data.instructors
    if (this.instructorList.length > 0) {
      this.emptyDataFlag = false
    }
  }
  emptyDataFlag: boolean = true
  instructorList: any[] = []
  close() {
    this.dialogRef.close(false)
  }
}