import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';

@Component({
  selector: 'app-game-overview',
  templateUrl: './game-overview.component.html',
  styleUrls: ['./game-overview.component.css']
})
export class GameOverviewComponent implements OnInit {

  constructor(
    private httpSv: HttpService,
    private toster: ToastrService,
    private dataSv: DataShareService
  ) { }
  programStatus: any = 'ended'
  ngOnInit(): void {
    this.allRoundTheameName = this.dataSv.roundTheameData
    this.theameName = this.allRoundTheameName['1']
    // this.httpSv.programStatus(sessionStorage.getItem('university_code')).subscribe((res: any) => {
    //   if (res['status']) {
    //     this.programStatus = 'started'
    //     sessionStorage.setItem('game_start_time', res['result'].game_start_time)
    //   } else {
    //     this.programStatus = 'ended'
    //   }
    // }, (err: any) => {
    //   this.programStatus = 'ended'
    // })
    this.getAllReport()
    this.getAllQueOption()
  }
  gameOverViewFlag: boolean = true
  gameInfoFlag: boolean = false
  selectedRound: any = '1'
  allRoundTheameName: any
  theameName: any
  switchOverViewFlag(data: any) {
    if (this.gameInfoFlag)
      this.gameInfoFlag = false
    if (data == '1')
      this.gameOverViewFlag = true
    else if (data == '2')
      this.gameOverViewFlag = false
  }
  page: any
  opendrawer(data: any) {
    console.log(data)
    if (data) {
      if (data != 'resume') {
        this.page = data
        this.gameInfoFlag = true
      }
      else {
        this.gameInfoFlag = false
      }

    }
  }
  queAllData: any = {
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
  }
  noOfSessionPlayed: any
  getAllQueOption() {
    this.httpSv.getAllQuestionAndOption(sessionStorage.getItem('university_code')).subscribe((res: any) => {
      if (res['status']) {
        const temp = res['result'].questions
        this.noOfSessionPlayed = res['result'].studentCount
        temp.forEach((ele: any) => {
          this.queAllData[ele['quiz_id']].push(ele)
        });
        console.log(this.queAllData)
      }
      else {
        this.toster.error(res['message'], '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops!! Something went wrong', '', { timeOut: 2000 })
    })
  }
  sideNavCondition(key: any) {
    if (this.queAllData[key].length > 0) return true
    else return false
  }
  getTheameName(key: any) {
    return this.allRoundTheameName[key]
  }
  changeRoundHandeler(round: any) {
    this.selectedRound = round
    this.theameName = this.allRoundTheameName[round]
  }
  impactHandel(type: string, data: any, data2: any = null, data3: any = null) {
    if (type == 'cash') {
      if (data == null || data == 'No cost- Strategic Decision') return '#'
      else {
        const temp = data.toString().at(0)
        if (temp == '+' || temp == '0') return '+'
        else return '-'
      }
    }
    else if (type == 'esg') {
      if (data == null) return '+'
      else {
        const temp = data.toString().at(0)
        if (temp == '+') return '+'
        else if(temp == 0) return '+'
        else return '-'
      }
    }
    else if (type == 'brand_value') {
      const temp = data == null ? 0 : data
      const final = parseInt(temp).toString()
      if (final.at(0) == '-') return '-'
      else return '+'
    }
    else {
      return ''
    }
  }
  getImapatNumber(val1: any = null, val2: any = null, val3: any = null) {
    const temp1 = val1 == null ? 0 : val1
    const temp2 = val2 == null ? 0 : val2
    const temp3 = val3 == null ? 0 : val3
    return Math.abs(parseInt(temp1) + parseInt(temp2) + parseInt(temp3))
  }
  noOfReport: any[] = []
  allData: any
  originalData: any
  selectedReport = 0
  showMsg: boolean = false
  sideNavSummaryName: any = []
  getAllReport() {
    this.allData = []
    this.originalData = []
    this.httpSv.getAllGameReport(sessionStorage.getItem('university_code')).subscribe((res: any) => {
      if (res['status']) {
        this.noOfReport = []
        this.allData = JSON.parse(JSON.stringify(res['result']))
        this.originalData = JSON.parse(JSON.stringify(res['result']))
        if (this.allData.length == 0) {
          this.showMsg = true
        }
        else {
          this.showMsg = false
        }

        res['result'].forEach((ele: any) => {
          this.noOfReport.push('')
          if (this.sideNavSummaryName.includes(`Game Report- ${ele['created_date']}-1`)) {
            let i = 2
            this.sideNavSummaryName.every((element: any, index: any) => {
              if (this.sideNavSummaryName.includes(`Game Report- ${ele['created_date']}-${i}`)) {
                i++
                return true
              }
              else {
                this.sideNavSummaryName.splice(this.sideNavSummaryName.findIndex((item: any) => item == `Game Report- ${ele['created_date']}-${i - 1}`), 0, `Game Report- ${ele['created_date']}-${i}`)
                return false
              }
            })
          } else {
            this.sideNavSummaryName.unshift(`Game Report- ${ele['created_date']}-1`)
          }

        })
        console.log(this.allData)
        console.log(this.sideNavSummaryName)
        this.allData.reverse()
        //  this.sideNavSummaryName.reverse()
      }
    })
  }
  changeReport(index: any) {
    console.log(this.allData)
    this.selectedReport = index
  }
  refresh(data: any) {
    if (data) {
      this.ngOnInit()
    }
  }
  getCash(type: string, total: any, left: any) {
    if (type == 'remain') {
      const number = parseInt(left == null ? 0 : left)
      if (number >= 1000) {
        return (number / 1000) + 'k';
      } else {
        return number.toString();
      }
    }
    else {
      const number = parseInt(total) - parseInt(left == null ? 0 : left)
      return number.toString();
    }
  }
  getPercentage(type: any, total: any, left: any) {
    const numinator = type == '1' ? parseInt(left == null ? 0 : left) : parseInt(total) - parseInt(left == null ? 0 : left)
    const persent = ((numinator == null ? 0 : numinator) / parseInt(total)) * 100
    return persent
  }
  getResponsePercentage(noOfSelection: any) {
    
    if (this.noOfSessionPlayed && this.noOfSessionPlayed != 0) {
      const responsePercet = (noOfSelection / this.noOfSessionPlayed) * 100
      console.log(responsePercet)
      return Math.ceil(responsePercet)
    }
    else {
      return 0
    }
  }


  //Sorting Method

  effortSortFlag: boolean = false
  esgSortFlag: boolean = false
  brandValueSortFlag: boolean = false
  handleSorting(type: string, col: string) {
    if (type == 'low') {
      switch (col) {
        case 'effort':
          this.effortSortFlag = true
          this.esgSortFlag = false
          this.brandValueSortFlag = false
          this.allData.map((ele: any) => {
            ele['student_summary'].sort((a: any, b: any) => {
              if (JSON.parse(a.budget == null ? 0 : a.budget) > JSON.parse(b.budget == null ? 0 : b.budget))
                return 1
              else if ((JSON.parse(a.budget == null ? 0 : a.budget) < JSON.parse(b.budget == null ? 0 : b.budget)))
                return -1
              else return 0
            })
          })
          break;
        case 'esg':
          this.effortSortFlag = false
          this.esgSortFlag = true
          this.brandValueSortFlag = false
          this.allData.map((ele: any) => {
            ele['student_summary'].sort((a: any, b: any) => {
              if (JSON.parse(a.esg_score == null ? 0 : a.esg_score) > JSON.parse(b.esg_score == null ? 0 : b.esg_score))
                return 1
              else if ((JSON.parse(a.esg_score == null ? 0 : a.esg_score) < JSON.parse(b.esg_score == null ? 0 : b.esg_score)))
                return -1
              else return 0
            })
          })
          break;
        case 'brandVal':
          this.effortSortFlag = false
          this.esgSortFlag = false
          this.brandValueSortFlag = true
          this.allData.map((ele: any) => {
            ele['student_summary'].sort((a: any, b: any) => {
              if (+a.brand_value > +b.brand_value)
                return 1
              else if (+a.brand_value < +b.brand_value)
                return -1
              else return 0
            })
          })
          break;



      }
    }
    else if (type == 'high') {
      this.effortSortFlag = false
      this.esgSortFlag = false
      this.brandValueSortFlag = false
      switch (col) {
        case 'effort':
          this.allData.map((ele: any) => {
            ele['student_summary'].sort((a: any, b: any) => {
              if (JSON.parse(a.budget == null ? 0 : a.budget) > JSON.parse(b.budget == null ? 0 : b.budget))
                return -1
              else if ((JSON.parse(a.budget == null ? 0 : a.budget) < JSON.parse(b.budget == null ? 0 : b.budget)))
                return 1
              else return 0
            })
          })
          break;
        case 'esg':
          this.allData.map((ele: any) => {
            ele['student_summary'].sort((a: any, b: any) => {
              if (JSON.parse(a.esg_score == null ? 0 : a.esg_score) > JSON.parse(b.esg_score == null ? 0 : b.esg_score))
                return -1
              else if ((JSON.parse(a.esg_score == null ? 0 : a.esg_score) < JSON.parse(b.esg_score == null ? 0 : b.esg_score)))
                return 1
              else return 0
            })
          })
          break;
        case 'brandVal':
          this.allData.map((ele: any) => {
            ele['student_summary'].sort((a: any, b: any) => {
              if (+a.brand_value > +b.brand_value)
                return -1
              else if (+a.brand_value < +b.brand_value)
                return 1
              else return 0
            })
          })
          break;



      }
    }
  }
  getCeilValue(val:any){
    let newVal = Math.ceil(val)
    if(newVal <= 0) newVal = 0
    return newVal
  }
}




