<div class="parent_comp_main_container">
    <!-- <app-epg-header></app-epg-header> -->
    <div class="comp_main_container" id="comp_main_container">
        <div class="topBarQueHeader">
            <div><img src="../../../../assets/img/epg/logo_transparent.svg" alt=""></div>
        </div>
        <div class="total_game_play_status_div" *ngIf="displayFlag == 'gameQues'">
            {{questionNumber+1}}/{{trailCompletedFlag?'20':'2'}}
        </div>
        <div class="comp_sub_container" #comp_sub_container id="game_main_cointainer">

            <!-- Game Question And Biding -->
            <div *ngIf="displayFlag == 'gameQues'">

                <!-- Question heading -->
                <div class="question_heading">
                    <div>YEAR {{getYear}}, Q {{getQuestionNum}}</div>
                </div>
                <div class="question_main_div">
                    <!-- round question and biding  -->
                    <div class="question_parent_div">
                        <div class="question_div mobile_hide_class">
                            <!-- Trail Question -->
                            <div *ngIf="!trailCompletedFlag" class="question_txt_div">
                                <!-- <div class="question_num_div">{{questionNumber+1}}</div> -->
                                <div class="question_txt">
                                    <div>
                                        <span>
                                            ESTD has announced that it will price
                                            <b>
                                                {{epgTrialStrategy[questionNumber].state_G?'Low':'High'}}
                                            </b>,
                                        </span>
                                        <br>
                                        <span>
                                            In this quarter costs are
                                            <b>
                                                {{epgTrialStrategy[questionNumber].state_C?'Low':'High'}}
                                            </b>,
                                        </span>
                                        <br>
                                        <span>
                                            Demand is
                                            <b>
                                                {{epgTrialStrategy[questionNumber].state_D?'Low':'High'}}
                                            </b>.
                                        </span>
                                        <br>
                                    </div>
                                    <!-- Previous move -->
                                    <div class="" *ngIf="questionNumber != 0" style="text-align: center;">
                                        Your previous move : <span
                                            style="font-weight: 600;">{{playerPerviousMoveForDisplay == 'High Price' ?
                                            'Enter the market with high price':playerPerviousMoveForDisplay == 'Low
                                            Price' ? 'Enter the market with low price':'Stay out'}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Main Question -->
                            <div *ngIf="trailCompletedFlag" class="question_txt_div">
                                <!-- <div class="question_num_div">{{questionNumber+1}}</div> -->
                                <div class="question_txt">
                                    <div>
                                        <span>
                                            ESTD has announced that it will price
                                            <b>
                                                {{epgMainStrategy[questionNumber].state_G?'Low':'High'}}
                                            </b>,
                                        </span>
                                        <br>
                                        <span>
                                            In this quarter costs are
                                            <b>
                                                {{epgMainStrategy[questionNumber].state_C?'Low':'High'}}
                                            </b>,
                                        </span>
                                        <br>
                                        <span>
                                            Demand is
                                            <b>
                                                {{epgMainStrategy[questionNumber].state_D?'Low':'High'}}
                                            </b>.
                                        </span>
                                        <br>
                                    </div>
                                    <!-- Previous move -->
                                    <div class="" *ngIf="questionNumber != 0" style="text-align: center;">
                                        Your previous move :
                                        <span style="font-weight: 600;">{{playerPerviousMoveForDisplay == 'High Price' ?
                                            'Enter the market with high price':playerPerviousMoveForDisplay == 'Low
                                            Price' ? 'Enter the market with low price':'Stay out'}}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="mobile_show_class mobile_question_div">
                            <!-- Trail Question -->
                            <div *ngIf="!trailCompletedFlag" class="question_txt_div">
                                <!-- <div class="question_num_div">{{questionNumber+1}}</div> -->
                                <div class="question_txt">
                                    <div>
                                        <span>
                                            ESTD has announced that it will price
                                            <b>
                                                {{epgTrialStrategy[questionNumber].state_G?'Low':'High'}},
                                            </b>
                                        </span>
                                        <br>
                                        <span>
                                            In this quarter costs are
                                            <b>
                                                {{epgTrialStrategy[questionNumber].state_C?'Low':'High'}},
                                            </b>
                                        </span>
                                        <br>
                                        <span>
                                            Demand is
                                            <b>
                                                {{epgTrialStrategy[questionNumber].state_D?'Low':'High'}}.
                                            </b>
                                        </span>
                                        <br>
                                    </div>
                                    <!-- Previous move -->
                                    <div class="" *ngIf="questionNumber != 0" style="text-align: center;">
                                        Your previous move : <span
                                            style="font-weight: 600;">{{playerPerviousMoveForDisplay == 'High Price' ?
                                            'Enter the market with high price':playerPerviousMoveForDisplay == 'Low
                                            Price' ? 'Enter the market with low price':'Stay out'}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Main Question -->
                            <div *ngIf="trailCompletedFlag" class="question_txt_div">
                                <!-- <div class="question_num_div">{{questionNumber+1}}</div> -->
                                <div class="question_txt">
                                    <div>
                                        <span>
                                            ESTD has announced that it will price
                                            <b>
                                                {{epgMainStrategy[questionNumber].state_G?'Low':'High'}}
                                            </b>,
                                        </span>
                                        <br>
                                        <span>
                                            In this quarter costs are
                                            <b>
                                                {{epgMainStrategy[questionNumber].state_C?'Low':'High'}}
                                            </b>,
                                        </span>
                                        <br>
                                        <span>
                                            Demand is
                                            <b>
                                                {{epgMainStrategy[questionNumber].state_D?'Low':'High'}}
                                            </b>.
                                        </span>
                                        <br>
                                    </div>
                                    <!-- Previous move -->
                                    <div class="" *ngIf="questionNumber != 0" style="text-align: center;">
                                        Your previous move :
                                        <span style="font-weight: 600;">{{playerPerviousMoveForDisplay == 'High Price' ?
                                            'Enter the market with high price':playerPerviousMoveForDisplay == 'Low
                                            Price' ? 'Enter the market with low price':'Stay out'}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- Mobile Question Image -->
                            <div class="mobile_question_icon_div">
                                <img src="../../../../assets/img/epg/question-icon-mobile.svg" alt=""
                                    style="height: 36px;">
                            </div>
                        </div>

                        <!-- blue Question Image -->
                        <div class="blue_ques_img_div mobile_hide_class">
                            <img src="../../../../assets/img/epg/blue_question_mark.svg" alt="" style="height: 144px;">
                        </div>



                        <!-- Prediction -->
                        <div class="prediction_div">
                            <div class="prediction_wrap_div">
                                <div style="text-align: center;">
                                    <span class="pridiction_heading">Prediction of ESTD's price for this Quarter</span>
                                    <div class="prediction_option_selection_div">
                                        <button class="option_selection_box option_selection_box_prediction"
                                            (click)="handlePredictionSelection('high')"
                                            [ngClass]="pridicteValue == 'high'?'activeBtnDiv':''">High
                                        </button>
                                        <button class="option_selection_box option_selection_box_prediction"
                                            (click)="handlePredictionSelection('low')"
                                            [ngClass]="pridicteValue == 'low'?'activeBtnDiv':''">LOW
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Option Boxes -->
                        <div class="option_selection_div" *ngIf="pridicteValue != 'null'">
                            <div class="option_que_heading">Now what would you like to do ?</div>
                            <button class="option_selection_box" (click)="handleBidSelection('high')"
                                [ngClass]="selectedBid == 'high'?'activeBtnDiv':''">Enter the market with High
                                price</button>
                            <button class="option_selection_box" (click)="handleBidSelection('low')"
                                [ngClass]="selectedBid == 'low'?'activeBtnDiv':''">Enter the market with LOW
                                price</button>
                            <button class="option_selection_box" (click)="handleBidSelection('not')"
                                [ngClass]="selectedBid == 'not'?'activeBtnDiv':''">STAY OUT</button>
                        </div>
                        <!-- Result -->
                        <div class="prediction_div scroll_in_view" id="scroll_in_view"
                            *ngIf="selectedBid != 'null' && pridicteValue != 'null' && result_flag">
                            <div class="border_line"></div>
                            <div class="result_main_div">
                                <span class="result_heading">RESULT</span>
                                <div class="prediction_option_selection_div">
                                    <ul style="color: white;">
                                        <li class="result_li">Actual ESTD’s price: <span
                                                class="result_span">{{result_data.actual_estd_price}}</span></li>
                                        <li class="result_li">Your profit in this quarter: <span class="result_span"
                                                [ngClass]="{'colorRed': result_data.your_profit_in_this_quarter < 0}">$
                                                {{result_data.your_profit_in_this_quarter | number: '1.0-0'}}</span>
                                        </li>
                                        <li class="result_li">Your cumulative profit: <span class="result_span">$
                                                {{result_data.your_cumulative_profit | number: '1.0-0'}}</span></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <!-- Next Btn -->
                        <div class="feedback1_next_btn_main_div next_moblie_btn"
                            *ngIf="selectedBid != 'null' && pridicteValue != 'null && result_flag'">
                            <div (click)="changeQuestion()"
                                [ngClass]="selectedBid == 'null' || pridicteValue == 'null' ? 'btnDeactivate':''">NEXT
                            </div>
                        </div>
                    </div>



                </div>
            </div>


            <!-- 10th Round Question -->
            <!-- 10th Round Question -->
            <div *ngIf="displayFlag == 'feedQue1'" class="feedQue_main_div">
                <div style="" class="feedback_Que_width">
                    <div class="feedQue_heading">Before we proceed to the next Quarter</div>
                    <div>
                        <div *ngFor="let item of midGameFeedbackQues;let i = index;">
                            <!-- Question (MCQ) -->
                            <div class="feedQue_question_div">
                                <div class="feedback_question_icon"><img
                                        src="../../../../assets/img/epg/feedback_question_icon.svg" alt=""></div>
                                <div class="feedback_question_txt">
                                    <div>{{i+1}}] {{item.question}} <span style="color: #FC4754;">&nbsp;*</span></div>
                                </div>
                            </div>
                            <div class="feedQue_option_main_div" *ngIf="item.options.length > 0">
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[0] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[0].id? 'feedback_option_active':''">
                                    <div>a</div>
                                    <div>{{item.options[0].option}}</div>
                                </button>
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[1] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[1].id? 'feedback_option_active':''">
                                    <div>b</div>
                                    <div>{{item.options[1].option}}</div>
                                </button>
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[2] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[2].id? 'feedback_option_active':''">
                                    <div>c</div>
                                    <div>{{item.options[2].option}}</div>
                                </button>
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[3] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[3].id? 'feedback_option_active':''">
                                    <div>d</div>
                                    <div>{{item.options[3].option}}</div>
                                </button>
                            </div>


                            <div style="text-align: center;margin-top: 5px; ;" *ngIf="item.options.length == 0">
                                <form class="example-form">
                                    <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
                                        <mat-label>Type your answer here...</mat-label>
                                        <!-- <textarea matInput #message maxlength="100" placeholder=""> -->
                                        <textarea matInput (input)="textAreaAdjust($event)"
                                            (input)="feedbackTextAreaHandel($event,item)" class="feedback_txt_area_field"></textarea>
                                    </mat-form-field>
                                </form>
                            </div>
                            <!-- Border -->
                            <div class="border_line" *ngIf="midGameFeedbackQues[i+1]"></div>
                        </div>
                        <!-- Next Btn -->
                        <div class="feedback1_next_btn_main_div">
                            <div (click)="firstFeedBackRound()"
                                [ngClass]="firstFeedBackOptionNextBtnFlag ? '':'btnDeactivate'">NEXT</div>
                        </div>

                    </div>
                </div>
            </div>


            <!-- 20th Round Question -->
            <!-- 20th Round Question -->
            <div *ngIf="displayFlag == 'feedQue2'" class="feedQue_main_div">
                <div class="feedback_Que_width">
                    <div class="feedQue_heading">Before we proceed to analyse your decisions</div>
                    <div>

                        <!-- 1st Question (MCQ) -->
                        <div *ngFor="let item of finalGameFeedbackQues;let i = index;">
                            <!-- 1st Question (MCQ) -->
                            <div class="feedQue_question_div">
                                <div class="feedback_question_icon"><img
                                        src="../../../../assets/img/epg/feedback_question_icon.svg" alt=""></div>
                                <div class="feedback_question_txt">{{i+1}}] {{item.question}} <span
                                        style="color: #FC4754;">&nbsp;*</span></div>
                            </div>
                            <div class="feedQue_option_main_div" *ngIf="item.options.length > 0">
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[0] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[0].id? 'feedback_option_active':''">
                                    <div>a</div>
                                    <div>{{item.options[0].option}}</div>
                                </button>
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[1] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[1].id? 'feedback_option_active':''">
                                    <div>b</div>
                                    <div>{{item.options[1].option}}</div>
                                </button>
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[2] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[2].id? 'feedback_option_active':''">
                                    <div>c</div>
                                    <div>{{item.options[2].option}}</div>
                                </button>
                                <button class="feedQue_option_sub_div"
                                    (click)="feedbackOptionHandel(item.options[3] , item)"
                                    [ngClass]="feedBackData[item.id]?.id == item.options[3].id? 'feedback_option_active':''">
                                    <div>d</div>
                                    <div>{{item.options[3].option}}</div>
                                </button>
                            </div>


                            <div style="text-align: center;margin-top: 5px; ;" *ngIf="item.options.length == 0">
                                <form class="example-form">
                                    <mat-form-field class="example-full-width" appearance="fill" style="width: 100%;">
                                        <mat-label>Type your answer here...</mat-label>
                                        <!-- <textarea matInput #message maxlength="100" placeholder=""> -->
                                        <textarea matInput (input)="textAreaAdjust($event)"
                                            (input)="feedbackTextAreaHandel($event,item)" class="feedback_txt_area_field"></textarea>
                                    </mat-form-field>
                                </form>
                            </div>
                            <!-- Border -->
                            <div class="border_line" *ngIf="midGameFeedbackQues[i+1]"></div>
                        </div>

                        <!-- Next Btn -->
                        <div class="feedback1_next_btn_main_div">
                            <div (click)="secondFeedbackQuestion()"
                                [ngClass]="secondFeedBackOptionNextBtnFlag ? '':'btnDeactivate'">NEXT</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Trails round popup -->
<div *ngIf="displayFlag == 'trail'" class="pop_up_absoulute">
    <div class="trail_round_main_div">
        <div class="pop_up_abs_txt">
            Before beginning the real assignment, you will play two trial rounds
            against an imaginary firm to familiarise you with the procedure before the actual assignment begins.
            <br> <br>
            Note that the trial periods are only for practice and need not reveal anything about what strategy you
            should use in the actual assignment.
        </div>
        <button (click)="startTrail()" class="trail_btn">PLAY TRIAL</button>
    </div>
</div>

<!-- Main round popup -->
<div *ngIf="displayFlag == 'mainPopup'" class="pop_up_absoulute">
    <div class="trail_round_main_div">
        <div class="pop_up_abs_txt">
            Trial rounds are completed. Click "Play" to start the game..
        </div>
        <button (click)="startGame()" class="trail_btn">PLAY</button>
    </div>
</div>