import { Component, Inject, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DeleteDialogueComponentComponent } from '../../commonComponants/delete-dialogue-component/delete-dialogue-component.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/https/http.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@Component({
  selector: 'app-game-configure',
  templateUrl: './game-configure.component.html',
  styleUrls: ['./game-configure.component.css']
})
export class GameConfigureComponent implements OnInit {

  constructor(
    private router: Router,
    private httpSv: HttpService,
    private toster: ToastrService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
	this.userId =  sessionStorage.getItem('user_id')
	if(!this.userId){
	 this.router.navigate(['/login']);
	 this.toster.warning("Something went wrong. Please Login Again",'',{timeOut:2000})
	}
    this.getInstructorGameList();
	// this.configureGame()
    
  }
  userId:any;
  
  selectedFilter:any='';
  displayedColumns: string[] = ['sl_no', 'game_type', 'game_name', 'game_code', 'instructor_name', 'university_code', 'email', 'status','remove'];
  dataBM: any[] = []
  data:any;
  dataSource: any = new MatTableDataSource();
  filterByStatus(data:any){
	this.selectedFilter=data==1?'ESG':'EPG'
	if(data=='all')
		{
			this.dataSource = this.data
			this.selectedFilter='all'

		}
	else{
	let tempdata:any[]=[];
    this.data.map((res:any)=>{
      if(res.game_type==data)
        tempdata.push(res)
    })
    this.dataSource=tempdata
	}
  }
  applyFilter(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  confirmDelete(element:any){
    const dialogRef = this.dialog.open(DeleteDialogueComponentComponent, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        heading:"Are you sure you want to remove the Configuration?"
      }
    })
	dialogRef.afterClosed().subscribe((res:any)=>{
		const data ={
		  instructor_id: element.instructor_id,
		  game_id: element.game_id
		}
		if(res){
		  this.httpSv.deleteConfiguredGame(data).subscribe((res: any) => {
		  if (res['status']) {
			this.toster.success('Configuration Deleted successfully', '', { timeOut: 2000 })
			this.getInstructorGameList()
		  }
		  else {
			this.toster.error(res.error[0], '', { timeOut: 2000 })
		  }
		  }, (err) => {
			this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
		  })
		}
	  })
  }

  getInstructorGameList(){
    this.httpSv.getInstructorGameList().subscribe((res: any) => {
      if (res['status']) {
        this.dataSource.data = res['results'];
        this.data = res['results'];
      }
      else {
        this.toster.error(res.error[0], '', { timeOut: 2000 })
      }
    }, (err) => {
      this.dataSource.data = [];
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }

  gameInactiveToast(element:any){
	this.toster.warning(`Please, first activate the game ${element.game_code}`, '', { timeOut: 2500})
  }

  setStatus(status:any, element:any){
    const data = {
      instructor_id: element.instructor_id,
      game_id: element.game_id,
      status
    }

    this.httpSv.updateConfigGameStatus(data).subscribe((res:any) => {
      if (res['status']) {
        const status = data.status == 'active' ? "actived" : "inactived";
        this.toster.success(`Game ${element.game_code} is ${status} for ${element.instructor_name}`, '', { timeOut: 2000 })
        this.getInstructorGameList();
      }
      else {
        this.toster.error(res.error[0], '', { timeOut: 2000 })
      }
    }, (err) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }  

  configureGame(){
    const dialogRef = this.dialog.open(DialogueConfigureGame, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        heading:"Are you sure you want to remove the Configuration?"
      }
    });
	dialogRef.afterClosed().subscribe((res:any) => {
		if(res){
			this.getInstructorGameList();
		}
	})

  }
}












@Component({
	selector: 'app-dialogueAddQuestion-game',
	templateUrl: './dialogue.configureGame.component.html',
	styleUrls: ['./game-configure.component.css']
})
export class DialogueConfigureGame implements OnInit {
	constructor(
		public dialogRef: MatDialogRef<DialogueConfigureGame>,
		@Inject(MAT_DIALOG_DATA) public data: any,
		private toaster:ToastrService,
		private httpSv: HttpService,
	){

	}
	ngOnInit(): void {
			// this.getInstructorsList();
		this.getGameList();

	} 
	color: any = '#005586';
	checked:boolean = false;
	disabled:boolean = false;
	show:boolean=false;
	src:any=''
	fileData:any;
	status:any = '';
	selectedInstructor:any;
	instructorName: any = '';
	instructorEmail: any = '';
	selectedGame: any;
	instructorList:any;
	gameList: any;
	universityName:any;
	universityDesc:any

	getInstuctorsToConfigGame(){
		this.httpSv.getInstuctorsToConfigGame(this.selectedGame.game_id).subscribe((res: any) => {
			if (res['status']) {
				var instructorList: any = [];
				for(var i = 0; i < res.result.length; i++){
					instructorList = [...instructorList, {instructor_id: res.result[i].instructor_id, university_code:res.result[i].university_code, email: res.result[i].email, name: res.result[i].name, label:`${res.result[i].university_code} | ${res.result[i].email}`}]
				}
				this.instructorList = instructorList;
			}
			else {
			this.toaster.error(res.error[0], '', { timeOut: 2000 })
			}
		}, (err) => {
			this.toaster.error('Oops! Something went wrong', '', { timeOut: 2000 })
		})
	}

	getGameList(){
		this.httpSv.getGameList().subscribe((res: any) => {
			if (res['status']) {
				let activeGame: any = [];
				res.data.forEach(element => {
					console.log(element)
					if(element.status == 'active'){
						activeGame = [...activeGame, element]
					}
				});
				var gameList: any = [];
				for(var i = 0; i < activeGame.length; i++){
					gameList = [...gameList, {game_id: activeGame[i].id, game_code: activeGame[i].game_code, game_type: activeGame[i].game_type, name: activeGame[i].game_name, label:`${activeGame[i].game_code} | ${activeGame[i].game_name}`}]
				}
				this.gameList = gameList;
			}
			else {
				this.toaster.error(res.error[0], '', { timeOut: 2000 })
			}
		}, (err) => {
			this.toaster.error('Oops! Something went wrong', '', { timeOut: 2000 })
		})
	}

	checkIsGameSelected(){
		if(this.selectedGame == undefined){
			this.toaster.warning('Please, select a game first!!!', '', { timeOut: 2000 });
		}
	}

	selectInstructor(instructor: any){
		if(this.selectedGame != undefined){
			this.selectedInstructor = instructor;
			this.instructorName = instructor.name;
			this.instructorEmail = instructor.email;
		} else{
			this.toaster.success('Game configured successfully.', '', { timeOut: 2000 });
		}
	}

	selectGame(game: any){
		this.selectedGame = game;
		this.getInstuctorsToConfigGame();
	}

	close(){
		
		this.dialogRef.close(true)
	}

	save(){

		if(this.selectedInstructor != undefined){
			if(this.selectedGame != undefined){
				if(this.status != ''){
					if((this.show == true && this.universityDesc) || this.show == false){

						const data = new FormData();
						data.append('instructor_id', this.selectedInstructor.instructor_id)
						data.append('game_id', this.selectedGame.game_id)
						data.append('university_code', this.selectedInstructor.university_code)
						data.append('game_code', this.selectedGame.game_code)
						data.append('game_type', this.selectedGame.game_type)
						data.append('status', this.status)
						data.append('toggle', JSON.stringify(this.show))
						data.append('univ_name',this.universityName)
						data.append('univ_logo', this.fileData)
						data.append('univ_desc', this.universityDesc)
						
						this.httpSv.configureGame(data).subscribe((res:any) => {
							if(res['status']){
								this.toaster.success('Game configured successfully.', '', { timeOut: 2000 });
								this.dialogRef.close(true)
							}
						})
					} else {
						this.toaster.warning('Please enter introduction description text.', '', { timeOut: 2000 })
					}
				} else {
					this.toaster.warning('Please select game status.', '', { timeOut: 2000 })
				}
			} else {
				this.toaster.warning('Please select game.', '', { timeOut: 2000 })
			}
		} else {
			this.toaster.warning('Please select instructor.', '', { timeOut: 2000 })
		}

	}
	setStatus(data:any){
		this.status=data
	}
	change(event:any){
		this.show = event.source.checked;   

	}
  onImageChange(event: any) {
    let file: File = event.target.files[0];
    this.fileData = file
    let reader: FileReader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e: any) => {
      this.src = e.target.result;
    };
	console.log(event,this.fileData)
    const fileDom = document.getElementById('file_inp') as HTMLInputElement
    if (fileDom) {
      fileDom.value = ''
    }
  }
}