<div>
  <nav class="navbar bg-body-tertiary">
    <div class="container">
      <img src="../../../assets/img/esg-logo.svg" alt="Bootstrap">
      <img src="../../../assets/img/logout-3-svgrepo-com.svg" alt="" style="max-width: 35px; cursor: pointer;"
        data-toggle="tooltip" data-placement="top" title="Logout">
    </div>
  </nav>

  <div class="container">

    <div *ngIf="tableFlag">
      <mat-form-field>
        <input matInput (keyup)="applyFilter($event)" placeholder="Filter by name" #input
          class="form-control filter-inp">
      </mat-form-field>

      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

        <!-- SL NO Column -->
        <ng-container matColumnDef="sl_no">
          <th mat-header-cell class="table_header" *matHeaderCellDef> No. </th>
          <td mat-cell *matCellDef="let element; let i = index"> {{i+1}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell class="table_header" *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>

        <!-- Status Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell class="table_header" *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element"> {{element.report_status}} </td>
        </ng-container>

        <!-- PDF Download Column -->
        <ng-container matColumnDef="download">
          <th mat-header-cell class="table_header" *matHeaderCellDef> Download Pdf </th>
          <td mat-cell *matCellDef="let element"> <img src="../../../assets/img/file-download-svgrepo-com.svg" alt=""
              style="max-width: 35px; cursor: pointer;" (click)="genPDF(element)"> </td>
        </ng-container>

        <!-- PDF Upload Column -->
        <!-- <ng-container matColumnDef="upload">
          <th mat-header-cell class="table_header" *matHeaderCellDef> Upload Pdf </th>
          <td mat-cell *matCellDef="let element; let i = index">
            <input type="file" accept=".pdf" (change)="pdfUploadHandler($event,i)" #fileInput_pdf hidden id="fileUploadInp"/>
            <img src="../../../assets/img/file-send-svgrepo-com.svg" alt="" style="max-width: 35px; cursor: pointer;"
              *ngIf="allPdfUploade[i] == null" (click)="fileInput_pdf.click()">
            <img src="../../../assets/img/pdf-file-svgrepo-com.svg" alt="" style="max-width: 35px;"
              *ngIf="allPdfUploade[i] != null">
            <img src="../../../assets/img/cross-svgrepo-com.svg" alt="" style="max-width: 15px; cursor: pointer;"
              data-toggle="tooltip" data-placement="top" title="Delete this pdf" *ngIf="allPdfUploade[i] != null" (click)="deletePdf(i)">
          </td>
        </ng-container> -->

        <!-- Send vai email -->
        <ng-container matColumnDef="send">
          <th mat-header-cell class="table_header" *matHeaderCellDef> Send report </th>
          <td mat-cell *matCellDef="let element;let i = index">
            <!-- <img src="../../../assets/img/send-file-1-svgrepo-com.svg" alt="" style="max-width: 35px; cursor: pointer;"
              *ngIf="allPdfUploade[i] != null" (click)="sendEmail(i)">
            <img src="../../../assets/img/send-send-gray-1-svgrepo-com.svg" alt=""
              style="max-width: 35px; cursor: not-allowed;" *ngIf="allPdfUploade[i] == null"> -->
              <img src="../../../assets/img/send-file-1-svgrepo-com.svg" alt="" style="max-width: 35px; cursor: pointer;"
              (click)="sendEmail(i)">
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <!-- Row shown when there is no matching data. -->
        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="4" style="text-align: center;font-size: 17px;">
            No data matching found for "<b>{{input.value}}</b>"
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [pageSizeOptions]="[15, 20, 25]" showFirstLastButtons></mat-paginator>
    </div>

  </div>
  <div *ngIf="!tableFlag"
    style="position: absolute; width: 100vw;top: 50%; text-align: center;font-size: 20px; font-weight: 500;">
    No Requested Data Found
  </div>
</div>