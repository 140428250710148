<div class="err_dialog_main_div">
    <div class="err_dialog_text" *ngIf="headingFlag && !adminFlag">
        You are allowed to create up to four EPG strategies
    </div>
    <div class="err_dialog_text" *ngIf="!headingFlag && !adminFlag">
        You are not allowed edit strategy of running game
    </div>
    <div class="err_dialog_text" *ngIf="headingFlag && adminFlag">
        Implementing this modification will bring a stop to all ongoing games utilizing this strategy.
        <br />
        <br />
        Are you certain about making this edit?
    </div>

    <div class="err_dialog_btn_div" *ngIf="!adminFlag">
        <button (click)="close()">OK</button>
    </div>
    <div class="err_dialog_btn_div" *ngIf="adminFlag">
        <button (click)="close()" class="cancel_btn">cancel</button>
        <button (click)="save()">OK</button>
    </div>
</div>
<style>
    .err_dialog_main_div {
        width: 580px;
        text-align: center;
        background-color: #fff;
        padding: 2rem;
        border-radius: 10px;
    }

    .err_dialog_text {
        color: #1D1E1C;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Avenir;
        font-size: 19px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.1px;
    }

    .err_dialog_btn_div {
        display: flex;
        gap: 20px;
        justify-content: center;
    }

    .err_dialog_btn_div button {
        border-radius: 8px;
        background: linear-gradient(90deg, #0D8BBC 3.7%, #20AAE0 100%);
        border: none;
        color: #FFF;
        font-family: Avenir;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
        letter-spacing: 1.28px;
        padding: 0.5rem 2rem;
        margin-top: 1rem;
    }

    .cancel_btn {
        background: none !important;
        color: red !important;
        padding: 0 !important;
    }
</style>