<div class="wrapper">
    <div class="spinners_main_div" *ngIf="showSpinnerFlag">
        <div class="spinner-border text-light" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>
    <div class="close_icon_main_div">
        <img src="../../../assets/img/close-md-svgrepo-com.svg" alt="" (click)="close()">
    </div>
    <!-- <div class="close_icon_main_div" style="left: 56%;">
        <img src="../../../assets/img/epg/download_svg.svg" alt="" (click)="convetToPDF('aa')">
    </div> -->
    <div class="container" id="contentToConvert">
        <div class="header">
            <div class="headerLogo">
                <img src="../../../assets/img/epg/logo.svg">
                <p><b style="font-size: 24px;">Entry Pricing Game</b></p>
            </div>
            <div class="headerName">
                <p><b>Name : </b>{{reportApiData.player_name}}</p>
                <p><b>Date of session : </b>{{DateInLocal | date:' d MMMM yyyy'}}</p>
                <p style="text-align: end;margin-right: 10px;"><b>Class Rank : </b>{{reportApiData.class_rank}}</p>
            </div>
        </div>
        <div class="containerBody">
            <div class="report_quik_info_main_div">
                <div class="grid_2">
                    <div class="report_qi_sub_div">
                        <p>My overall profit</p>
                        <p>{{reportApiData.overall_profit | number: '1.0-0'}}</p>
                    </div>
                    <div class="report_qi_sub_div">
                        <p>Class average </p>
                        <p>{{reportApiData.class_average | number: '1.0-0'}}</p>
                    </div>
                </div>
                <div class="grid_2">
                    <div class="report_qi_sub_div">
                        <p>ESTD’s strategy for first half</p>
                        <p>{{reportApiData.estd_strategy_for_first_half}}</p>
                    </div>
                    <div class="report_qi_sub_div">
                        <p>My inference</p>
                        <p>{{reportApiData.first_half_prediction}}</p>
                    </div>
                </div>
                <div class="grid_2">
                    <div class="report_qi_sub_div">
                        <p>ESTD’s strategy for second half</p>
                        <p>{{reportApiData.estd_strategy_for_second_half}}</p>
                    </div>
                    <div class="report_qi_sub_div">
                        <p>My inference</p>
                        <p>{{reportApiData.second_half_prediction}}</p>
                    </div>
                </div>

            </div>
            <div class="report_prediction">
                <p>My price prediction accuracy for ESTD’s pricing -
                    <span
                        style="color: red;font-size: 18px;">{{reportApiData.price_prediction_accuracy_for_estd_pricing}}%</span>
                </p>
            </div>
            <div class="report_quik_info_main_div" style="padding: 0;">
                <div class="table_heading_main_div">
                    <p>My performance in every Quarter</p>
                </div>
                <div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">Quarter</th>
                                <th scope="col">Announcement</th>
                                <th scope="col">Costs</th>
                                <th scope="col">Demand</th>
                                <th scope="col">Your previous move</th>
                                <th scope="col">ESTD’s price</th>
                                <th scope="col">UPSTART’s price</th>
                                <th scope="col">UPSTART’s profit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of tempArr.slice(0,15);let i = index"
                                [ngClass]="i%2 == 0? 'table_dark':'table_light'">
                                <th scope="row">{{i+1}}</th>
                                <td>ESTD has announced that it will price {{item.state_G?'Low':'High'}}</td>
                                <td>{{item.state_C ? 'Low' : 'High'}}</td>
                                <td>{{item.state_D ? 'Low' : 'High'}}</td>
                                <td> {{item.prev_move == 0 ? 'High': item.prev_move == 1 ? 'Low': item.prev_move ==
                                    2?'Stayed out':'N/A' }}</td>
                                <td>{{item.estd_price == 500 ? 'High' : 'Low'}}</td>
                                <td> {{item.upstart_price?'Low':'High'}}</td>
                                <td [innerHTML]="getUpstartProfit(item.upstart_profit)"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 10px;" id="contentToConvert2">
        <div class="header">
            <div class="headerLogo">
                <img src="../../../assets/img/epg/logo.svg">
                <p><b style="font-size: 24px;">Entry Pricing Game</b></p>
            </div>
        </div>
        <div class="secondPage">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th scope="col">Quarter</th>
                        <th scope="col">Announcement</th>
                        <th scope="col">Costs</th>
                        <th scope="col">Demand</th>
                        <th scope="col">Your previous move</th>
                        <th scope="col">ESTD’s price</th>
                        <th scope="col">UPSTART’s price</th>
                        <th scope="col">UPSTART’s profit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of tempArr.slice(15);let i = index"
                        [ngClass]="i%2 == 0? 'table_dark':'table_light'">
                        <th scope="row">{{i+16}}</th>
                        <td>ESTD has announced that it will price {{item.state_G?'Low':'High'}}</td>
                        <td>{{item.state_C ? 'Low' : 'High'}}</td>
                        <td>{{item.state_D ? 'Low' : 'High'}}</td>
                        <td> {{item.prev_move == 0 ? 'High': item.prev_move == 1 ? 'Low': item.prev_move ==
                            2?'Stayed out':'N/A' }}</td>
                        <td>{{item.estd_price == '500' ? 'High' : 'Low'}}</td>
                        <td> {{item.upstart_price?'Low':'High'}}</td>
                        <td [innerHTML]="getUpstartProfit(item.upstart_profit)"></td>
                    </tr>
                </tbody>
            </table>
            <div>
                <div *ngFor="let item of reportApiData.feedback_qa.slice(0,3);let i = index"
                    class="report_que_main_div">
                    <div class="report_que_sub_div">
                        <span>{{i+1}} </span>
                        <span>{{item.question}}</span>
                    </div>
                    <div class="report_que_response_div">
                        <div>My Response :</div>
                        <div>{{item.player_answer}}</div>
                    </div>
                    <div *ngIf="item.instructor_answer != null" class="report_que_comment_div">
                        <div>Instructor's view :</div>
                        <div>{{item.instructor_answer}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" style="margin-top: 10px;" id="contentToConvert3">
        <div class="header">
            <div class="headerLogo">
                <img src="../../../assets/img/epg/logo.svg">
                <p><b style="font-size: 24px;">Entry Pricing Game</b></p>
            </div>
        </div>
        <div class="secondPage">
            <div style="background: white;">
                <div *ngFor="let item of reportApiData.feedback_qa.slice(3);let i = index" class="report_que_main_div">
                    <div class="report_que_sub_div">
                        <span>{{i+4}} </span>
                        <span>{{item.question}}</span>
                    </div>
                    <div class="report_que_response_div">
                        <div>My Response :</div>
                        <div>{{item.player_answer}}</div>
                    </div>
                    <div *ngIf="item.instructor_answer != null" class="report_que_comment_div">
                        <div>Instructor's view :</div>
                        <div>{{item.instructor_answer}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>


</div>