import { Component, ElementRef, Inject, OnInit, Renderer2 } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpService } from 'src/app/services/https/http.service';

@Component({
  selector: 'app-ins-epg-list',
  templateUrl: './ins-epg-list.component.html',
  styleUrls: ['./ins-epg-list.component.css']
})
export class InsEpgListComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.instructor_id = sessionStorage.getItem('user_id')
    if (this.instructor_id) {
      this.getAllStrategyList()
    } else {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      this.router.navigate(['/login'])
    }
  }
  instructor_id: any
  epgData: any[] = [];
  displayedColumns: string[] = ['sl_no', 'strategyName', 'viewFeedback', 'viewStrategy', 'modify', 'duplicate', 'action', 'gameCode'];
  dataSource: any = new MatTableDataSource();
  viewFeedback(element: any) {
    const dialogRef = this.dialog.open(InsFeedbackDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        ins_id: this.instructor_id,
        strategy_id: element.id
      }
    })
  }
  viewStrategy(element: any) {
    const dialogRef = this.dialog.open(InsStrategyDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        viewOnly: true,
        strategyData: element
      }
    })
  }
  createStrategy() {
    const dialogRef = this.dialog.open(InsStrategyDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        viewOnly: false,
        ins_id: this.instructor_id
      }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getAllStrategyList()
      }
    })
  }
  editStrategy(flag: boolean, data: any) {
    if (flag) {
      return
    }
    else if (data.status == 'running') {
      this.dialog.open(InsStrategyErrorDialog, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          fromEdit: true
        }
      })
      return
    }
    const dialogRef = this.dialog.open(InsStrategyDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        viewOnly: true,
        editStategy: true,
        strategyData: data,
        ins_id: this.instructor_id
      }
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.getAllStrategyList()
      }
    })
  }
  copyStrategy(data: any) {
    if (this.addMoreStrategyFlag) {
      const dialogRef = this.dialog.open(InsStrategyCopyDialog, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          strategyData: data,
          ins_id: this.instructor_id
        }
      })
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          this.getAllStrategyList()
        }
      })
    }
    else {
      const dialogRef = this.dialog.open(InsStrategyErrorDialog, {
        disableClose: true,
        hasBackdrop: true,
        data: {
          fromEdit: false
        }
      })
    }
  }
  gameStatusFlag: boolean = false
  addMoreStrategyFlag: boolean = false
  getAllStrategyList() {
    this.addMoreStrategyFlag = false
    this.httpSv.getInsStrategy(this.instructor_id).subscribe((res: any) => {
      if (res['status']) {
        this.dataSource.data = res['results'].game_data
        this.epgData = res['results'].game_data
        this.gameStatusFlag = res['results'].game_flag
        if (res['results'].game_data.length < 4) {
          this.addMoreStrategyFlag = true
        }
      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })
  }
  startGame(condition: boolean, element: any) {
    let flag = false;
    this.epgData.map((res: any) => {
      if (res.status == 'running') {
        flag = true
      }
    })
    if (flag)
      return
    const epochNow = Math.floor(new Date().getTime() / 1000);
    let data = {
      instructor_id: this.instructor_id,
      game_id: 1,
      start_time: epochNow.toString(),
      strategy_id: element.id
    }
    this.httpSv.instructorStartGame(data).subscribe((res: any) => {
      if (res['status']) {
        this.getAllStrategyList()
      } else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error(err.error.message, '', { timeOut: 2000 })
    })
  }
  stopGame(element: any) {
    const dialogRef = this.dialog.open(InsPlayerCountDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        session_code: element.session_code,
      }
    })
    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        const epochNow = Math.floor(new Date().getTime() / 1000);
        let data = {
          session_code: element.session_code,
          end_time: epochNow.toString()
        }
        this.httpSv.instructorStopGame(data).subscribe((res: any) => {
          if (res['status']) {
            this.getAllStrategyList()
          } else {
            this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
          }
        }, (err: any) => {
          this.toster.error(err.error.message, '', { timeOut: 2000 })
        })
      }
    })
  }
}




// Strategy Main Dialog Box 
// Strategy Main Dialog Box 
// Strategy Main Dialog Box 
// Strategy Main Dialog Box 

@Component({
  selector: 'app-ins-epg-strategy',
  templateUrl: './ins-strategy-dialog.html',
  styleUrls: ['./ins-epg-list.component.css']
})
export class InsStrategyDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InsStrategyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService,
    private dialog: MatDialog,
    private el: ElementRef,
    private renderer: Renderer2
  ) { }
  ngOnInit(): void {
    const numberInputs = this.el.nativeElement.querySelectorAll('input[type=number]');

    numberInputs.forEach((input: any) => {
      input.addEventListener('touchmove', (event) => {
        event.preventDefault(); // Prevent the default scrolling behavior
        input.blur();
      });
    });
    if (this.data.viewOnly) {
      this.strategyData = this.data.strategyData.strategy_values
      this.strategyApiData = this.data.strategyData
      this.strategyName = this.strategyApiData.name
      this.strategyDescription = this.strategyApiData.description
      this.strategyFirstHalf = this.strategyApiData.first_half
      this.strategySecondHalf = this.strategyApiData.second_half
      this.indentifierChecked = this.strategyApiData.is_identifer_enabled ? true : false

      if (this.data?.editStategy) {
        this.viewOnlyFlag = false
        this.heading = 'Edit Strategy'
      } else {
        this.viewOnlyFlag = true
        this.heading = 'View Strategy'
      }
    } else {
      this.heading = 'Create New Strategy'
      this.viewOnlyFlag = false
    }
  }
  heading: string = ''
  viewOnlyFlag
  strategyName: any
  strategyDescription: any
  strategyFirstHalf: any
  strategySecondHalf: any
  strategyData: any[] = Array.from({ length: 20 }, () => ({
    coefficient_matrix_G: null,
    coefficient_matrix_C: null,
    coefficient_matrix_D: null,
    coefficient_matrix_M: null,
    state_G: null,
    state_C: null,
    state_D: null,
  }));
  strategyApiData: any = []
  errorIndex: any
  indentifierChecked:boolean = false
  close() {
    this.dialogRef.close(false)
  }
  saveStrategy() {
    if (this.strategyName && this.strategyName != '') {
      if (this.strategyDescription && this.strategyDescription != '') {
        if (this.strategyFirstHalf && this.strategyFirstHalf != '') {
          if (this.strategySecondHalf && this.strategySecondHalf != '') {
            let validationFlag = false
            for (let i = 0; i < this.strategyData.length; i++) {
              if (+this.strategyData[i].coefficient_matrix_G + +this.strategyData[i].coefficient_matrix_C + +this.strategyData[i].coefficient_matrix_D + +this.strategyData[i].coefficient_matrix_M == 100) {
                if (this.strategyData[i].coefficient_matrix_G == null || this.strategyData[i].coefficient_matrix_C == null || this.strategyData[i].coefficient_matrix_D == null || this.strategyData[i].coefficient_matrix_M == null) {
                  this.errorIndex = i
                  document.getElementById('matrix' + i)?.scrollIntoView(false)
                  this.toster.info(`All column are required`, '', { timeOut: 2000 })
                  validationFlag = false
                  break
                } else {
                  validationFlag = true
                  continue
                }
              }
              else {
                this.errorIndex = i
                document.getElementById('matrix' + i)?.scrollIntoView(false)
                this.toster.info(`Sum of strategy row ${i + 1} must be equal to 100`, '', { timeOut: 2000 })
                validationFlag = false
                break
              }
            }
            if (validationFlag) {
              if (this.data.byAdmin == true) {
                let data = {
                  name: this.strategyName,
                  description: this.strategyDescription,
                  first_half: this.strategyFirstHalf,
                  second_half: this.strategySecondHalf,
                  strategy_values: this.strategyData,
                  indentifier: this.indentifierChecked ? 1 : 0
                }
                if (this.heading == 'Edit Strategy') {
                  data['strategy_id'] = this.strategyApiData.id
                }
                const dialogRef = this.dialog.open(InsStrategyErrorDialog, {
                  disableClose: true,
                  hasBackdrop: true,
                  data: {
                    fromAdmin: true
                  }
                })
                dialogRef.afterClosed().subscribe((result: boolean) => {
                  if (result) {
                    this.httpSv.createModifyStrategyAdmin(data).subscribe((res: any) => {
                      if (res['status']) this.dialogRef.close(true)
                      else this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
                  }
                })
              }
              else {
                let data = {
                  instructor_id: this.data.ins_id,
                  name: this.strategyName,
                  description: this.strategyDescription,
                  first_half: this.strategyFirstHalf,
                  second_half: this.strategySecondHalf,
                  strategy_values: this.strategyData,
                  indentifier: this.indentifierChecked ? 1 : 0
                }
                if (this.heading == 'Edit Strategy') {
                  data['strategy_id'] = this.strategyApiData.id
                }
                this.httpSv.createModifyStrategy(data).subscribe((res: any) => {
                  if (res['status']) this.dialogRef.close(true)
                  else this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
              }
            }
            else return
          } else {
            this.toster.info('Please enter strategy for second half', '', { timeOut: 2000 })
          }
        } else {
          this.toster.info('Please enter strategy for first half', '', { timeOut: 2000 })
        }
      } else {
        this.toster.info('Please enter strategy description', '', { timeOut: 2000 })
      }
    } else {
      this.toster.info('Please enter strategy name', '', { timeOut: 2000 })
    }
  }
  maxtrixKeyDown(index: any) {
    if (this.errorIndex != null) {
      if (this.errorIndex == index) {
        this.errorIndex = null
      }
    } else return
  }
  allowOnlyDigits(event: any) {
    const allowedKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8 /* Backspace */, 46 /* Delete */, 37 /* Left Arrow */, 39 /* Right Arrow */];
    const keyCode = event.which || event.keyCode;
    // Check if the key is allowed (0 to 9, Backspace, Delete)
    if (allowedKeys.indexOf(keyCode) === -1) {
      event.preventDefault();
    }
  }
  allowOnlyBinary(event: any) {
    const allowedKeys = [48, 49, 8 /* Backspace */, 46 /* Delete */, 37 /* Left Arrow */, 39 /* Right Arrow */];
    const keyCode = event.which || event.keyCode;
    // Check if the key is allowed (0 to 9, Backspace, Delete)
    if (allowedKeys.indexOf(keyCode) === -1) {
      event.preventDefault();
    }
  }
  handleInput(event: any) {
    const inputValue = (event.target as HTMLInputElement).value;

    // If the input exceeds one digit, truncate it
    if (inputValue.length > 1) {
      (event.target as HTMLInputElement).value = inputValue.charAt(0);
    }
  }
}
// Strategy Copy Dialog 
// Strategy Copy Dialog 
// Strategy Copy Dialog 
// Strategy Copy Dialog 
@Component({
  selector: 'app-ins-epg-strategy-copy',
  templateUrl: './ins-strategy-copy-dialog.html',
  styleUrls: ['./ins-epg-list.component.css']
})
export class InsStrategyCopyDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InsStrategyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }
  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close(false)
  }
  strategyName: any
  strategyDescription: any
  saveCopy() {
    if (this.strategyName && this.strategyName != '') {
      if (this.strategyDescription && this.strategyDescription != '') {
        let data = {
          instructor_id: this.data.ins_id,
          name: this.strategyName,
          description: this.strategyDescription,
          first_half: this.data.strategyData.first_half,
          second_half: this.data.strategyData.second_half,
          strategy_values: this.data.strategyData.strategy_values,
          indentifier: this.data.strategyData.is_identifer_enabled ? 1 : 0
        }
        this.httpSv.createModifyStrategy(data).subscribe((res: any) => {
          if (res['status']) this.dialogRef.close(true)
          else this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
        }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
      } else {
        this.toster.info('Please enter strategy description', '', { timeOut: 2000 })
      }
    } else {
      this.toster.info('Please enter strategy name', '', { timeOut: 2000 })
    }
  }
}
// Strategy Error Message Dialog
// Strategy Error Message Dialog 
// Strategy Error Message Dialog 
// Strategy Error Message Dialog 
@Component({
  selector: 'app-ins-epg-strategy-error',
  templateUrl: './ins-strategy-error-dialog.html',
  styleUrls: ['./ins-epg-list.component.css']
})
export class InsStrategyErrorDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InsStrategyDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }
  ngOnInit(): void {
    if (this.data.fromEdit)
      this.headingFlag = !this.data.fromEdit
    else this.adminFlag = this.data.fromAdmin
  }
  headingFlag: boolean = true
  adminFlag: boolean = false
  close() {
    this.dialogRef.close(false)
  }
  save() {
    this.dialogRef.close(true)
  }
}

// Instructor feedback dialog
// Instructor feedback dialog
// Instructor feedback dialog
// Instructor feedback dialog
@Component({
  selector: 'app-ins-epg-feedback',
  templateUrl: './ins-feedback-count-dialog.html',
  styleUrls: ['./ins-epg-list.component.css']
})
export class InsFeedbackDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InsFeedbackDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }
  textAreaAdjust(event: Event): void {
    const element = event.target as HTMLTextAreaElement;
    element.style.height = '0.5px';
    element.style.height = (1 + element.scrollHeight) + 'px';

  }
  ngOnInit(): void {
    this.ins_id = this.data.ins_id
    this.strategy_id = this.data.strategy_id
    this.getAllFeedBackQuestion()
  }
  strategy_id: any
  ins_id: any
  gameFeedbackQues: any[] = []


  feedBackData: any = {}
  feedBackSubmitBtnFlag: boolean = false
  feedbackOptionHandel(optionData: any, data: any) {
    this.feedBackData[data.id] = {
      id: optionData.id,
      ans: optionData.option
    }
    if (Object.keys(this.feedBackData).length == this.gameFeedbackQues.length) {
      const isNullish = Object.values(this.feedBackData).every((value: any) => {
        if (typeof value === 'string') {
          return value.trim() === '';
        }
        return true; // treat non-string values as truthy
      });

      this.feedBackSubmitBtnFlag = !isNullish
    }
    else this.feedBackSubmitBtnFlag = false
  }
  feedbackTextAreaHandel(val: any, data: any) {
    if (val.target.value && val.target.value != '' && val.target.value.trim() != '') {
      this.feedBackData[data.id] = val.target.value
      if (Object.keys(this.feedBackData).length == this.gameFeedbackQues.length) this.feedBackSubmitBtnFlag = true
      else this.feedBackSubmitBtnFlag = false
    }
    else this.feedBackSubmitBtnFlag = false
  }

  getAllFeedBackQuestion() {
    this.httpSv.getInsFeedbackQuestion(this.ins_id, this.strategy_id).subscribe((res: any) => {
      if (res['status']) {
        this.gameFeedbackQues = res['results']
        this.gameFeedbackQues.forEach((ele: any) => {
          if (ele.is_mcq == 1) {
            this.feedBackData[ele.id] = {
              "id": ele.answers[0].answer,
              "ans": ele.options.filter((item: any) => item.id == ele.answers[0].answer).option
            }
          } else {
            this.feedBackData[ele.id] = ele.answers[0].answer
          }
        })
        console.log(this.feedBackData)
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))
  }
  close() {
    this.dialogRef.close(false)
  }
  saveInsAns() {
    let payload: any = {
      instructor_id: this.ins_id,
      strategy_id: this.strategy_id,
      answers: []
    }
    this.gameFeedbackQues.forEach((ele: any) => {
      if (this.feedBackData[ele.id]?.id) {
        payload['answers'].push({
          epg_instructor_fq_id: ele.id,
          answer_id: this.feedBackData[ele.id]?.id
        })
      }
      else {
        payload['answers'].push({
          epg_instructor_fq_id: ele.id,
          answer: this.feedBackData[ele.id]
        })
      }
    })
    this.httpSv.saveInsFeedbackAnswer(payload).subscribe((res: any) => {
      if (res['status']) {
        this.toster.success('Saved sucessfully', '', { timeOut: 2000 })
        this.dialogRef.close(true)
      }
      else {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 }))

  }
}


// Player Count Dialog
// Player Count Dialog 
// Player Count Dialog 
// Player Count Dialog 
@Component({
  selector: 'app-ins-epg-player-count',
  templateUrl: './ins-player-count-dialog.html',
  styleUrls: ['./ins-epg-list.component.css']
})
export class InsPlayerCountDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<InsPlayerCountDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }
  ngOnInit(): void {
    this.session_code = this.data.session_code
    this.getPlayerCount()
  }
  session_code: any
  plyrCount: any = 0
  activePlyr: any[] = []
  close() {
    this.dialogRef.close(false)
  }
  confirm() {
    this.dialogRef.close(true)
  }
  getPlayerCount() {
    this.httpSv.getRunningPlyrCount(this.session_code).subscribe((res: any) => {
      if (res['status']) {
        this.activePlyr = res['results']
        this.plyrCount = res['results'].length

      } else {
        this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Opps! Something went wrong', '', { timeOut: 2000 })
    })
  }
}