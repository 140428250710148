<div class="parent_comp_main_container">
    <!-- <app-epg-header></app-epg-header> -->
    <div class="comp_main_container">
        <div class="topBarQueHeader">
            <div class="summary_logo_div">
                <img src="../../../../assets/img/epg/logo.svg" alt="" class="img-hide-toggle-mobile">
                <img src="../../../../assets/img/epg/mobile_epg_icon.svg" alt="" class="img-show-toggle-mobile">
            </div>
            <div class="heading">
                Game Summary
            </div>
        </div>

        <div class="summary_main_div">
            <div class="player_name_div">
                Participant Name: <span>{{summaryData.player_name}}</span>
            </div>
            <div class="grid_2 img-hide-toggle-mobile">
                <div class="overall_dtl_box">
                    <div>My Overall profit</div>
                    <div class="overall_price_div">${{summaryData.overall_profit | number: '1.0-0'}}</div>
                </div>
                <div class="overall_dtl_box">
                    <div class="class_avg_main_div">
                        <div>Class Average</div>
                        <button ngbTooltip="refresh" tooltipClass="tooltip" placement="bottom-right"
                            class="refresh_btn_div">
                            <img src="../../../../assets/img/epg/refresh-white-icn.svg" alt="" style="cursor: pointer;"
                                (click)="ngOnInit()">
                            <!-- <span>refresh</span> -->
                        </button>
                    </div>
                    <div class="overall_price_div">${{summaryData.class_average | number: '1.0-0'}}</div>
                </div>
            </div>
            <div class="grid_2 img-hide-toggle-mobile">
                <div class="game_half_dtl_box">
                    <div class="game_half_div">
                        <div>ESTD’s strategy for First half</div>
                        <div class="game_half_price_div">
                            {{summaryData.estd_strategy_for_first_half?summaryData.estd_strategy_for_first_half:'N/A'}}
                        </div>
                    </div>
                    <div class="game_half_div">
                        <div>My inference</div>
                        <div class="game_half_price_div">
                            {{summaryData.first_half_prediction?summaryData.first_half_prediction:'N/A'}}</div>
                    </div>
                </div>
                <div class="game_half_dtl_box">
                    <div class="game_half_div">
                        <div>ESTD’s strategy for Second half</div>
                        <div class="game_half_price_div">
                            {{summaryData.estd_strategy_for_second_half?summaryData.estd_strategy_for_second_half:'N/A'}}
                        </div>
                    </div>
                    <div class="game_half_div">
                        <div>My inference</div>
                        <div class="game_half_price_div">
                            {{summaryData.second_half_prediction?summaryData.second_half_prediction:'N/A'}}</div>
                    </div>
                </div>
            </div>



            <div class="mobile_summary_cards img-show-toggle-mobile">

                <div class="overall_dtl_box">
                    <div>My Overall profit</div>
                    <div class="overall_price_div">${{summaryData.overall_profit | number: '1.0-0'}}</div>
                </div>


                <div class="game_half_dtl_box">
                    <div class="game_half_div">
                        <div>ESTD’s strategy for First half</div>
                        <div class="game_half_price_div">
                            {{summaryData.estd_strategy_for_first_half?summaryData.estd_strategy_for_first_half:'N/A'}}
                        </div>
                    </div>
                    <div class="game_half_div">
                        <div>My inference</div>
                        <div class="game_half_price_div">
                            {{summaryData.first_half_prediction?summaryData.first_half_prediction:'N/A'}}</div>
                    </div>
                </div>



                <div class="overall_dtl_box">
                    <div class="class_avg_main_div">
                        <div>Class Average</div>
                        <button ngbTooltip="refresh" tooltipClass="tooltip" placement="bottom-right"
                            class="refresh_btn_div">
                            <img src="../../../../assets/img/epg/refresh-white-icn.svg" alt="" style="cursor: pointer;"
                                (click)="ngOnInit()">
                            <!-- <span>refresh</span> -->
                        </button>
                    </div>
                    <div class="overall_price_div">${{summaryData.class_average | number: '1.0-0'}}</div>
                </div>



                <div class="game_half_dtl_box">
                    <div class="game_half_div">
                        <div>ESTD’s strategy for Second half</div>
                        <div class="game_half_price_div">
                            {{summaryData.estd_strategy_for_second_half?summaryData.estd_strategy_for_second_half:'N/A'}}
                        </div>
                    </div>
                    <div class="game_half_div">
                        <div>My inference</div>
                        <div class="game_half_price_div">
                            {{summaryData.second_half_prediction?summaryData.second_half_prediction:'N/A'}}</div>
                    </div>
                </div>
            </div>




            <div class="prediction_percent">
                <span> My price prediction accuracy for ESTD’s pricing - </span>
                <button ngbTooltip="Rightly Predicted ESTD's Pricing" tooltipClass="tooltip"
                    placement="bottom"><b>{{summaryData.price_prediction_accuracy_for_estd_pricing}}%</b></button>
            </div>
            <div class="summary_table_main_div">
                <div class="summary_table_header_sub_div">
                    <div class="summary_table_heading">My performance</div>
                    <div class="email-request-main-div" *ngIf="!emailRequested">Send the detail report via <span
                            (click)="captureImage()">email</span></div>
                </div>
                <div class="summary_table_sub_div">
                    <table class="summary_table">
                        <thead>
                            <tr class="table_head">
                                <th scope="col">Quarter</th>
                                <th scope="col">Announcement</th>
                                <th scope="col">Costs</th>
                                <th scope="col">Demand</th>
                                <th scope="col">Your previous move</th>
                                <th scope="col">ESTD’s price</th>
                                <th scope="col">UPSTART’s price</th>
                                <th scope="col">UPSTART’s profit</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="table_body" *ngFor="let item of summaryTableData;let i=index"
                                [ngClass]="i%2 == 0?'table_bg_dark':'table_bg_light'">
                                <td scope="row">
                                    <div style="min-height: 60px; display: contents;">
                                        {{i+1}}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-height: 60px; display: contents;">
                                        ESTD has announced that it will price {{item.state_G?'Low':'High'}}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-height: 60px; display: contents;">
                                        {{item.state_C?'Low':'High'}}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-height: 60px; display: contents;">
                                        {{item.state_D?'Low':'High'}}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-height: 60px; display: contents;">
                                        {{item.prev_move == 0 ? 'High': item.prev_move == 1 ? 'Low': item.prev_move ==
                                        2?'Stayed out':'N/A' }}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-height: 60px; display: contents;">
                                        {{item.estd_price == 500 ? 'High':'Low'}}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-height: 60px; display: contents;">
                                        {{item.upstart_price == 0 ?'High':item.upstart_price == 1 ?'Low':
                                        item.upstart_price == 2 ? 'Stayed out':''}}
                                    </div>
                                </td>
                                <td>
                                    <div style="min-height: 60px; display: contents;"
                                        [innerHTML]="getUpstartProfit(item.upstart_profit)"></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <!-- <div class="email-request-main-div" *ngIf="!emailRequested">Send the detail report via <span
                (click)="captureImage()">email</span></div> -->
    </div>
</div>