<div class="dialogueConfigureGame" style="height: 41vh;">
    <div class="header">
        <div class="headerTxt">
            New Strategy
        </div>
        <img style="cursor: pointer;" (click)="close()" src="../../../../assets/img/close-md-svgrepo-com.svg" />
    </div>
    <div class="body">
        <div class="gameDetails">
            <div class="gameFields">
                <label>Strategy Name:<b>*</b></label>
                <input type="text" [(ngModel)]="strategyName">
            </div>
            <div class="gameFields">
                <label>Description:<b>*</b></label>
                <input type="text" [(ngModel)]="strategyDescription">
            </div>
        </div>
        <div class="strategy_save_btn_div">
            <div (click)="close()">CANCEL</div>
            <div (click)="saveCopy()">SAVE</div>
        </div>
    </div>
</div>