import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ToastrService } from 'ngx-toastr';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';
import { DialogueCopyGame } from '../game-list.component';
import { InsStrategyCopyDialog, InsStrategyDialog } from 'src/app/components/instructor-dashboard/ins-epg-list/ins-epg-list.component';

@Component({
  selector: 'app-epg-gamelist',
  templateUrl: './epg-gamelist.component.html',
  styleUrls: ['./epg-gamelist.component.css']
})
export class EpgGamelistComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private toast:ToastrService,
    private http:HttpService,
    private dataShare:DataShareService
  ) { }

  ngOnInit(): void {

    this.getGameList()
  }
  selectedFilter:any='';
  displayedColumns: string[] = ['sl_no', 'name', 'action', 'status'];
  dataBM: any[] = []
  instructorId:any;
  instructions:any;
  dataSource: any = new MatTableDataSource();
  isAddGame:boolean=false
  editTooltip: string = "Edit game";
  copyTooltip: string = "Copy game";
  Strategies:any=[]


  filterByStatus(data:any){
    this.selectedFilter=data
  }
  applyFilter(event:Event){
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  addGame(type:any,element:any)
  {
    if(this.Strategies.length==3)
    {
      this.toast.warning('Maximum of 3 Strategies are only allowed', '', { timeOut: 2000 });
      return;
    }
    const dialogRef = this.dialog.open(InsStrategyDialog, {
      disableClose: true,
      hasBackdrop: true,
      data: {
        viewOnly: false,
        ins_id:null,
        byAdmin:true
      }
    })
  }
  editStrategy(id:any)
  {
    this.http.getStrategyDetails(id).subscribe((res:any)=>{
      if(res['status']){
        const dialogRef = this.dialog.open(InsStrategyDialog, {
          disableClose: true,
          hasBackdrop: true,
          data: {
            viewOnly: true,
            editStategy: true,
            strategyData: res['results'],
            byAdmin:true
          }
          
        })
      }
    })
   
  }
  copyStrategy(id: any) {
    if(this.Strategies.length==3)
    {
      this.toast.warning('Maximum of 3 Strategies are only allowed', '', { timeOut: 2000 });
      return;
    }
    this.http.getStrategyDetails(id).subscribe((res:any)=>{
      if(res['status']){
        const dialogRef = this.dialog.open(InsStrategyCopyDialog, {
          disableClose: true,
          hasBackdrop: true,
          data: {
            strategyData: res['results']['strategy_values'],
            ins_id: null,
            byAdmin:true
          }
        })
     
      }else{
        this.toast.error('Oops! Something went wrong.', '', { timeOut: 2000 });
      }
    },(err:any)=>{
        this.toast.error(err.error.message, '', { timeOut: 2000 });
      }
    )
  } 
  
  getGameList()
  {
    this.http.getGameListEPG().subscribe((res:any)=>{
      this.dataSource.data=res.results.game_data;
      this.Strategies=res.results.game_data;
    })
  }
  setStatus(status:any,element:any){
    let data = {
      game_id:element.id,
      status:status
    }
    this.http.updateGameStatus(data).subscribe((res:any) => {
      if(res['status']){
        this.toast.success('Game status updated successfully', '', { timeOut: 2000 });
        this.getGameList()
      } else{
        this.toast.error('Oops! Something went wrong.', '', { timeOut: 2000 });
      }
    })

  }
  copyGame(element:any){
    const dialogRef = this.dialog.open(DialogueCopyGame, {
      disableClose: true,
      hasBackdrop: true,
      data:{
        game_id: element.id
      }
    })
    
    dialogRef.afterClosed().subscribe((res:any) => {
      if(res){
        this.getGameList();
      }
    })
  }
}
