import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructor-routing',
  templateUrl: './instructor-routing.component.html',
  styleUrls: ['./instructor-routing.component.css']
})
export class InstructorRoutingComponent implements OnInit {

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }
  tabSelected='';
  changeTab(route:any)
  {
      this.tabSelected='gr'
      setTimeout(()=>{
        this.tabSelected=route
      },0)
      this.cdr.detectChanges();  // Manually trigger change detection
    }
}
