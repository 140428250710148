    <div class="profile_main_div">
        <div class="profile_heading">PROFILE SETTING</div>
        <div>
            <div style="    display: flex;
            position: relative;
            max-width: min-content; margin-bottom: 2rem;">
                <img [src]="src" alt="" style="    width: 150px;
                height: 150px;
                border-radius: 50%;
                border: 1px solid #0D8BBC;">

                <img src="../../../../assets/img/camera.svg" alt="" style="position: absolute;
                right: 0;
                cursor: pointer;" (click)="fileInput_image.click()">
                <input type="file" accept="image/png,image/jpg" (change)="onImageChange($event)"
                #fileInput_image hidden id="file_inp" />

            </div>
            <div>
                <div class="service_modify_pd_inp_main_div">
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Name</label>
                        <input type="text" [(ngModel)]="name">
                    </div>
                    <!-- <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Mobile Number</label>
                        <input type="number" [(ngModel)]="mobileNo" [disabled]="true" class="input_disable">
                    </div> -->
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Email</label>
                        <input type="email" [(ngModel)]="email">
                    </div>
                </div>
                <div class="license_modify_btn_main_div">
                    <div class="license_modify_pd_btn_div">
                        <div (click)="updateProfileDtls()">UPDATE</div>
                    </div>
                </div>
                <div class="password_update_heading">Change Password</div>
                <div class="password_update_div">
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Current Password</label>
                        <input type="password" [(ngModel)]="oldPassword">
                    </div>
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">New Password</label>
                        <input type="password" [(ngModel)]="newPassword">
                    </div>
                    <div class="service_modify_pd_inp_sub_div">
                        <label for="name">Confirm Password</label>
                        <input type="password" [(ngModel)]="confirmPassword">
                    </div>

                </div>
                <div class="license_modify_btn_main_div" style="margin-top: 1rem;">
                    <div class="license_modify_pd_btn_div">
                        <div (click)="updatePassword()">CHANGE PASSWORD</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
