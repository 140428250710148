<div class="instructorConatainer" >
    <div class="gameHeader stickyContainer">
        <div class="gameHeaderLeft">
            <img style="cursor: pointer;" (click)="goBackRound()" src="../../../../assets/img/backArrow.svg"/>
            <p>MODIFY ROUND QUESTIONS</p>
        </div>
        <div class="gameHeaderRight">
            <div (click)="handleShowTree()" class="gameSave roundHeaderAddBtn" style="margin-right: 10%;">SHOW GRAPH</div>
            <div (click)="handleAddQuestion('curveball','new')" *ngIf="curveBallQuestion.length==0" class="gameSave roundHeaderAddBtn" style="margin-right: 10%;">ADD CURVEBALL</div>
            <div (click)="handleAddQuestion('curveball','edit')" *ngIf="curveBallQuestion.length>0" class="gameSave roundHeaderAddBtn" style="margin-right: 10%;">EDIT CURVEBALL</div>
            <div (click)="handleAddQuestion('question','')" class="gameSave roundHeaderAddBtn" >ADD QUESTIONS</div>
        </div>
    </div>
    <div class="gameDetails">
        <div class="gameFields">
            <label>Enter the {{createdGameName.toUpperCase()}} Game Name<b>*</b></label>
            <input type="text" [(ngModel)]="gameName" disabled>
        </div>
        <div class="gameFields">
            <label>Enter the {{createdGameName.toUpperCase()}} Game Code<b>*</b></label>
            <input type="text" [(ngModel)]="gameCode" disabled>
        </div>
        <!-- <div class="gameFields" (click)="handleModifyInstructions()">
            <label>Game Instructions <b>*</b></label>
            <div class="gameAddInstructions" >
                <img src="../../../../assets/img/addInstructions.svg"/>
                ADD
            </div>
        </div> -->
        <div class="gameFields">
            <label>Choose Theme <b>*</b></label>
            <mat-form-field class="roundSelectOption">
                <!-- <mat-label>{{themes[0].value}}</mat-label> -->
                <mat-select  name="food" [(value)]="selectedRoundId">
                      <mat-option (click)="getGameRoundQuestions(round.id); disableEditableQues();" [value]="round.id" *ngFor="let round of rounds">{{round.round_name}}</mat-option>
                  </mat-select>
              </mat-form-field>
              
        </div>
    </div>
    <div style="border-top: 2px solid #C2C2C2;margin: 0 5px">&nbsp;</div>

    <!-- FOR ESG GAME -->
    <div class="gameBody" style="flex-direction: column;" *ngIf="!isCustomgame">
            <div class="questionList" *ngFor="let question of questions;let i=index" (click)="clickQuestion(question)" [ngClass]="editableQuestion.id==question.id?'editing':''"  >
                <div class="questionListHeader" style="position: relative;" >
                    <label>Question {{i+1}}</label>
                    <!-- <img *ngIf="!isOnEdit && editableQuestion.id==question.id" src="../../../../assets/img/mdi-light_delete.svg"/> -->
                    <div style="display: flex;">
                        <img style="width: 25px;margin-right: 20px;" *ngIf="editableQuestion.id!=question.id" (click)="handleEdit(question,i);$event.stopPropagation()" src="../../../../assets/img/edit_icon.svg"/>
                        <div  class="saving" *ngIf="isOnEdit && editableQuestion.id==question.id">
                            <img src="../../../../assets/img/mdi-light_delete.svg" (click)="handleDeleteQuestion(question)" style="margin-right: 2rem;"/>
                            <img *ngIf="saved" src="../../../../assets/img/saved.svg" />
                            <img *ngIf="unsaved" src="../../../../assets/img/unsaved.svg" (click)="savingQuestion();$event.stopPropagation()"/>
                        </div>
                    <div *ngIf="isOnEdit && editableQuestion.id!=question.id">
                        <img src="../../../../assets/img/mdi-light_delete.svg" (click)="handleDeleteQuestion(question)" />
                    </div>
                </div>

                </div>
                <div class="questionListQuestions">
                    <label *ngIf="!(isEditable && editableIndex == i)" style="white-space: pre-wrap; width:90%;">{{question.ques_text}}</label>
                    <textarea 
                        *ngIf="isEditable && editableIndex == i"
                        style="display: block; width:90%; min-height:4rem; max-height:30vh" 
                        (blur)="updateQuestion($event,'question')"
                    >{{question.ques_text}}</textarea>
                </div>
                <div class="questionListOptions">
                    <div class="listOptionContainer" style="margin:2rem 0">
                        <div class="listOptions">
                            <p>Answers</p>
                        </div>
                        <div class="listNxtQuestion">
                            <p>Next Question ID No.</p>
                        </div>
                    </div>
                    <div class="listOptionContainer" *ngFor="let option of question.options;let j= index">
                        <div class="listOptions optionOuterBox" >
                            <div class="optionTxt" >
                                <!-- <label [attr.contenteditable]="isEditable" style="display: block;" (blur)="updateQuestion($event,'option'+(j+1))" >{{option.option_text}}</label> -->
                                <label *ngIf="!(isEditable && editableIndex == i && j+1)" style="white-space: pre-wrap; width:90%;">{{option.option_text}}</label>
                                <textarea 
                                    *ngIf="isEditable && editableIndex == i && j+1"
                                    style="display: block; width:100%; min-height:5rem; max-height:21vh" 
                                    (blur)="updateQuestion($event,'option'+(j+1))"
                                >{{option.option_text}}</textarea>
                            </div>
                            <div class="optionPoints">
                                <div>
                                    <label style="font-weight: 600;">Effort Points :</label> 
                                    <label *ngIf="!(isEditable && editableIndex == i && j+1)" class="inputs" style="margin-left: 5px;" [ngClass]="option.budget?.toString().charAt(0)=='-'?'red':'green'" >{{option.budget?option.budget:0}}</label>
                                    <input *ngIf="isEditable && editableIndex == i && j+1" class="inputs" style="margin-left: 5px;" (keypress)="preventText($event)" value="{{option.budget}}" (blur)="updateQuestion($event,'ep'+(j+1))" [ngClass]="option.budget?.toString().charAt(0)=='-'?'red':'green'"/>
                                </div>
                                <div>
                                    <label style="font-weight: 600;">ESG :</label> 
                                    <label *ngIf="!(isEditable && editableIndex == i && j+1)" class="inputs" style="margin-left: 5px;" [ngClass]="option.budget?.toString().charAt(0)=='-'?'red':'green'" >{{option.esg_score?option.esg_score:0}}</label>
                                    <input *ngIf="isEditable && editableIndex == i && j+1" class="inputs" style="margin-left: 5px;" (keypress)="preventText($event)" value="{{option.esg_score}}" (blur)="updateQuestion($event,'esg'+(j+1))" [ngClass]="option.esg_score?.toString().charAt(0)=='-'?'red':'green'"/>
                                </div>
                                <div>
                                    <label style="font-weight: 600;">Brand Value :</label> 
                                    <label *ngIf="!(isEditable && editableIndex == i && j+1)" class="inputs" style="margin-left: 5px;" [ngClass]="option.budget?.toString().charAt(0)=='-'?'red':'green'" >{{option.brand_value?option.brand_value:0}}</label>
                                    <input *ngIf="isEditable && editableIndex == i && j+1" class="inputs" style="margin-left: 5px;" (keypress)="preventText($event)" value="{{option.brand_value}}" (blur)="updateQuestion($event,'bv'+(j+1))" [ngClass]="option.brand_value?.toString().charAt(0)=='-'?'red':'green'"/>
                                </div>
                            </div>
                        </div>
                        <div class="listNxtQuestion nxtQuestionBox" style="color: #0D8BBC;">
                            <mat-select  name="nextQuesId" [(value)]="nextQuesIdArr[i][j]" [disabled]="editableQuestion.id!=question.id">
                                <mat-option 
                                    (mouseover)="changeIndex(k)"
                                    matTooltip="{{questionArray[hoverIndex]}}"
                                    matTooltipPosition="right"
                                    [disabled]="disabledNextQuesIdArr.includes(quesId)"
                                    (click)="disabledNextQuesIdArr.includes(quesId) ? null : updateNextQuesId(quesId, 'option'+(j+1))"
                                    [value]="questionIdArr[j]"
                                    *ngFor="let quesId of questionIdArr; let k = index"
                                    [value]="k"
                                >
                                    {{k==0 ? 'N/A' : k}}
                                </mat-option>
                            </mat-select>
                            <!-- <label style="width: 1rem;" [attr.contenteditable]="isEditable" (keypress)="preventText($event)" (blur)="updateQuestion($event,'n'+(i+1))" >{{option.next_ques_id?option.next_ques_id:'NULL'}}</label>  <img src="../../../../assets/img/material-symbols-light_arrow-drop-down-circle-outline-rounded.svg"/>                        -->
                        </div>
                    </div>
                  
                </div>
            </div>
            <div class="curveBall" *ngIf="curveBallQuestion.length>0">
                <div class="curveBallHeader">
                    CURVE BALL QUESTION
                </div>
                <div class="questionList" *ngFor="let question of curveBallQuestion;let i=index">
                <div class="questionListHeader">
                    <label class="curveHeader">CURVE BALL QUESTION</label>
                    <img src="../../../../assets/img/mdi-light_delete.svg" (click)="handleDeleteQuestion(question)"/>
                </div>
                <div class="questionListQuestions">
                    <label [attr.contenteditable]="isEditable"  (dblclick)="handleEdit('',i)" >{{question.ques_text
                    }}</label>
                </div>
                <div class="questionListOptions">
                    <div class="listOptionContainer" style="margin:2rem 0">
                        <div class="listOptions">
                            <p>Answers</p>
                        </div>
                  
                    </div>
                    <div class="listOptionContainer" *ngFor="let option of question.options">
                        <div class="listOptions optionOuterBox" *ngIf="option.is_active == 1">
                            <div class="optionTxt  curveBallOptionHeader">{{option.option_text}}</div>
                            <div class="optionTxt curveBallOptionBody">{{option.popup}}</div>
                            
                        </div>
                    </div>
                  
                </div>
            </div>
            </div>
    </div>

    <!-- FOR CUSTOM ESG GAME -->

    <div class="gameBody" style="flex-direction: column;" *ngIf="isCustomgame">
        <div class="questionList" *ngFor="let question of questions;let i=index" (click)="clickQuestion(question)" [ngClass]="editableQuestion.id==question.id?'editing':''"  >
            <div class="questionListHeader" style="position: relative;" >
                <label>Question {{i+1}}</label>
                <!-- <img *ngIf="!isOnEdit && editableQuestion.id==question.id" src="../../../../assets/img/mdi-light_delete.svg"/> -->
                <div style="display: flex;">
                    <img style="width: 25px;margin-right: 20px;" *ngIf="editableQuestion.id!=question.id" (click)="handleEdit(question,i);$event.stopPropagation()" src="../../../../assets/img/edit_icon.svg"/>
                    <div  class="saving" *ngIf="isOnEdit && editableQuestion.id==question.id">
                        <img src="../../../../assets/img/mdi-light_delete.svg" (click)="handleDeleteQuestion(question)" style="margin-right: 2rem;"/>
                        <img *ngIf="saved" src="../../../../assets/img/saved.svg" />
                        <img *ngIf="unsaved" src="../../../../assets/img/unsaved.svg" (click)="savingQuestion();$event.stopPropagation()"/>
                    </div>
                <div *ngIf="isOnEdit && editableQuestion.id!=question.id">
                    <img src="../../../../assets/img/mdi-light_delete.svg" (click)="handleDeleteQuestion(question)" />
                </div>
            </div>

            </div>
            <div class="questionListQuestions">
                <label *ngIf="!(isEditable && editableIndex == i)" style="white-space: pre-wrap; width:90%;">{{question.ques_text}}</label>
                <textarea 
                    *ngIf="isEditable && editableIndex == i"
                    style="display: block; width:90%; min-height:4rem; max-height:30vh" 
                    (blur)="updateQuestion($event,'question')"
                >{{question.ques_text}}</textarea>
            </div>
            <div class="questionListOptions">
                <div class="listOptionContainer" style="margin:2rem 0">
                    <div class="listOptions">
                        <p>Answers</p>
                    </div>
                    <div class="listNxtQuestion">
                        <p>Next Question ID No.</p>
                    </div>
                </div>
                <div class="listOptionContainer" *ngFor="let option of question.options;let j= index">
                    <div class="listOptions optionOuterBox" >
                        <div class="optionTxt" >
                            <!-- <label [attr.contenteditable]="isEditable" style="display: block;" (blur)="updateQuestion($event,'option'+(j+1))" >{{option.option_text}}</label> -->
                            <label *ngIf="!(isEditable && editableIndex == i && j+1)" style="white-space: pre-wrap; width:90%;">{{option.option_text}}</label>
                            <textarea 
                                *ngIf="isEditable && editableIndex == i && j+1"
                                style="display: block; width:100%; min-height:5rem; max-height:21vh" 
                                (blur)="updateQuestion($event,'option'+(j+1))"
                            >{{option.option_text}}</textarea>
                        </div>
                        <div class="optionPoints">
                            <div *ngFor="let label of question.labels[j];let k =index">
                                    <label style="font-weight: 600;">{{label.name}} :</label> 
                                    <label *ngIf="!(isEditable && editableIndex == i && j+1)" class="inputs" style="margin-left: 5px;" [ngClass]="label.value?.toString().charAt(0)=='-'?'red':'green'" >{{label.value}}</label>
                                    <input type="number" min="1" *ngIf="isEditable && editableIndex == i && j+1" class="inputs" style="margin-left: 5px;" (keypress)="preventText($event)" value="{{label.value}}" (blur)="updateQuestionCustom($event,label,k,j)" [ngClass]="label.value?.toString().charAt(0)=='-'?'red':'green'"/>
                            </div>
                        </div>
                    </div>
                    <div class="listNxtQuestion nxtQuestionBox" style="color: #0D8BBC;">
                        <mat-select  name="nextQuesId" [(value)]="nextQuesIdArr[i][j]" [disabled]="editableQuestion.id!=question.id">
                            <mat-option 
                                (mouseover)="changeIndex(k)"
                                matTooltip="{{questionArray[hoverIndex]}}"
                                matTooltipPosition="right"
                                [disabled]="disabledNextQuesIdArr.includes(quesId)"
                                (click)="disabledNextQuesIdArr.includes(quesId) ? null : updateNextQuesId(quesId, 'option'+(j+1))"
                                [value]="questionIdArr[j]"
                                *ngFor="let quesId of questionIdArr; let k = index"
                                [value]="k"
                            >
                                {{k==0 ? 'N/A' : k}}
                            </mat-option>
                        </mat-select>
                        <!-- <label style="width: 1rem;" [attr.contenteditable]="isEditable" (keypress)="preventText($event)" (blur)="updateQuestion($event,'n'+(i+1))" >{{option.next_ques_id?option.next_ques_id:'NULL'}}</label>  <img src="../../../../assets/img/material-symbols-light_arrow-drop-down-circle-outline-rounded.svg"/>                        -->
                    </div>
                </div>
              
            </div>
        </div>
        <div class="curveBall" *ngIf="curveBallQuestion.length>0">
            <div class="curveBallHeader">
                CURVE BALL QUESTION
            </div>
            <div class="questionList" *ngFor="let question of curveBallQuestion;let i=index">
            <div class="questionListHeader">
                <label class="curveHeader">CURVE BALL QUESTION</label>
                <img src="../../../../assets/img/mdi-light_delete.svg" (click)="handleDeleteQuestion(question)"/>
            </div>
            <div class="questionListQuestions">
                <label [attr.contenteditable]="isEditable"  (dblclick)="handleEdit('',i)" >{{question.ques_text
                }}</label>
            </div>
            <div class="questionListOptions">
                <div class="listOptionContainer" style="margin:2rem 0">
                    <div class="listOptions">
                        <p>Answers</p>
                    </div>
              
                </div>
                <div class="listOptionContainer" *ngFor="let option of question.options">
                    <div class="listOptions optionOuterBox" *ngIf="option.is_active == 1">
                        <div class="optionTxt  curveBallOptionHeader">{{option.option_text}}</div>
                        <div class="optionTxt curveBallOptionBody">{{option.popup}}</div>
                        
                    </div>
                </div>
              
            </div>
        </div>
        </div>
</div>
</div>

