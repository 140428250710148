<div [ngClass]="{'epgEmilMainDiv': epgFlag || esgFlag}">
    <div style="text-align: end;"><img src="../../../assets/img/close-md-svgrepo-com.svg" alt=""
            style="width: 20px;cursor: pointer;" (click)="close(false)"></div>
    <div *ngIf="!emailValidate">
        <div style="text-align: center;">
            <h2>Enter your email address to get the report</h2>
        </div>
        <div style="display: flex;justify-content: center;">
            <input type="email" name="email" id="" class="form-control" placeholder="enter your email address"
                [(ngModel)]="email" style="width: 80%;" [ngClass]="{'epgEmilInp': epgFlag}">
        </div>
        <div style="text-align: center;margin-top: 1rem;" *ngIf="!epgFlag">
            <button type="button" class="btn btn-success" (click)="validateEmail()">Submit</button>
        </div>
        <div class="feedback1_next_btn_main_div" *ngIf="epgFlag">
            <div (click)="validateEmail()">Submit</div>
        </div>
    </div>
    <div *ngIf="emailValidate">
        <div style="font-size: 18px;text-align: center;">We will send you the report soon to your email.</div>
        <div style="text-align: center;margin-top: 1rem;" *ngIf="!epgFlag">
            <button type="button" class="btn btn-success">OKAY</button>
        </div>
        <div class="feedback1_next_btn_main_div" *ngIf="epgFlag">
            <div (click)="close(true)">OKAY</div>
        </div>

    </div>
</div>
<style>
    ::ng-deep .mat-dialog-container {
        padding: 10px;
    }

    .epgEmilMainDiv {
        background: #ddfafb;
        padding: 13px;
        border-radius: 10px;
        color: black;
        width: 430px;
    }

    .epgEmilInp {
        background-color: #fff;
        color: #373636;
    }

    .feedback1_next_btn_main_div {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    .feedback1_next_btn_main_div div:first-of-type {
        border-radius: 4px;
        background: #29CCC8;
        padding: 10px 40px;
        color: #0B0B0B;
        font-family: Avenir;
        font-size: 16px;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 1.92px;
        cursor: pointer;
    }
</style>