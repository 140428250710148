import { HeaderComponent } from './../header/header.component';
import { Component, OnInit, Renderer2, HostListener, ViewChild, AfterViewChecked, } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ComponentCanDeactivate } from 'src/app/services/Guards/pending-changes.guard';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { HttpService } from 'src/app/services/https/http.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { trigger, state, style, animate, transition } from '@angular/animations';



let alertCondition: boolean = true
@Component({
  selector: 'app-rounds',
  templateUrl: './rounds.component.html',
  styleUrls: ['./rounds.component.css'],
  animations: [
    trigger('incrementAnimation', [
      state('start', style({ transform: 'scale(1.2)' })),
      state('end', style({ transform: 'scale(1)' })),
      transition('start => end', animate('0.1s ease-in-out')),
      transition('end => start', animate('0.1s ease-in-out'))
    ]),
    trigger('slideInOut', [
      state('void', style({
        transform: 'translateX(100%)'
      })),
      transition(':enter', [
        animate('0.8s ease-in', style({
          transform: 'translateX(0)'
        }))
      ]),
      transition(':leave', [
        animate('0.5s ease-out', style({
          transform: 'translateX(100%)'
        }))
      ])
    ]),
    trigger('fadeIn', [
      state('void', style({
        opacity : '10%'
      })),
      transition(':enter', [
        animate('0.8s ease-in', style({
          opacity : '100%'
        }))
      ]),
    ])
  ]
})
export class RoundsComponent implements OnInit, ComponentCanDeactivate,AfterViewChecked {
  math: Math = Math;
  gameInfoFlag: boolean = false
  @HostListener('window:beforeunload', ['$event'])
  canDeactivate(): Observable<boolean> | boolean {
    if (this.gameConditionFlag) {
      return false
    }
    else {
      return true
    }
  }
  animationState = 'start';
  curveBallQueFlag: boolean = false
  curveBallQuePopupFlag: boolean = false
  curveBallQueData: any
  selectedCurveBallOption: any = {}
  gameConditionFlag: boolean = false
  roundTheameData: any
  gameType:any = 1
  roundStartStatus: boolean = true;
  selectedOption: any = {};
  question: any[] = [];
  options: any[] = [];
  curveBallQues:any[] = [];
  curveBallOptions:any[] = [];
  curveBallPopup:any[] = [];
  gameId: any
  playerData: any
  playerId:any
  quesNo: number = 1
  allQuestionCount: number = 1
  theameName: any
  themeBgImageUrl: string = ''
  gameDetails: any
  gameRounds: any
  roundNumber: number = 0;
  totalRounds: any
  themeName: any
  quesId: any
  cashInHandProgress: any = 100
  cashInHandValue: any = 100
  esgScoreProgress: any = 50
  customerSentimentProgress: number = 50
  preDefine_cashInHandProgress: number = 100
  preDefine_esgScoreProgress: number = 92.5 * 2
  preDefine_customerSentimentProgress: number = 202.5 * 2
  customFormulaLabels:any = []
  isCustomGame:any = 0
  customFormulaValues:any = []
  customFormulaName:any = ''
  customFormulaOptionvalues:any = []

  constructor(
    private httpSv: HttpService,
    private toster: ToastrService,
    private router: Router,
    private renderer: Renderer2,
    private dataSv: DataShareService,
    public dialog: MatDialog,
  ) {
  }
  ngAfterViewChecked() {
    if (this.roundStartStatus && !this.curveBallQuePopupFlag ) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
    }
  }

  gameSessionCode: any
  gameTime: any
  currentValues: number[] = []
  progressBarValue:any = []
  isMobile:boolean = false
  @ViewChild('childComponentRef', { static: false }) childComponentRef!: HeaderComponent;
  ngOnInit(): void {
    if (sessionStorage.getItem('gameComplete') == 'true' || sessionStorage.getItem('roundCondition') == 'true') {
      this.router.navigate(['/login'])
      // clear sessionStorage here
    }
    if(window.innerWidth <= 450)
      this.isMobile = true
    if(window.innerWidth <= 650 && window.innerHeight <= 400)
      this.isMobile = true
    this.customFormulaLabels = JSON.parse(sessionStorage.getItem('customFormula') || '[]');
    this.customFormulaLabels.map((data)=>{
      this.customFormulaValues.push(data)
    })
    this.currentValues = new Array(this.customFormulaValues.length).fill(0);
    this.gameSessionCode = sessionStorage.getItem('gamseSessionCode');
    this.gameType = sessionStorage.getItem('gameType');
    this.isCustomGame = sessionStorage.getItem('isCustomGame');
    console.log(this.isCustomGame,'aaaaaa')
    // this.gameTime = sessionStorage.getItem('game_start_time')
    this.gameDetails = JSON.parse(sessionStorage.getItem('gameDetails') || '[]');
    this.totalRounds = this.gameDetails.total_rounds;
    this.gameRounds = JSON.parse(sessionStorage.getItem('gameRounds') || '[]');
    this.roundNumber = JSON.parse(sessionStorage.getItem('roundNumber') || '1');
    this.roundTheameData = this.gameRounds;
    this.themeName = this.gameRounds[this.roundNumber-1].round_name;
    this.themeBgImageUrl = this.gameRounds[this.roundNumber-1].bg_image;

    if(this.quesNo == 1){
      this.quesId = this.gameRounds[this.roundNumber-1].first_ques;
      sessionStorage.setItem('quesId', this.quesId);
    } else if(this.quesNo > 1){
      this.quesId = JSON.parse(sessionStorage.getItem('quesId') || '0')
    }

    this.playerId = JSON.parse(sessionStorage.getItem('playerId') || '')
    this.gameId = JSON.parse(sessionStorage.getItem('gameId') || '')
    this. incrementCounters()
  }
  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    setTimeout(() => {
      console.log(this.childComponentRef);
    }, 0);
  }
  callTimerStartFunc(): void {
    console.log(this.childComponentRef)
    if (this.childComponentRef) {
      this.childComponentRef.startTimer()
    }
  }
  callTimerStopFunc(): void {
    if (this.childComponentRef && this.childComponentRef) {
      this.childComponentRef.stopTimer();
    }
  }
  getOptionValue(id:any){
    const item = this.customFormulaOptionvalues.find(obj => obj.option_id === id);
    return item ? item.value : null;
  }
  getProgressWidth(value: number): string {
    return value > 100 ? '100%' : `${value}%`;
  }
  startRound() {
    this.httpSv.getFirstQuestion(this.playerId, this.gameId, this.roundNumber, this.quesId).subscribe((res: any) => {
      if (res['status']) {
        if (sessionStorage.getItem('roundCondition') != 'true')
          this.callTimerStartFunc()
        const temp = res['result'].question
        this.quesId = temp.ques_id
        const quesPara:[] = temp.ques_text.split('\n');
        this.question = quesPara;
        this.options = temp.options;
        temp.options.forEach((option, index) => {
          let option_text:any = [];
          const optionPara = option.option_text.split('\n');
          optionPara.forEach(paragraph => {
            option_text.push(paragraph);
          });
          this.options[index].option_text = option_text;
        })
        if(this.isCustomGame == 1){
          this.customFormulaName = res['result'].question.formulaName['name']
          this.customFormulaOptionvalues =  res['result'].question.customValue
          let customValue =  this.customFormulaValues
          res['result'].formulaValues.map((data)=>{
            customValue.map((value)=>{
              console.log(value.id , data.formula_id)
              if(value.id == data.formula_id){
                value.value = data.value
              }
            })
          })
          this.currentValues = new Array(this.customFormulaValues.length).fill(0);
          this.incrementCounters();
        }
        // this.incrementCounters()
        this.playerData = res['result'].studentDtl
        this.roundStartStatus = false
        this.gameConditionFlag = true
        sessionStorage.setItem('roundCondition', 'true')
        if (res['result']['studentDtl'].budget) {
          var budget = res['result'].studentDtl.budget;
          if (+budget <= 0) {
            this.cashInHandProgress = 0
            this.cashInHandValue = 0
            this.openAlertDialog()
          } else {
            this.cashInHandProgress = Math.round(budget);
            this.cashInHandValue = Math.round(+budget)
          }
        }
        if (res['result'].studentDtl.esg_score)
          this.esgScoreProgress = Math.round(res['result'].studentDtl.esg_score)
        // this.esgScoreProgress = Math.ceil((((+res['result'].studentDtl.esg_score) / this.preDefine_esgScoreProgress) * 100))

        if (this.esgScoreProgress > 100) this.esgScoreProgress = 100;
        // if (res['result'].studentDtl.brand_sentiment && res['result'].studentDtl.brand_awareness && res['result'].studentDtl.brand_loyalty)
        //   this.customerSentimentProgress = Math.ceil((((parseInt(res['result'].studentDtl.brand_sentiment) + parseInt(res['result'].studentDtl.brand_awareness) + parseInt(res['result'].studentDtl.brand_loyalty))  / this.preDefine_customerSentimentProgress) * 100))
        if (res['result'].studentDtl.brand_value)
          this.customerSentimentProgress = Math.round(res['result'].studentDtl.brand_value);
        if (this.customerSentimentProgress > 100) this.customerSentimentProgress = 100;
      }
      else {
        this.toster.error(res['error'][0], '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
    })

  }
  async incrementCounters() {
    const duration = 1000; // 1 second
    const maxValue = Math.max(...this.customFormulaValues.map(v => v.value));
    const interval = duration / maxValue; // Calculate interval based on max value
  
    const updatePromises = this.customFormulaValues.map((item, index) => {
      return new Promise<void>((resolve) => {
        let currentValue = 0;
        const targetValue = item.value;
        this.currentValues[index] = currentValue;
  
        const intervalId = setInterval(() => {
          if (currentValue < targetValue) {
            currentValue++;
            this.currentValues[index] = currentValue;
            this.toggleAnimationState();
          } else {
            clearInterval(intervalId);
            resolve(); // Resolve the promise when the loop is complete
          }
        }, interval);
      });
    });
  
    await Promise.all(updatePromises); // Wait for all increments to complete
    this.progressBarValue = [...this.currentValues]; // Update progressBarValue after all loops are done
  }
  selectOption(option: any) {
    this.selectedOption = option
    sessionStorage.setItem('nextQuesId', option.next_ques_id)
  }
  page: any
  opendrawer(data: any) {

    console.log(data)
    if (data) {
      if (data != 'resume') {

        this.page = data
        this.gameInfoFlag = true
        sessionStorage.setItem('temp', 'true')
      }
      else {
        this.gameInfoFlag = false
      }

    }
  }
  close(event: any) {
    if (event == 'resume') {
      this.gameInfoFlag = false
    }
  }

  makeWordsBold(text) {
    return text.replace(/\*\*(.*?)\*\*/g, '<strong style="font-weight: bold">$1</strong>');
  }

  // opendrawer(data: any) {
  //   console.log(data)
  //   if (data) {
  //     if (data != 'resume') {
  //       this.page = data
  //       this.gameInfoFlag = true
  //     }
  //     else {
  //       this.gameInfoFlag = false
  //     }

  //   }
  // }
  nextQuestion() {
    // const animation_progress_bar = document.getElementsByClassName('animation_progress_bar')
    // for (let i = 0; i < 3; i++) {
    //   if (document.getElementsByClassName('animation_progress_bar').length > 0) {
    //     document.getElementsByClassName('animation_progress_bar')[0].classList.remove('animation_progress_bar')
    //   }
    // }
    if (Object.keys(this.selectedOption).length == 0) {
      this.toster.error('Please select any option', '', { timeOut: 2000 })
    } else {
      if (document.getElementsByClassName('animation').length > 0) {
        document.getElementById('question-conatiner')?.classList.remove('animation')
      }
      if (document.getElementsByClassName('animationMobile').length > 0) {
        document.getElementById('question-conatiner')?.classList.remove('animationMobile')
      }
      let data = {
        player_id: this.playerId,
        ques_id: this.quesId,
        option_id: this.selectedOption.option_id,
        game_id: this.gameId,

        ques_no: this.quesNo == 1 ? this.quesNo : 0,
        // product: this.fillData('product', this.selectedOption.tag_name.filter((ele: any) => ele.tag == 'product').length > 0),
        // price: this.fillData('price', this.selectedOption.tag_name.filter((ele: any) => ele.tag == 'price').length > 0),
        // place: this.fillData('place', this.selectedOption.tag_name.filter((ele: any) => ele.tag == 'place').length > 0),
        // promotion: this.fillData('promotion', this.selectedOption.tag_name.filter((ele: any) => ele.tag == 'promotion').length > 0),
        // easy: this.fillData('easy', this.selectedOption.tag_name.filter((ele: any) => ele.tag == 'easy').length > 0),
        // accessible: this.fillData('accessible', this.selectedOption.tag_name.filter((ele: any) => ele.tag == 'accessible').length > 0),
        // social: this.fillData('social', this.selectedOption.tag_name.filter((ele: any) => ele.tag == 'social').length > 0),
      }
      this.httpSv.updateStudentDetails(data).subscribe((res: any) => {
        if (res['status']) {
          if (this.selectedOption.next_ques_id) {
           setTimeout(() => {
             this.httpSv.nextQuestion(this.gameId, this.selectedOption.next_ques_id, this.playerId, this.gameSessionCode).subscribe((res: any) => {
               if (res['status']) {  
                if(!this.isMobile)
                  document.getElementById('question-conatiner')?.classList.add('animation')
                else
                  document.getElementById('question-conatiner')?.classList.add('animationMobile')
                 this.selectedOption = {};
                 const temp = res['result'].quesDtl
                 this.quesId = temp.ques_id
                 const quesPara:[] = temp.ques_text.split('\n');
                 this.question = quesPara;              
                 this.options = temp.options;
                 temp.options.forEach((option, index) => {
                   let option_text:any = [];
                   const optionPara = option.option_text.split('\n');
                   optionPara.forEach(paragraph => {
                     option_text.push(paragraph);
                   });
                   this.options[index].option_text = option_text;
                 })
                 if(this.isCustomGame == 1){
                   this.customFormulaName = res['result'].quesDtl.formulaName['name']
                   this.customFormulaOptionvalues =  res['result'].quesDtl.customValue
                   
                   console.log(this.customFormulaValues)
                   res['result'].formulaValues.map((data)=>{
                     this.customFormulaValues.map((value)=>{
                       if(value.id == data.formula_id){
                         value.value = data.value
                       }
                     })
                   })
                   this.currentValues = new Array(this.customFormulaValues.length).fill(0);
                  this.incrementCounters();
                   // this.customFormulaValues.forEach(target => Object.assign(target, res['result'].formulaValues.find(source => source.id === target.formula_id) || {}));
                 }
                 this.playerData = res['result'].studentDtl
                 this.quesNo += 1
                 this.allQuestionCount += 1
 
                 const qureySelectClass = document.getElementsByClassName('qureySelectClass')
                 for (let i = 0; i < qureySelectClass.length; i++) {
                   qureySelectClass[i].classList.add('animation_progress_bar')
                 }
                 var budget = res['result'].studentDtl.budget;
                 if (+budget <= 0) {
                   this.cashInHandProgress = 0
                   this.cashInHandValue = 0
                   this.openAlertDialog()
                 } else {
                   this.cashInHandProgress = Math.round(parseInt(budget))
                   this.cashInHandValue = Math.round(+budget)
                 }
                 this.esgScoreProgress = Math.round(res['result'].studentDtl.esg_score);
                 this.customerSentimentProgress = Math.round(res['result'].studentDtl.brand_value);
                 if (this.cashInHandProgress > 100)
                   this.cashInHandProgress = 100;
                 if (this.esgScoreProgress > 100)
                   this.esgScoreProgress = 100
                 if (this.customerSentimentProgress > 100)
                   this.customerSentimentProgress = 100
                 window.scrollTo({
                   top: 0,
                   left: 0,
                   behavior: "smooth",
                 });
               }
             })
           }, 500);
          } else {
            if (this.roundNumber != this.totalRounds) {
              // this.httpSv.getFirstQuestion(this.playerId, this.gameId, this.roundNumber, this.quesId).subscribe((res: any) => {
              //   if (res['status']) {
                  this.httpSv.getCurveBallQuestion(this.playerId, this.gameId, this.roundNumber).subscribe((res: any) => {
                    if (res['status']) {
                      this.curveBallQueData = res['result']['curveBall'];

                      const qureySelectClass = document.getElementsByClassName('qureySelectClass')
                      for (let i = 0; i < qureySelectClass.length; i++) {
                        qureySelectClass[i].classList.add('animation_progress_bar')
                      }
                      if(this.isCustomGame == 1){
                          res['result'].formulaValues.map((data)=>{
                          this.customFormulaValues.map((value)=>{
                            if(value.id == data.formula_id){
                              value.value = data.value
                            }
                          })
                        })
                        this.currentValues = new Array(this.customFormulaValues.length).fill(0);
                       this.incrementCounters();
                        // this.customFormulaValues.forEach(target => Object.assign(target, res['result'].formulaValues.find(source => source.id === target.formula_id) || {}));
                      }
                      var budget = res['result'].studentDtl.budget
                      if (+budget <= 0) {
                        this.cashInHandProgress = 0
                        this.cashInHandValue = 0
                        this.openAlertDialog()
                      } else {
                        this.cashInHandProgress = Math.ceil(((parseInt(budget) / this.preDefine_cashInHandProgress) * 100))
                        this.cashInHandValue = Math.ceil(+budget)
                      }
                      this.esgScoreProgress = Math.round(res['result'].studentDtl.esg_score);
                      this.customerSentimentProgress = Math.round(res['result'].studentDtl.brand_value);
                      if (this.cashInHandProgress > 100)
                        this.cashInHandProgress = 100;
                      if (this.esgScoreProgress > 100)
                        this.esgScoreProgress = 100
                      if (this.customerSentimentProgress > 100)
                        this.customerSentimentProgress = 100
                      window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });

                      if(this.curveBallQueData){
                        this.curveBallQueFlag = true;
                        const temp = res['result'].curveBall;
                        this.quesId = temp.ques_id
                        const quesPara:[] = this.curveBallQueData.ques_text.split('\n');
                        this.curveBallQues = quesPara;              
                        const tempOptions = this.curveBallQueData.options;
                        tempOptions.forEach((option, index) => {
                          let option_text:any = [];
                          let popup_text:any = [];
                          const optionPara = option.option_text.split('\n');
                          const popupPara = option.popup.split('\n');
                          optionPara.forEach(paragraph => {
                            option_text.push(paragraph);
                          });
                          popupPara.forEach(para => {
                            popup_text.push(para);
                          })
                          this.curveBallOptions[index] = option_text;
                        })
                      } else {
                        this.roundNumber += 1
                        this.quesNo = 1
                        this.allQuestionCount += 1
                        this.themeName = this.roundTheameData[this.roundNumber-1].round_name
                        this.roundStartStatus = true
                        this.quesId = this.gameRounds[this.roundNumber-1].first_ques;
                        sessionStorage.setItem('quesId', this.gameRounds[this.roundNumber-1].first_ques);
                        this.themeBgImageUrl = this.gameRounds[this.roundNumber-1].bg_image;
                      }

                    }                  
                  },(err:any) =>{
                      this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
                  })
                  // console.log(res['result']);
                  // if (res['result'].studentDtl.budget) {
                  //   var budget = res['result'].studentDtl.budget
                  //   if (+budget <= 0) {
                  //     this.cashInHandProgress = 0
                  //     this.cashInHandValue = 0
                  //     this.openAlertDialog()
                  //   } else {
                  //     this.cashInHandProgress = Math.ceil(((parseInt(budget) / this.preDefine_cashInHandProgress) * 100))
                  //     this.cashInHandValue = Math.ceil(+budget)
                  //   }
                  // }
                  // if (res['result'].studentDtl.esg_score)
                  //   this.esgScoreProgress = res['result'].studentDtl.esg_score
                  // // this.esgScoreProgress = Math.ceil((((+res['result'].studentDtl.esg_score) / this.preDefine_esgScoreProgress) * 100))

                  // if (this.esgScoreProgress > 100) this.esgScoreProgress = 100
                  // // if (res['result'].studentDtl.brand_sentiment && res['result'].studentDtl.brand_awareness && res['result'].studentDtl.brand_loyalty)
                  // //   this.customerSentimentProgress = Math.ceil((((parseInt(res['result'].studentDtl.brand_sentiment) + parseInt(res['result'].studentDtl.brand_awareness) + parseInt(res['result'].studentDtl.brand_loyalty))  / this.preDefine_customerSentimentProgress) * 100))
                  // if (res['result'].studentDtl.brand_value)
                  //   this.customerSentimentProgress = res['result'].studentDtl.brand_value
                  // if (this.customerSentimentProgress > 100) this.customerSentimentProgress = 100
              //   }
              //   else {
              //     this.toster.error(res['error'][0], '', { timeOut: 2000 })
              //   }
              // }, (err: any) => {
              //   this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
              // })

            }
            else {
              if (this.roundNumber == this.totalRounds) {
                this.httpSv.endGame(this.playerId).subscribe((res: any) => {
                  if (res['status']) {
                    sessionStorage.setItem('gameComplete', 'true')
                    this.gameConditionFlag = false
                    this.router.navigate(['/summary'])
                    this.callTimerStopFunc()
                  }
                })
              }
              // else {
              //   this.roundNumber += 1
              //   this.quesNo = 1
              //   this.allQuestionCount += 1
              //   this.theameName = this.roundTheameData[this.roundNumber]
              //   this.roundStartStatus = true
              // }
            }
          }
        }
        else {
          this.toster.error(res['error'][0], '', { timeOut: 2000 })
        }
      }, (err: any) => {
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 })
      })
    }

  }
  toggleAnimationState() {
    this.animationState = this.animationState === 'start' ? 'end' : 'start';
  }
  fillData(type: any, condition: boolean) {

    if (condition) {
      if (this.playerData[type] != null && this.playerData[type] !== "") {
        return JSON.stringify(JSON.parse(this.playerData[type]) + 1)
      }
      else {
        return JSON.stringify(1)
      }
    }
    else {
      if (this.playerData[type] != null && this.playerData[type] !== "")
        return this.playerData[type]
      else
        return null
    }
  }
  handleCurveQueOption(item: any) {
    this.selectedCurveBallOption['option_id'] = item.option_id
    this.selectedCurveBallOption['popup'] = item.popup
    this.selectedCurveBallOption['budget'] = item.budget
    this.curveBallPopup = item.popup.split('\n');

  }
  handleCurveQueSubmit() {
    if (Object.keys(this.selectedCurveBallOption).length > 0 || this.curveBallQueData.options.length == 1) {
      let data:any ={};
      if(this.curveBallQueData.options.length == 1){
        this.selectedCurveBallOption['popup'] = this.curveBallQueData.options[0].popup
        this.curveBallPopup = this.curveBallQueData.options[0].popup.split('\n');
        data = {
          game_id: this.gameId,
          player_id: this.playerId,
          ques_id: this.curveBallQueData.ques_id,
          option_id: this.curveBallQueData.options[0].option_id
        }
      } else{
        data = {
          game_id: this.gameId,
          player_id: this.playerId,
          ques_id: this.curveBallQueData.ques_id,
          option_id: this.selectedCurveBallOption.option_id
        }
      }
      this.httpSv.updateCurveBallStudentDtl(data).subscribe((res: any) => {
        if (res['status']) {
          if (this.roundNumber == this.totalRounds) {
            this.httpSv.endGame(this.playerId).subscribe((res: any) => {
              if (res['status']) {
                sessionStorage.setItem('gameComplete', 'true')
                this.gameConditionFlag = false
                this.router.navigate(['/summary'])
                this.callTimerStopFunc()
              }
            })
          }
          else {
            this.selectedOption = {};
            this.roundNumber += 1
            // this.quesNo = 1
            this.allQuestionCount += 1
            this.themeName = this.gameRounds[this.roundNumber-1].round_name;
            this.roundStartStatus = true
            this.quesId = this.gameRounds[this.roundNumber-1].first_ques;
            sessionStorage.setItem('quesId', this.gameRounds[this.roundNumber-1].first_ques);
            this.themeBgImageUrl = this.gameRounds[this.roundNumber-1].bg_image;
          }
          this.curveBallQuePopupFlag = true;
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          if(this.curveBallQueData.options.length == 1){
            this.cashInHandValue += this.curveBallQueData.options[0].budget;
          } else{
            this.cashInHandValue += this.selectedCurveBallOption.budget;
          }
          this.cashInHandProgress = this.cashInHandValue
          if(this.cashInHandProgress > 100)
              this.cashInHandProgress = 100
        }
      })
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }
    // else if (this.curveBallQueData.options[0].option_text == "") {
    //   this.selectedCurveBallOption['popup'] = this.curveBallQueData.options[0].popup
    //   const data = {
    //     player_id: this.playerId,
    //     ques_id: this.curveBallQueData.ques_id,
    //     option_id: this.curveBallQueData.options[0].option_id
    //   }
    //   this.httpSv.updateCurveBallStudentDtl(data).subscribe((res: any) => {
    //     if (res['status']) {
    //       if (this.roundNumber == this.totalRounds) {
    //         this.httpSv.endGame(this.playerId).subscribe((res: any) => {
    //           if (res['status']) {
    //             sessionStorage.setItem('gameComplete', 'true')
    //             this.gameConditionFlag = false
    //             this.router.navigate(['/summary'])
    //             this.callTimerStopFunc()
    //           }
    //         })
    //       }
    //       // else {
    //       //   if (this.roundNumber == 4 && this.tempCountForCurve == 0) {
    //       //     this.tempCountForCurve++
    //       //     this.httpSv.getCurveBallQuestion(this.roundNumber + 1).subscribe((res: any) => {
    //       //       if (res['status']) {
    //       //         this.curveBallQueData = res['result']
    //       //         this.curveBallQueFlag = true
    //       //         // this.curveBallQuePopupFlag = false
    //       //       }
    //       //     })
    //       //   } else {
    //       //     this.roundNumber += 1
    //       //     this.quesNo = 1
    //       //     this.allQuestionCount += 1
    //       //     this.theameName = this.roundTheameData[this.roundNumber]
    //       //     this.roundStartStatus = true
    //       //   }
    //       // } 
    //       else{
    //         this.curveBallQuePopupFlag = true
    //         this.roundNumber += 1
    //         this.quesNo = 1
    //         this.allQuestionCount += 1
    //         this.themeName = this.roundTheameData[this.roundNumber-1].round_name
    //         this.roundStartStatus = true
    //         this.quesId = this.gameRounds[this.roundNumber-1].first_ques;
    //         sessionStorage.setItem('quesId', this.gameRounds[this.roundNumber-1].first_ques);
    //       }
    //     }
    //   })

    // }
    else {
      this.toster.error('Please select any option', '', { timeOut: 2000 })
    }
  }
  backToQue() {
    this.curveBallQuePopupFlag = false
    this.curveBallQueFlag = false
    this.selectedCurveBallOption = {}
    this.curveBallQueData = null

  }
  openAlertDialog() {
    if (alertCondition) {
      this.dialog.open(EffortAlertComponent, {
        hasBackdrop: true,
        disableClose: true
      })
    }
    else return
  }
}


@Component({
  selector: 'effort-alert',
  templateUrl: './effort_alart_dialog.html',
})
export class EffortAlertComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<EffortAlertComponent>,
  ) { }
  ngOnInit(): void {

  }
  close() {
    alertCondition = false
    this.dialogRef.close()
  }
}