import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { HttpService } from 'src/app/services/https/http.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();
  @Output() flagEmitter = new EventEmitter<any>();
  @Output() startStopFlag = new EventEmitter<boolean>();
  @Input() activeOverview: any
  @Input() programStaus: any
  constructor(
    private router: Router,
    private dialog: MatDialog,
    private httpSv: HttpService,
    private toster: ToastrService
  ) { }
  headerTxtFlag: boolean = false
  university_code: any
  instructorGames: any[] = [];
  game_code: any
  session_code: any
  txtFlag:boolean = false
  logoutFlag:boolean = false
  ngOnInit(): void {
    this.university_code = sessionStorage.getItem('university_code')
    const currentPage = this.router.url
    if (currentPage == '/overview') {
      this.headerTxtFlag = true
    }
    if(currentPage == '/rounds'){
      this.timerShowFlag = true
    }
    if(sessionStorage.getItem('university_code')  == '1001CJBS') this.txtFlag = true
    if(currentPage == '/summary') this.logoutFlag = true

    if(sessionStorage.getItem('university_logo') == null){
      this.getPlayerGameInfo();
    } else{
      this.universityLogo = sessionStorage.getItem('university_logo');
      this.gameDetailText = sessionStorage.getItem('game_detail_text');
    } 
  }

  universityLogo:any=null;
  gameDetailText:any;
  sideBarFlag: boolean = false
  resumeGameFlag: boolean = false
  openSideBar() {
    const currentPage = this.router.url
    if (currentPage == '/rounds') {
      this.resumeGameFlag = true
      
    }
    this.sideBarFlag = true
  }
  infoDisplayHandler(data: any) {
    console.log('headdddd')
    const currentPage = this.router.url
    if (currentPage == '/about') {
      this.messageEmitter.emit(data)
    }
    else if (currentPage == '/rounds') {
      console.log(data,'header')
      if (data == 'resume')
        this.sideBarFlag = false
      this.messageEmitter.emit(data)
    }
    else if (currentPage == '/overview') {
      this.flagEmitter.emit(data)
      this.activeOverview = null
    }
    else if(currentPage == '/summary'){
      this.messageEmitter.emit(data)
    }
  }
  gameDtlClick(data: any) {
    this.messageEmitter.emit(data)
    if (data == '1')
      this.activeOverview = true
    else if (data == '2')
      this.activeOverview = false
  }

  getPlayerGameInfo(){
    const sesison_code = sessionStorage.getItem('gameSessionCode')

    this.httpSv.getPlayerGameInfo(sesison_code).subscribe((res:any) => {
      if(res['status']){
        this.universityLogo = res['data']?.configDtl?.university_logo;
        this.gameDetailText = res['data']?.configDtl?.gameDetailText;
        sessionStorage.setItem('university_logo', res['data']?.configDtl?.university_logo);
        sessionStorage.setItem('game_detail_text', res['data']?.configDtl?.gameDetailText);
      } else{
        this.toster.error('Oops! Something went wrong.', '', { timeOut: 2000 });
      }
    },(err:any) => {
      if (err.status == 422) {
        this.toster.warning(err.error['message'], '', { timeOut: 2000 });
      } else{
        this.toster.error('Oops! Something went wrong', '', { timeOut: 2000 });
      }

    })
  }

  startGameProgram() {
    let epochTime =  Math.floor(new Date().getTime() / 1000);;
    let data = {
      university_code :this.university_code,
      game_code: this.game_code,
      game_start_time: epochTime
    }
    this.httpSv.startProgarm(data).subscribe((res: any) => {
      if (res['status']) {
        this.session_code = res['data'];
        this.startStopFlag.emit(true)
      }
    })
  }
  endGameProgram() {
    let epochTime =  Math.floor(new Date().getTime() / 1000);;
    let data = {
      university_code :this.university_code,
      session_code: this.session_code,
      game_end_time: epochTime
    }
    this.httpSv.getAllStudentStatus(data).subscribe((res: any) => {
      if (res['status']) {
        const dialogRef = this.dialog.open(SessionDialog, {
          maxWidth: '350px',
          data: res['result']
        })
        dialogRef.afterClosed().subscribe((result: any) => {
          if (result) {
            let data = {
              university_code: this.university_code,
              game_code: this.game_code,
              sesison_code: this.session_code,
              game_end_time: epochTime
            }
            this.httpSv.endProgram(data).subscribe((res: any) => {
              if (res['status']) {
                this.startStopFlag.emit(true)
              }
            })
          }
        })
      } else {
        this.toster.error('Oops!! Something went wrong', '', { timeOut: 2000 })
      }
    }, (err: any) => {
      this.toster.error('Oops!! Something went wrong', '', { timeOut: 2000 })
    })

  }

  //Timer Logic
  remainingMin: any = '25'
  remainingSec: any = '00'
  countdownTime: number = 25 * 60
  timerShowFlag:boolean = false
  intervalID:any
  startTimer() {
    this.intervalID = setInterval(() => {
      this.remainingMin = Math.floor(this.countdownTime / 60);
      this.remainingSec = this.countdownTime % 60;
      if (this.remainingSec < 10)
        this.remainingSec = '0' + this.remainingSec
      if (this.remainingMin < 10)
        this.remainingMin = '0' + this.remainingMin
      this.countdownTime--;
      if (this.countdownTime < 0) {
        clearInterval(this.intervalID)
      }
    },1000)
  }
  stopTimer(){
    this.timerShowFlag = false
    clearInterval(this.intervalID)
  }
  logout(){
    this.router.navigate(['/login'])
  }

}







//Session Confirmation Dialog
@Component({
  selector: 'app-session-diaog',
  templateUrl: './session-confiramtion-dialog.html',
  styleUrls: ['./header.component.css']
})
export class SessionDialog implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<SessionDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }
  isAnyActive: boolean = false
  ngOnInit(): void {
    if (this.data.noOfStudentWithActiveGame > 0) {
      this.isAnyActive = true
    }
  }
  close() {
    this.dialogRef.close(false)
  }
  endGame() {
    this.dialogRef.close(true)
  }
}