<div class="renameContainer">
    <div class="header">
        <div>RENAME REPORT</div>
        <img (click)="close()" class="close" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>
    <div class="body">
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Game Code <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <input type="text" disabled [(ngModel)]="gameCode">
            </div>  
        </div>
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Report Name <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <input type="text"  [(ngModel)]="reportName">
            </div>  
        </div>

    </div>
    <div class="contentFooter" *ngIf="data.from!='ins'">
        <div class="gameCancel" style="color:#D43738;margin-right: 3rem;" (click)="close()">CANCEL</div>
        <div class="gameSave" style="margin-right: 2rem;" (click)="save()">SAVE</div>
</div>
</div>