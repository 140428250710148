<div class="instructorConatainer" *ngIf="!isAddGame">
    <div class="header">
        <div class="title"> 
            <p>{{customGameName.toUpperCase()}} GAME LIST</p>
        </div>
        <div class="addInstructor">
            <img (click)="addGame('new',0)" style="margin-top: 20px;" src="../../../../assets/img/CNG.svg" />
        </div>
    </div>
    <div class="body" *ngIf="gameList.length!=0">
        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="search" class="search_icon_inp" placeholder="Search" (keyup)="applyFilter($event)"
                        autocomplete="off">
                </div>
            </div>
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">

                <!-- Sl NO -->
                <ng-container matColumnDef="sl_no">
                    <th class="" mat-header-cell *matHeaderCellDef [style.width.%]="8"> SN. </th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>

                <!-- Retail User Name  -->
                <ng-container matColumnDef="game_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="24">ESG Game Name
                    </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">{{element.game_name}}</td>
                </ng-container>

                <!-- Company name -->
                <ng-container matColumnDef="game_code">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="11"> ESG Game Code
                    </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.game_code == null ?
                        'N/A' : element.game_code}}</td>
                </ng-container>

                <!-- City name -->
                <ng-container matColumnDef="edit">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10"
                        style="text-align: center;"> Edit Game Instructions </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;">
                            <img src="../../../../assets/img/edit-game.svg"
                                (click)="handleEditInstructions(element.id,element.game_name)" alt=""
                                style="cursor: pointer;padding-left: 20%;">
                        </div>
                    </td>
                </ng-container>

                <!-- Status -->
                <ng-container matColumnDef="no_of_rounds">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="9"
                        style="text-align: center;">Number of Rounds</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element" style="text-align: center;">
                        {{element.total_rounds}}
                    </td>
                </ng-container>

                <!-- Status -->
                <ng-container matColumnDef="template">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10"
                        style="text-align:center">Edit Report</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;">
                            <img style="cursor: pointer;" (click)="editTemplate(element)"
                                src="../../../../assets/img/edit-game.svg" />
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="status">
                    <th class="common_table_data " mat-header-cell *matHeaderCellDef [style.width.%]="14"
                        style=" z-index: 10;text-align:center;">Status</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div class="dropdown" style="display: flex; justify-content: center; align-content:center">
                            <div class=" " id="dropdownMenuLink"
                                style="display: flex;justify-content: space-between;align-items: center;width:  57%; margin:0px 15px;"
                                [ngClass]="element.status=='active'?'green':'red'" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {{element.status | uppercase}}<img style="width: 20px;"
                                    src="../../../../assets/img/chevron-down-svgrepo-com.svg" />
                            </div>
                            <ul class="dropdown-menu" name="dropdown" aria-labelledby="dropdownMenuLink">
                                <li class="dropdown-item green" (click)="setStatus('active',element)">
                                    Active</li>
                                <li class="dropdown-item red" (click)="setStatus('inactive',element)">
                                    InActive</li>
                            </ul>
                        </div>
                    </td>
                </ng-container>

                <!-- Modify -->
                <ng-container matColumnDef="modify">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10"
                        style="text-align: center;"> Action</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;">
                            <!-- <img src="../../../../assets/img/remove_icon_bold.svg" (click)="confirmDelete()" alt="" style="cursor: pointer;"
                                >
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div> -->
                            <img src="../../../../assets/img/edit_icon.svg" alt="" [ngbTooltip]="editTooltip"
                                (click)="addGame('edit',element)" style="cursor: pointer;">
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div>
                            <img src="../../../../assets/img/copy.svg" alt="" [ngbTooltip]="copyTooltip"
                                (click)="copyGame(element)" style="cursor: pointer;">
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px" *ngIf="adminFlag">&nbsp;
                            </div>
                            <img src="../../../../assets/img/mdi-light_delete.svg"
                                (click)="deleteGame(element.id)" *ngIf="adminFlag" style="cursor: pointer;"/>
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </div>
    <div class="nogameBody" *ngIf="gameList.length==0">
        <div class="noGame">NO GAMES FOUND</div>
        <img src="../../../../../assets/img/nogame.svg" />
    </div>
</div>
<app-addModify-game *ngIf="isAddGame" (messageEmitter)="addGame('',0)"></app-addModify-game>