<div class="sideBarContainer">
    <div class="logo">
        <p>Game</p>
    </div>
    <div class="sideBarBody">
        <div class="selectables">
            <div class="sideBarItems" [ngClass]="currentTab=='university_list'?'selectedItem':''" (click)="changeRoute('university_list')"><p>University List</p></div>
            <div class="sideBarItems" [ngClass]="currentTab=='instructor'?'selectedItem':''" (click)="changeRoute('instructor')"><p>Instructor List</p></div>
            <div class="sideBarItems" >
                <div class="multi" (click)="toggleGameList()"><div class="d-flex align-items-center"><p>Game List</p><img [ngClass]="!opengameList?'rotate':''" src="../../../../assets//img/ri_arrow-drop-down-line.svg"/></div> 
                <div (click)="addNewGame()" class="addGame">+</div>
            </div>
                <div class="subItem" *ngIf="opengameList">
                    <ul style="width:100%">
                        <li *ngFor="let game of gameList" class="subSideBarItems"[ngClass]="currentTab==game.name?.toLowerCase()?'selectedItem':''"(click)="changeRoute(game.name.toLowerCase(),1,game.id)">
                            <div class="d-flex justify-content-between">
                                {{game.name}}
                                 <img *ngIf="game.id != 1 && game.id!=2" src="../../../../assets/img/mdi-light_delete.svg"(click)="deleteGame(game);$event.stopPropagation()" style="cursor: pointer;margin-right: 10px;"/>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="sideBarItems" [ngClass]="currentTab=='gc'?'selectedItem':''" (click)="changeRoute('gc')" > <p>Game Configure</p></div>
            <div class="sideBarItems" [ngClass]="currentTab=='gr'?'selectedItem':''" (click)="changeRoute('gr')"> <p>Game Report</p></div>
        </div>
        <div class="logout" (click)="logout()">
            <p>Logout</p> <img src="../../../../assets/img/logout.svg"/>
        </div>
    </div>
    <div class="profile"  (click)="changeRoute('profile')">
        <img class="profileImg" [src]="src"/>
        <p>{{userName}}</p>
        <img src="../../../../assets//img/ic_More Vertica.svg"/>
    </div>
</div>