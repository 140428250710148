<div class="emailConatainer">
    <div class="header">
        <div></div>
        <img (click)="close()" class="close" src="../../../../assets/img/close-md-svgrepo-com.svg"/>
    </div>
    <div class="body" style="min-height: 10vh;">
        <div class="bodyContents">
            <div class="bodyContentsLeft">
                <label>Email <b>*</b></label>
            </div>
            <div class="bodyContentsRight">
                <input type="text"  [(ngModel)]="studentEmail">
            </div>  
        </div>
    </div>
    <div class="contentFooter">
        <div class="gameCancel" style="color:#D43738;margin-right: 3rem;" (click)="close()">CANCEL</div>
        <div class="gameSave" style="margin-right: 2rem;" (click)="save()">SEND</div>
</div>
</div>