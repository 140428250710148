<div>
    <div *ngIf="!isAnyActive">
        <div class="main_que_div">Are you sure to end the game ?</div>
    </div>
    <div *ngIf="isAnyActive">
        <div >Still <b>{{data.noOfStudentWithActiveGame}}</b> player are not completed the game</div>
        <div class="main_que_div">Are you sure to end the game ?</div>

    </div>
    <div class="btn-div">
        <button type="button" class="btn btn-warning" (click)="endGame()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="close()">No</button>
    </div>

</div>


<style>
    .main_que_div {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: center;
        font-weight: 500;
        margin-top: 10px;
    }
    .btn-div{
        display: flex;
        justify-content: space-evenly;
        margin-top: 15px;
    }
</style>