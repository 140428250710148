import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataShareService } from 'src/app/services/data.service/data-share.service';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/https/http.service';
import { ToastrService } from 'ngx-toastr';
import { eventListeners } from '@popperjs/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent implements OnInit {
  @Output() messageEmitter = new EventEmitter<any>();
  @Output() createNewGame = new EventEmitter<any>();
  @Input() addGame: Observable<void> | undefined;

  private subscription: Subscription;
  constructor(
    private route:Router,
    private httpSv: HttpService,
    private dataShare: DataShareService,
    private toast: ToastrService,  
    public dialog: MatDialog,

  ) {
    this.subscription = this.dataShare.userDetails$.subscribe(user => {
      this.userName = user.name;
      this.src = user.profile;
    });
   }

  ngOnInit(): void {
    this.addGame?.subscribe((res)=>{
      this.getGameList()
    })
    this.getGameList()
    this.userId = JSON.parse(sessionStorage.getItem('user_id') || ''); 
    this.getProfileDtls();
    this.dataShare.userName = sessionStorage.getItem('user_name')
    this.userName = sessionStorage.getItem('user_name')
    this.src = sessionStorage.getItem('user_img')
  }
  userId:any;
  currentTab:string='university_list'
  opengameList:boolean=false;
  userName:any
  gameList:any = []
  src:any = "../../../../assets/img/account-icon.svg"

  toggleGameList(){
    this.opengameList=!this.opengameList
  }
  getProfileDtls() {
    this.httpSv.getAdminProfileDetails(this.userId).subscribe((res:any) => {
      if(res['status']){
        let userData = res['data'];
        this.userName = userData.name,
        this.src = res['profile']
        sessionStorage.setItem('user_img',this.src)
      }
    },(err) => {
        this.toast.error('Opps! Something went wrong', '', { timeOut: 2000 })
    })
  }
  changeRoute(route:any,from:any=null,gameId:any = null){
    if(gameId)
      this.dataShare.gameID = gameId
    this.dataShare.gameName = route
    if(from == null){
      this.opengameList=false
    }
    this.currentTab=route
    if(from && route!='esg' && route!='epg')
      this.messageEmitter.emit(
        {
          'route' : route,
          'isNewGame' : true
        })
    else
    this.messageEmitter.emit({
      'route' : route,
      'isNewGame' : false
      })


  }
  deleteGame(data:any){
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      hasBackdrop:true,
      disableClose:true,
      data:{heading:'Are you sure you want to delete this '+data.name.toUpperCase() +' game?<br> All games within it will be deleted.'}
    })
    dialogRef.afterClosed().subscribe((result:any)=>{
      if(result){
        this.httpSv.deleteGameEntire(data.id).subscribe((res:any)=>{
          if(res['status']){
            this.toast.success("Game Deleted Successfully",'',{timeOut:2000})
            this.getGameList()
          }
          else{
            this.toast.error("Game Not Found",'',{timeOut:2000})
          }
        },(err:any)=>{
          this.toast.error("Oops! Something went wrong. Please try again",'',{timeOut:2000})
        })
      }})
  }
  addNewGame(){
    event?.stopPropagation()
    this.createNewGame.emit()

  }
  getGameList(){
    this.httpSv.getAllGames().subscribe((res)=>{
      if(res['status']){
        this.gameList = res['data']
      }
    })
  }
  logout()
  {
    this.route.navigate(['/login'])
  }
}
