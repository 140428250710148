<div class="instructorConatainer">
    <div class="header">
        <div class="title">
            <p>INSTRUCTOR LIST</p>
        </div>
        <div class="addInstructor">
            <img (click)="addInstructor('add')" style="margin-top: 20px;" src="../../../../assets/img/addIns.svg" />
        </div>
    </div>
    <div class="body">
        <div class="web_user_search_main_div">
            <div class="web_user_search_sub_div">
                <div class="search_icon_inp_div">
                    <img src="../../../../assets/img/search_icon.svg" alt="" class="search_icon_inp_icon">
                    <input type="search" class="search_icon_inp" placeholder="Search" (keyup)="applyFilter($event)"
                        autocomplete="off">
                </div>
            </div>
        </div>
        <div class="web_user_table_main_div">
            <table mat-table [dataSource]="dataSource" class="mat-elevation-z8"
                style="width: 100%; position: relative;">

                <!-- Sl NO -->
                <ng-container matColumnDef="sl_no">
                    <th class="" mat-header-cell *matHeaderCellDef [style.width.%]="8"> SN. </th>
                    <td class="" mat-cell *matCellDef="let element; let i = index">{{i+1}} </td>
                </ng-container>

                <!-- Instructor logo
                <ng-container matColumnDef="university_logo">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef>University Logo</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">  <img class="univ_logo" [src]="element.university_logo"/></td>
                </ng-container> -->

                <!-- University name -->
                <ng-container matColumnDef="name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="22"> Instructor
                        Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element"> {{element.name == null ? 'N/A' :
                        element.name}}</td>
                </ng-container>

                <!-- University code -->
                <ng-container matColumnDef="university_code">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="12"> Instructor
                        Code </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.university_code == null ? 'N/A' : element.university_code}}
                    </td>
                </ng-container>

                <!-- City name -->
                <ng-container matColumnDef="email">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="22"> Email Id </th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.email == null ? 'N/A' : element.email}}
                    </td>
                </ng-container>

                <!-- University Name -->
                <ng-container matColumnDef="university_name">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="14">University Name</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        {{element.university[0].name}}
                    </td>
                </ng-container>

                <!-- Reset Password -->
                <ng-container matColumnDef="reset_password">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="8"
                        style="text-align: center;">Reset Password</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;"><img
                                (click)="resetPassword(element.instructor_id)" src="../../../assets/img/reset_icon.svg"
                                alt="" srcset="" style="cursor: pointer;">

                        </div>
                    </td>
                </ng-container>

                <!-- Reset Password -->
                <ng-container matColumnDef="status">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="14"
                        style="text-align: center;">Status</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div class="dropdown" style="display: flex; justify-content: center; align-content:center">
                            <div class=" " id="dropdownMenuLink"
                                style="display: flex;justify-content: space-between;align-items: center;width: 57%; margin:0px 15px"
                                [ngClass]="element.status=='active'?'green':'red'" type="button"
                                data-bs-toggle="dropdown" aria-expanded="false">
                                {{element.status | uppercase}}<img style="width: 20px;"
                                    src="../../../../assets/img/chevron-down-svgrepo-com.svg" />
                            </div>
                            <ul class="dropdown-menu" name="dropdown" aria-labelledby="dropdownMenuLink">
                                <li class="dropdown-item green" (click)="setStatus('active',element)">
                                    Active</li>
                                <li class="dropdown-item red" (click)="setStatus('inactive',element)">
                                    InActive</li>
                            </ul>
                        </div>
                    </td>
                </ng-container>
                <!-- Modify -->
                <ng-container matColumnDef="modify">
                    <th class="common_table_data" mat-header-cell *matHeaderCellDef [style.width.%]="10"
                        style="text-align: center;">Action</th>
                    <td class="common_table_data" mat-cell *matCellDef="let element">
                        <div style="display: flex; justify-content:center;">
                            <!-- <img src="../../../../assets/img/remove_icon_bold.svg" (click)="confirmDelete(element.instructor_id)" alt="" style="cursor: pointer;"
                                >
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;</div> -->
                            <img src="../../../../assets/img/edit_icon.svg" alt=""
                                (click)="modifyInstructor('edit', element)" style="cursor: pointer;">
                            <div style="border-right: 1px solid #C2C2C2;margin: 0 5px">&nbsp;
                            </div>
                            <img src="../../../../assets/img/mdi-light_delete.svg"
                                (click)="deleteInstructor(element.instructor_id)" style="cursor: pointer;" />
                        </div>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <!-- <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr> -->
                <ng-container *ngIf="dataSource.data.length > 0; else noDataMessage">
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </ng-container>

                <ng-template #noDataMessage>
                    <tr mat-row>
                        <td colspan="6" style="background-color: blueviolet; margin-top:40px;">No data available</td>
                    </tr>
                </ng-template>

            </table>
        </div>
    </div>
</div>